import Service from '@ember/service';
import { service } from '@ember/service';

import config from 'vfc-admin/config/environment';

export default class TagService extends Service {
  @service('auth/http-client') declare authenticatedHttpClient: AuthenticatedHttpClient;

  static ENDPOINTS = {
    findAll: '/tag',
  };

  findAll(): Promise<Array<Tag>> {
    return this.authenticatedHttpClient.GET<Array<Tag>>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${TagService.ENDPOINTS.findAll}`,
    });
  }
}
