import Service from '@ember/service';
import { service } from '@ember/service';

import type RouterService from '@ember/routing/router-service';
import type { SessionService } from 'ember-simple-auth/services/session';
import type { TODO } from 'vfc-admin/utility-types';

export const defaultHeaders: Record<string, string> = {
  'X-Requested-With': 'XMLHttpRequest',
};

export default class AuthenticatedHttpClient extends Service {
  @service declare router: RouterService;
  @service declare session: SessionService;

  async DELETE<R>({
    url,
    headers,
    signal,
  }: {
    url: string;
    headers?: Headers;
    signal?: AbortSignal;
  }): Promise<R> {
    return this.fetch({ url, method: 'DELETE', headers, signal });
  }

  async HEAD<R>({
    url,
    headers,
    signal,
  }: {
    url: string;
    headers?: Headers;
    signal?: AbortSignal;
  }): Promise<R> {
    return this.fetch({ url, method: 'HEAD', headers, signal });
  }

  async GET<R>({
    url,
    headers,
    signal,
  }: {
    url: string;
    headers?: Headers;
    signal?: AbortSignal;
  }): Promise<R> {
    return this.fetch({ url, method: 'GET', headers, signal });
  }

  async POST<R, RequestBody = object>({
    url,
    headers,
    body,
    signal,
  }: {
    url: string;
    headers?: Headers;
    body?: RequestBody;
    signal?: AbortSignal;
  }): Promise<R> {
    return this.fetch({ url, method: 'POST', headers, body, signal });
  }

  async PUT<R, RequestBody = object>({
    url,
    headers,
    body,
    signal,
  }: {
    url: string;
    headers?: Headers;
    body?: RequestBody;
    signal?: AbortSignal;
  }): Promise<R> {
    return this.fetch({ url, method: 'PUT', headers, body, signal });
  }

  async PATCH<R, RequestBody = object>(
    url: string,
    headers?: Headers,
    body?: RequestBody,
    signal?: AbortSignal
  ): Promise<R> {
    return this.fetch({ url, method: 'PATCH', headers, body, signal });
  }

  async fetch<R, RequestBody = object>({
    url,
    method = 'GET',
    headers = new Headers(),
    body,
    signal,
  }: {
    url: string;
    method?: string;
    headers?: Headers;
    body?: RequestBody;
    signal?: AbortSignal;
  }): Promise<R> {
    const _headers = Array.from(headers).reduce((acc: { [key: string]: string }, curr) => {
      const [k, v] = curr;

      acc[k] = v;

      return acc;
    }, {});

    const {
      authenticated: { token },
    }: { authenticated: { token: string } } = this.session.data;

    _headers['Authorization'] = `Bearer ${token}`;

    let _body: FormData | string | null = null;

    if (body) {
      if (body instanceof FormData) {
        _body = body;
      } else {
        _body = JSON.stringify(body);
      }
    }

    const response = await fetch(url, {
      method: method,
      headers: { ...defaultHeaders, ..._headers },
      body: _body,
      signal: signal,
    });

    if (response.ok) {
      return this.getResponseByContentType(response);
    } else {
      if (401 === response.status) {
        this.session.invalidate();
        this.router.transitionTo('login');
      }

      return Promise.reject(response);
    }
  }

  async getResponseByContentType(response: Response): Promise<TODO> {
    const responseType = response.headers.get('content-type');

    if (responseType) {
      if (responseType.indexOf('application/json') !== -1) {
        try {
          return await response.json();
        } catch (error) {
          return response;
        }
      }
    }

    return response;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'auth/http-client': AuthenticatedHttpClient;
  }
}
