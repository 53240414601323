import Route from '@ember/routing/route';
import { service } from '@ember/service';

import config from 'vfc-admin/config/environment';

import type ClientService from 'vfc-admin/services/client-service';

type Params = {
  page: string;
};

export default class Client extends Route {
  queryParams = {
    query: {
      refreshModel: true,
    },
    page: {
      refreshModel: true,
    },
  };

  pageSize = config.APP.PAGE_SIZE;

  @service declare clientService: ClientService;

  async model(params: Params) {
    const page = Number(params.page ?? 1);
    const clientsPromise = this.clientService.findAll(params.query, {
      page,
      pageSize: this.pageSize,
    });

    return { clientsPromise, query: params.query };
  }
}
