import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import { TripTabs } from 'vfc-admin/components/pages/trip-tabs-page';
import type { Trip } from 'vfc-admin/types/trip';
export default RouteTemplate<{
    Args: {
        model: {
            tripPromise: Promise<Trip>;
            tripId: string;
        };
    };
}>(template(`
    <TripTabs
      @tripType="current-course"
      @tripPromise={{@model.tripPromise}}
      @tripId={{@model.tripId}}
    />
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
