import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import Route from 'ember-route-template';
export default Route(template(`
    <div class="flex flex-1 items-center justify-center">
      <div class="w-full max-w-xs">
        <div class="grid grid-cols-2">
          <div class="flex flex-col items-center">
            <div class="text-7xl font-bold">
              404
            </div>
            <div class="text-lg">
              <div>
                opps..
              </div>
              <div class="whitespace-nowrap">
                {{t "page.not-found.not-found"}}
              </div>
            </div>
          </div>
          <img
            alt="plane crashing to describe that the page is not found"
            src="/images/plane-crash.png"
          />
        </div>
      </div>
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
