import Route from '@ember/routing/route';
import { service } from '@ember/service';

import type ConfigService from 'vfc-admin/services/config-service';

export default class Faqs extends Route {
  @service declare configService: ConfigService;

  async model() {
    const paymentPromise = this.configService.getPayment();

    return { paymentPromise };
  }
}
