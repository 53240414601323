import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { type FormData, HeadlessForm } from 'ember-headless-form';
import RouteTemplate from 'ember-route-template';
import ButtonText from 'vfc-admin/components/ui/button/text';
import { HtmlEditor } from 'vfc-admin/components/ui/html-editor';
import { awaitPromise } from 'vfc-admin/helpers/await-promise';
import { validateInputNatively } from 'vfc-admin/utils/form-utils';
import { extractTextFromHtml } from 'vfc-admin/utils/string-utils';
import type RouterService from '@ember/routing/router-service';
import type { IntlService } from 'ember-intl';
import type ConfigService from 'vfc-admin/services/config-service';
import type ToastService from 'vfc-admin/services/toast-service';
import type { Payment } from 'vfc-admin/types/configs/payment';
interface ConfigPaymentSignature {
    Element: HTMLDivElement;
    Args: {
        paymentPromise: Promise<Payment>;
    };
}
let ConfigPaymentComponent = class ConfigPaymentComponent extends Component<ConfigPaymentSignature> {
    @service
    intl: IntlService;
    @service
    router: RouterService;
    @service
    configService: ConfigService;
    @service
    toastService: ToastService;
    @tracked
    isBeingSaved = false;
    validateInputNatively = (event1: Event)=>validateInputNatively.call(this, event1);
    get paymentResource() {
        return awaitPromise<Payment>(this.args.paymentPromise);
    }
    get payment() {
        return this.paymentResource.result as Payment;
    }
    @action
    async onSubmit(data1: FormData<Payment>) {
        this.isBeingSaved = true;
        this.configService.updatePayment(data1).then(()=>{
            this.isBeingSaved = false;
            this.toastService.add({
                id: crypto.randomUUID(),
                message: 'Se ha guardado la configuración de pagos',
                type: 'success',
                duration: 3000
            });
        }).catch((error1)=>{
            console.log(error1);
        });
    }
    @action
    validateHtmlEditor(value1: string) {
        const text1 = extractTextFromHtml(value1);
        if (!text1) {
            return [
                {
                    type: 'valueMissing',
                    message: this.intl.t('common.form.validation.valueMissing')
                }
            ];
        }
    }
    static{
        template(`
    <div class="lg:col-span-8 md:col-span-6 sm:col-span-12 col-span-12">
      <div class="card">
        <div class="border-b border-light-dark py-4 px-6 flex items-center justify-between">
          <h5 class="card-title mb-0">Configuración de pagos</h5>
        </div>

        <div class="card-body">
          {{#if this.paymentResource.isPending}}
            Loading
          {{else if this.paymentResource.isError}}
            <tr class="text-center text-red-500">
              <td colspan="7">
                <div>Unexpected error has happened</div>
                <div class="text-sm">{{this.paymentResource.error.message}}</div>
              </td>
            </tr>
          {{else}}
            <HeadlessForm
              @data={{this.payment}}
              @onSubmit={{this.onSubmit}}
              @revalidateOn="input"
              as |form|
            >
              <div class="grid grid-cols-12 gap-x-6 gap-y-4 mt-5">
                <div class="col-span-12">
                  <div class="flex flex-col gap-4">
                    <h2>Información de pago <span class="text-red-500">*</span></h2>
                    <div>
                      <form.Field
                        @name="information"
                        @validate={{this.validateHtmlEditor}}
                        as |field|
                      >
                        <HtmlEditor
                          @content={{field.value}}
                          @onUpdate={{field.setValue}}
                          class="min-h-20 ql-container ql-snow
                            {{if field.isInvalid '!border-error'}}"
                        />
                        <field.Errors class="py-1 text-xs text-red-300" />
                      </form.Field>
                      <small>Este texto se muestra en el apartado "Pagos" en el area de cliente</small>
                    </div>
                  </div>
                </div>
                <div class="col-span-12">
                  <div class="flex gap-3 justify-end">
                    <ButtonText
                      @kind="solid-primary"
                      @isLoading={{this.isBeingSaved}}
                      type="submit"
                      disabled={{this.isBeingSaved}}
                    >
                      Guardar
                    </ButtonText>
                  </div>
                </div>
              </div>
            </HeadlessForm>
          {{/if}}
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate<{
    Args: {
        model: {
            paymentPromise: Promise<Payment>;
        };
    };
}>(template(`<ConfigPaymentComponent @paymentPromise={{@model.paymentPromise}} />`, {
    eval () {
        return eval(arguments[0]);
    }
}));
