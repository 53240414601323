import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import { TripDetailsTabComponent } from 'vfc-admin/components/pages/trip-details-tab';
import type Client from 'vfc-admin/models/Client';
import type School from 'vfc-admin/models/School';
import type { Trip, ClientRelation } from 'vfc-admin/types/trip';
export default RouteTemplate<{
    Args: {
        model: {
            tripPromise: Promise<Trip>;
            clientPromise: Promise<Client>;
            schoolPromise: Promise<School>;
            clientRelationsPromise: Promise<ClientRelation>;
            parentRedirectUrl: string;
        };
    };
}>(template(`
    <TripDetailsTabComponent
      @trip={{@model.tripPromise}}
      @clientPromise={{@model.clientPromise}}
      @schoolPromise={{@model.schoolPromise}}
      @clientRelationsPromise={{@model.clientRelationsPromise}}
      @parentRedirectUrl={{@model.parentRedirectUrl}}
    />
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
