import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { on } from '@ember/modifier';
import { get } from '@ember/object';
import { type FormData, HeadlessForm } from 'ember-headless-form';
import { t, type IntlService } from 'ember-intl';
import { urlForRoute } from 'vfc-admin/helpers/url-for-route';
import { not } from 'vfc-admin/utils/boolean-utils';
import { validateInputNatively } from 'vfc-admin/utils/form-utils';
import { concat } from 'vfc-admin/utils/string-utils';
import type { ClientForm } from 'vfc-admin/models/Client';
import { service } from '@ember/service';
export interface ClientFormComponentSignature {
    Element: HTMLElement;
    Args: {
        client: ClientForm;
        onSubmit?: (data: FormData<ClientForm>) => void;
    };
}
export default class ClientFormComponent extends Component<ClientFormComponentSignature> {
    @tracked
    errors = null;
    @service
    intl: IntlService;
    validateInputNatively = (event1: Event)=>{
        const input1 = event1.target as HTMLInputElement;
        const validityState1 = validateInputNatively.call(this, event1);
        if (validityState1.patternMismatch) {
            input1.setCustomValidity(this.intl.t('common.form.validation.custom.password-requirements'));
        }
    };
    onSubmit = async (data1: FormData<ClientForm>)=>{
        try {
            await this.args.onSubmit?.(data1);
        } catch (error1) {
            if (error1 instanceof Response) {
                error1.json().then((data1)=>{
                    this.errors = data1.errors;
                });
            }
        }
    };
    static{
        template(`
    <HeadlessForm
      @data={{@client}}
      @onSubmit={{this.onSubmit}}
      @validateOn="focusout"
      @revalidateOn="input"
      as |form|
    >
      <div class="grid grid-cols-12 gap-x-6 gap-y-4 mt-5">
        <div class="lg:col-span-6 col-span-12">
          <div class="flex flex-col gap-4">
            <div>
              <form.Field @name="username" as |field|>
                <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                  Usuario
                  <span class="text-red-500">*</span>
                </field.Label>
                <field.Input
                  required
                  maxLength="255"
                  {{on "invalid" this.validateInputNatively}}
                  class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                />
                <field.Errors class="py-1 text-xs text-red-300" />
              </form.Field>
            </div>
            <div>
              <form.Field @name="password" as |field|>
                <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                  Contraseña
                </field.Label>
                <field.Input
                  type="password"
                  minlength="12"
                  maxlength="128"
                  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@\$!%*?&_\\-])[A-Za-z\\d@\$!%*?&_\\-]{12,}\$"
                  placeholder="Contraseña"
                  {{on "invalid" this.validateInputNatively}}
                  class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                />
                <field.Errors class="py-1 text-xs text-red-300" />
                <div class="text-xs text-dark dark:text-darklink mt-1 flex items-center gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-info-circle"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#5D87FF"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                    <path d="M12 9h.01" />
                    <path d="M11 12h1v4h1" />
                  </svg>Dejar en blanco para no cambiar la contraseña
                </div>
              </form.Field>
            </div>
            <div>
              <form.Field @name="email" as |field|>
                <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                  Email
                  <span class="text-red-500">*</span>
                </field.Label>
                <field.Input
                  required
                  type="email"
                  {{on "invalid" this.validateInputNatively}}
                  class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                />
                <field.Errors class="py-1 text-xs text-red-300" />
              </form.Field>
            </div>
          </div>
        </div>
        <div class="lg:col-span-6 col-span-12">
          <div class="flex flex-col gap-4">
            <div>
              <form.Field @name="name" as |field|>
                <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                  Nombre
                  <span class="text-red-500">*</span>
                </field.Label>
                <field.Input
                  required
                  maxLength="255"
                  {{on "invalid" this.validateInputNatively}}
                  class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                />
                <field.Errors class="py-1 text-xs text-red-300" />
              </form.Field>
            </div>
            <div>
              <form.Field @name="phone" as |field|>
                <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                  Teléfono
                </field.Label>
                <field.Input
                  maxLength="255"
                  {{on "invalid" this.validateInputNatively}}
                  class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                />
                <field.Errors class="py-1 text-xs text-red-300" />
              </form.Field>
            </div>
            {{#if (not @client.id)}}
              <div class="mt-4">
                <form.Field @name="notify" as |field|>
                  <div class="flex gap-4 items-center">
                    <field.Checkbox />
                    <p> Notificar por email al cliente</p>
                  </div>
                </form.Field>
              </div>
            {{/if}}
          </div>
        </div>
        <div class="col-span-12">
          <div class="flex gap-3 justify-end">
            <button class="btn btn-md" type="submit">Guardar</button>
            <a class="btn btn-light-error" href="{{urlForRoute 'authenticated.clients'}}">
              Cancelar
            </a>
          </div>
        </div>
      </div>
      {{#if this.errors}}
        <div class="flex flex-col p-2 text-sm text-red-500">
          <div>
            {{t "common.error"}}
          </div>
          <div class="flex flex-col">
            {{#each this.errors as |error|}}
              {{#let (get error "path") (get error "message") as |path message|}}
                <div>
                  <span>- {{t (concat "common.form.validation.custom." message) field=path}}</span>
                </div>
              {{/let}}
            {{/each}}
          </div>
        </div>
      {{/if}}
    </HeadlessForm>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
