import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import { TripDocumentsTabComponent } from 'vfc-admin/components/pages/trip-documents-tab';
import type Trip from 'vfc-admin/services/trip-service';
export default RouteTemplate<{
    Args: {
        model: {
            tripPromise: Promise<Trip>;
        };
    };
}>(template(`<TripDocumentsTabComponent @tripPromise={{@model.tripPromise}} />`, {
    eval () {
        return eval(arguments[0]);
    }
}));
