import Service from '@ember/service';
import { service } from '@ember/service';

import config from 'vfc-admin/config/environment';

import type AuthenticatedHttpClient from 'vfc-admin/services/auth/http-client';
import type { TODO } from 'vfc-admin/utility-types';

export default class TripDocumentsService extends Service {
  @service('auth/http-client') declare authenticatedHttpClient: AuthenticatedHttpClient;

  static ENDPOINTS = {
    upload: '/trip/:tripId/files/:name',
    downloadSummaryPDF: '/trip/:tripId/files/summary',
    removeSummaryPDF: '/trip/:tripId/files/summary',
    downloadContractPDF: '/trip/:tripId/files/contract',
    removeContractPDF: '/trip/:tripId/files/contract',
    setContractState: '/trip/:tripId/files/contract/:state',
    downloadProformaPDF: '/trip/:tripId/files/proforma',
    removeProformaPDF: '/trip/:tripId/files/proforma',
  };

  upload(tripId: string, name: string, file: File): Promise<Response> {
    const endpoint = TripDocumentsService.ENDPOINTS.upload
      .replace(':tripId', tripId)
      .replace(':name', name);
    const headers = new Headers();

    const formData = new FormData();

    formData.append('file', file);

    return this.authenticatedHttpClient.POST<TODO>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
      headers,
      body: formData,
    });
  }

  downloadSummaryPDF(tripId: string): Promise<Response> {
    const endpoint = TripDocumentsService.ENDPOINTS.downloadSummaryPDF.replace(':tripId', tripId);

    return this.authenticatedHttpClient.GET<TODO>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
    });
  }

  removeSummaryPDF(tripId: string): Promise<Response> {
    const endpoint = TripDocumentsService.ENDPOINTS.removeSummaryPDF.replace(':tripId', tripId);

    return this.authenticatedHttpClient.DELETE<TODO>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
    });
  }

  downloadContractPDF(tripId: string): Promise<Response> {
    const endpoint = TripDocumentsService.ENDPOINTS.downloadContractPDF.replace(':tripId', tripId);

    return this.authenticatedHttpClient.GET<TODO>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
    });
  }

  removeContractPDF(tripId: string): Promise<Response> {
    const endpoint = TripDocumentsService.ENDPOINTS.removeContractPDF.replace(':tripId', tripId);

    return this.authenticatedHttpClient.DELETE<TODO>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
    });
  }

  downloadProformaPDF(tripId: string): Promise<Response> {
    const endpoint = TripDocumentsService.ENDPOINTS.downloadProformaPDF.replace(':tripId', tripId);

    return this.authenticatedHttpClient.GET<TODO>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
    });
  }

  removeProformaPDF(tripId: string): Promise<Response> {
    const endpoint = TripDocumentsService.ENDPOINTS.removeProformaPDF.replace(':tripId', tripId);

    return this.authenticatedHttpClient.DELETE<TODO>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
    });
  }

  setContractState(tripId: string, state: 'accept' | 'reject'): Promise<Response> {
    const endpoint = TripDocumentsService.ENDPOINTS.setContractState
      .replace(':tripId', tripId)
      .replace(':state', state);

    return this.authenticatedHttpClient.GET<TODO>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
    });
  }

  rejectContract(tripId: string): Promise<Response> {
    const endpoint = TripDocumentsService.ENDPOINTS.state.replace(':tripId', tripId);

    return this.authenticatedHttpClient.PUT<TODO>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'trip-documents-service': TripDocumentsService;
  }
}
