import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import { get } from '@ember/object';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { headlessTable } from 'ember-headless-table';
import { forColumn, Metadata } from 'ember-headless-table/plugins/metadata';
import RouteTemplate from 'ember-route-template';
import { DestinationCellComponent } from 'vfc-admin/components/pages/trip-list-page';
import { Pagination } from 'vfc-admin/components/ui/pagination';
import TableSkeletonLoader from 'vfc-admin/components/ui/table-skeleton-loader';
import config from 'vfc-admin/config/environment';
import { awaitPromise } from 'vfc-admin/helpers/await-promise';
import { formatDate } from 'vfc-admin/utils/date-utils';
import { debounce } from 'vfc-admin/utils/fn-utils';
import type { TOC } from '@ember/component/template-only';
import type Owner from '@ember/owner';
import type RouterService from '@ember/routing/router-service';
import type { Args } from '@glimmer/component/-private/component';
import type { Column, Row } from 'ember-headless-table';
import type { IntlService } from 'ember-intl';
import type ThemeService from 'vfc-admin/services/theme-service';
import type { TripsResponse } from 'vfc-admin/services/trip-service';
import type { Trip } from 'vfc-admin/types/trip';
const INTL_COLUMNS_PREFIX = 'page.trips.current-course.table.columns';
const isFormatDate = (column1: Column<Trip>)=>{
    return forColumn(column1, 'formatDate');
};
const ActionsCellComponent: TOC<{
    Element: HTMLElement;
    Args: {
        row: Row;
        column: Column;
    };
}> = template(`
  <div class="action-btn flex gap-3 text-center">
    <a href="/trips/incomplete/{{get @row.data 'id'}}" class="text-info edit">
      <i class="ti ti-pencil text-lg"></i>
    </a>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface TripIncompleteSignature {
    Element: HTMLDivElement;
    Args: {
        trips: Promise<TripsResponse>;
        query: string;
    };
}
let TripIncompleteComponent = class TripIncompleteComponent extends Component<TripIncompleteSignature> {
    @service
    themeService: ThemeService;
    @service
    intl: IntlService;
    @service
    router: RouterService;
    get tripsResource() {
        return awaitPromise<TripsResponse>(this.args.trips);
    }
    get totalPages() {
        if (!this.tripsResource.isSuccess) return 0;
        return Math.ceil((this.tripsResource?.result?.meta?.total ?? 1) / (this.tripsResource?.result?.meta?.pageSize ?? 1));
    }
    get pageSize() {
        return this.tripsResource?.result?.meta?.pageSize ?? config.APP.PAGE_SIZE;
    }
    get page() {
        return this.tripsResource?.result?.meta?.page ?? 1;
    }
    tripsTable = headlessTable<Trip>(this, {
        columns: ()=>[
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.reference`),
                    key: 'reference'
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.school`),
                    key: 'infoTmp.schoolName'
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.contact`),
                    key: 'infoTmp.clientName'
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.destination`),
                    key: 'destination.name',
                    Cell: DestinationCellComponent
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.checkIn`),
                    key: 'checkIn',
                    pluginOptions: [
                        Metadata.forColumn(()=>({
                                formatDate: config.APP.DATE.DEFAULT_FORMAT
                            }))
                    ]
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.checkOut`),
                    key: 'checkOut',
                    pluginOptions: [
                        Metadata.forColumn(()=>({
                                formatDate: config.APP.DATE.DEFAULT_FORMAT
                            }))
                    ]
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.actions`),
                    Cell: ActionsCellComponent
                }
            ],
        data: ()=>{
            return this.tripsResource?.result?.data ?? [];
        }
    });
    @action
    setfilterQuery(event1: Event) {
        const value1 = (event1.target as HTMLInputElement).value;
        debounce(()=>{
            if (this.args.query !== value1) {
                this.router.transitionTo('authenticated.trips.incomplete', {
                    queryParams: {
                        query: value1,
                        page: undefined
                    }
                });
            }
        }, config.APP.TYPING_DELAY)();
    }
    constructor(owner1: Owner, args1: Args<TripIncompleteSignature>){
        super(owner1, args1);
    }
    static{
        template(`
    <div class="lg:col-span-8 md:col-span-6 sm:col-span-12 col-span-12">
      <div class="card">

        <div class="border-b border-light-dark py-4 px-6 flex items-center justify-between">
          <h5 class="card-title">Viajes - Incompletos</h5>
        </div>

        <div class="card-body">
          <form class="relative w-full mb-4" onsubmit="return false;">
            <input
              type="text"
              placeholder="Buscar"
              class="form-control py-2 ps-10 w-1/4 min-w-72"
              {{on "input" this.setfilterQuery}}
              value={{this.args.query}}
            />
            <i
              class="ti ti-search absolute top-2.5 start-0 translate-middle-y text-lg text-dark ms-3"
            ></i>
          </form>
          <div class="flex flex-col">
            <div class="-m-1.5 overflow-x-auto">
              <div class="p-1.5 min-w-full inline-block align-middle">
                <div class="overflow-hidden">
                  <table
                    class="min-w-full divide-y divide-border dark:divide-darkborder"
                    {{this.tripsTable.modifiers.container}}
                  >
                    <thead>
                      <tr>
                        {{#each this.tripsTable.columns as |column|}}
                          <th
                            scope="col"
                            class="text-left rtl:text-right p-4 ps-0 font-semibold text-dark dark:text-white"
                          >
                            {{column.name}}
                          </th>
                        {{/each}}
                      </tr>
                    </thead>
                    <tbody class="divide-y divide-border dark:divide-darkborder">
                      {{#if this.tripsResource.isPending}}
                        <TableSkeletonLoader @rows={{8}} @columns={{7}} @columnClass={{"py-3"}} />
                      {{else if this.tripsResource.isError}}
                        <tr class="text-center text-red-500">
                          <td colspan="7">
                            <div>Unexpected error has happened</div>
                            <div class="text-sm">{{this.tripsResource.error.message}}</div>
                          </td>
                        </tr>
                      {{else}}
                        {{#each this.tripsTable.rows as |row|}}
                          <tr>
                            {{#each this.tripsTable.columns as |column|}}
                              <td class="text-dark dark:text-darklink text-base font-light py-3">
                                {{#if column.Cell}}
                                  <column.Cell @row={{row}} @column={{column}} />
                                {{else}}
                                  {{#let (isFormatDate column) as |formatDateValue|}}
                                    {{#if formatDateValue}}
                                      {{formatDate (column.getValueForRow row) formatDateValue}}
                                    {{else}}
                                      {{column.getValueForRow row}}
                                    {{/if}}
                                  {{/let}}
                                {{/if}}
                              </td>
                            {{/each}}
                          </tr>
                        {{/each}}
                      {{/if}}
                    </tbody>
                  </table>
                  {{#if this.tripsResource.isSuccess}}
                    <div class="flex justify-center mt-4">
                      <Pagination
                        @totalPages={{this.totalPages}}
                        @currentPage={{this.page}}
                        @pageSize={{this.pageSize}}
                      />
                    </div>
                  {{/if}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate<{
    Args: {
        model: {
            trips: Promise<TripsResponse>;
            query: string;
        };
    };
}>(template(`<TripIncompleteComponent @trips={{@model.trips}} @query={{@model.query}} />`, {
    eval () {
        return eval(arguments[0]);
    }
}));
