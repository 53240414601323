import Route from '@ember/routing/route';
import { service } from '@ember/service';

import config from 'vfc-admin/config/environment';

import type TripService from 'vfc-admin/services/trip-service';

type Params = {
  page: string;
};

export default class TripCurrentCourse extends Route {
  queryParams = {
    query: {
      refreshModel: true,
    },
    page: {
      refreshModel: true,
    },
  };

  pageSize = config.APP.PAGE_SIZE;
  @service declare tripService: TripService;

  async model(params: Params) {
    const page = Number(params.page ?? 1);
    const trips = this.tripService.currentCourse(params.query, {
      page,
      pageSize: this.pageSize,
    });

    return {
      trips,
      query: params.query,
    };
  }
}
