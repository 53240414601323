import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { modifier } from 'ember-modifier';
import Loader from 'vfc-admin/components/ui/loader';
import { awaitPromise } from 'vfc-admin/helpers/await-promise';
import { Tag } from 'vfc-admin/models/tag';
import { eq } from 'vfc-admin/utils/boolean-utils';
import type { Args } from '@glimmer/component/-private/component';
import type IntlService from 'ember-intl/services/intl';
interface InputTagsArgs {
    Element: HTMLDivElement;
    Args: {
        value?: number | string | Array<Tag>;
        autocomplete: boolean;
        availableTagsPromise?: Promise<Array<Tag>>;
        onChange?: (value: Array<Tag>) => void;
        placeholder?: string;
    };
}
export default class InputTags extends Component<InputTagsArgs> {
    @tracked
    private tags: Array<Tag> = [];
    @tracked
    private inputValue = '';
    @tracked
    private isInputFocused = false;
    @service
    intl: IntlService;
    autocompleteUL: HTMLUListElement;
    constructor(owner1: unknown, args1: Args<InputTagsArgs>){
        super(owner1, args1);
        const value1 = this.args.value;
        if (typeof value1 === 'string') {
            this.tags = [
                new Tag(value1)
            ];
        } else if (Array.isArray(value1)) {
            this.tags = value1.map((v1)=>new Tag(v1.name, v1.id));
        } else {
            this.tags = [];
        }
    }
    setAutocompleteUL = modifier((element1: HTMLUListElement)=>{
        this.autocompleteUL = element1;
    });
    get availableTagsPromise() {
        return awaitPromise<Array<Tag>>(this.args.availableTagsPromise);
    }
    get availableTags(): Array<Tag> {
        if (this.availableTagsPromise.isSuccess) {
            return this.availableTagsPromise.result.filter((tag1)=>!this.tags.map((v1)=>v1.name).includes(tag1.name) && tag1.name.toLowerCase().includes(this.inputValue.toLowerCase()));
        }
        return [];
    }
    get placeholder(): string {
        return this.isInputFocused || this.tags.length === 0 ? this.args.placeholder ?? this.intl.t('components.forms.input_tags.placeholder') : '';
    }
    @action
    handleInput(event1: Event) {
        this.inputValue = (event1.target as HTMLInputElement).value;
    }
    @action
    addTag(value1: string) {
        if (value1) {
            const tag1 = new Tag(value1);
            this.tags = [
                ...this.tags,
                tag1
            ];
            this.inputValue = '';
            this.args.onChange?.(this.tags);
        }
    }
    @action
    removeTag(tag1: Tag) {
        this.tags = this.tags.filter((t1)=>t1.id !== tag1.id);
        this.args.onChange?.(this.tags);
    }
    @action
    removeLastTag() {
        if (this.tags.length > 0) {
            this.tags = this.tags.slice(0, -1);
        }
    }
    @action
    handleKeyDown(event1: KeyboardEvent) {
        if (event1.key === 'Enter') {
            event1.preventDefault();
            const value1 = this.inputValue.trim();
            if (value1) {
                this.addTag(value1);
            }
        } else if (event1.key === 'Backspace' && this.inputValue.trim() === '') {
            event1.preventDefault();
            this.removeLastTag();
        } else if (event1.key === 'Escape') {
            (event1.target as HTMLInputElement).blur();
        }
    }
    @action
    handleBlur(event1: Event) {
        const relatedTarget1 = event1.relatedTarget;
        if (this.autocompleteUL.contains(relatedTarget1) && 'BUTTON' === relatedTarget1?.tagName) {
            this.addTag(relatedTarget1.value);
            this.isInputFocused = false;
        } else if (this.autocompleteUL === relatedTarget1) {
            event1.preventDefault();
            event1.currentTarget.focus();
        } else {
            this.isInputFocused = false;
        }
    }
    @action
    handleFocus() {
        this.isInputFocused = true;
    }
    static{
        template(`
    <div class="flex flex-wrap items-center space-x-1 p-2 border border-gray-300 rounded-md">
      {{#each this.tags as |tag|}}
        <span
          class="flex items-center bg-blue-100 text-blue-800 text-sm font-medium px-2.5 py-0.5 rounded-full"
        >
          {{tag.name}}
          <button
            type="button"
            class="ml-1 text-blue-600 hover:text-blue-800 focus:outline-none"
            {{on "click" (fn this.removeTag tag)}}
          >
            <svg
              class="w-3 h-3"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </span>
      {{/each}}

      <div class="relative flex-1">
        <input
          type="text"
          value={{this.inputValue}}
          placeholder={{this.placeholder}}
          class="!border-0 flex-grow w-full p-1 text-sm rounded"
          {{on "input" this.handleInput}}
          {{on "keydown" this.handleKeyDown}}
          {{on "blur" this.handleBlur}}
          {{on "focus" this.handleFocus}}
        />

        {{#if @autocomplete}}
          {{#if this.isInputFocused}}
            <ul
              {{this.setAutocompleteUL}}
              tabindex="0"
              class="absolute flex flex-col gap-y-2 z-10 p-2 min-w-48 bg-white border border-gray-100 rounded-md shadow-lg"
            >
              {{#if this.availableTagsPromise.isPending}}
                <li>
                  <Loader />
                </li>
              {{else if (eq this.availableTags.length 0)}}
                <li>
                  <span class="text-sm text-gray-500">No hay etiquetas disponibles</span>
                </li>
              {{else}}
                {{#each this.availableTags as |tag|}}
                  <li class="hover:bg-lightsecondary px-2 py-1">
                    <button
                      value={{tag.name}}
                      class="flex items-center bg-blue-100 text-blue-800 text-sm font-medium px-2.5 py-0.5 rounded-full"
                    >
                      {{tag.name}}
                    </button>
                  </li>
                {{/each}}
              {{/if}}
            </ul>
          {{/if}}
        {{/if}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
