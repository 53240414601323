import Helper from '@ember/component/helper';
import { service } from '@ember/service';

import type RouterService from '@ember/routing/router-service';

type Positional = Array<string>;

export interface IsActiveRouteSignature {
  Args: {
    Positional: Positional;
  };
  Return: boolean;
}

class IsActiveRoute extends Helper<IsActiveRouteSignature> {
  @service declare router: RouterService;
  compute([url]: [string]) {
    return this.router.isActive(url);
  }
}

export { IsActiveRoute as isActiveRoute };
