import Route from '@ember/routing/route';
import { service } from '@ember/service';

import type UserService from 'vfc-admin/services/user-service';

export default class Profile extends Route {
  @service declare userService: UserService;

  async model() {
    const userPromise = this.userService.userProfile;

    return { userPromise };
  }
}
