import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { HeadlessForm } from 'ember-headless-form';
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import { validateInputNatively } from 'vfc-admin/utils/form-utils';
import type RouterService from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';
import type UserService from 'vfc-admin/services/user-service';
interface ChangePasswordComponentArgs {
    Args: {
        model: void;
    };
}
let ChangePasswordComponent = class ChangePasswordComponent extends Component<ChangePasswordComponentArgs> {
    @service
    router: RouterService;
    @service
    userService: UserService;
    @service
    intl: IntlService;
    @tracked
    passwordVisible = false;
    @tracked
    confirmPasswordVisible = false;
    validateInputNatively = (event1: Event)=>{
        const input1 = event1.target as HTMLInputElement;
        const validityState1 = validateInputNatively.call(this, event1);
        if (validityState1.patternMismatch) {
            input1.setCustomValidity(this.intl.t('common.form.validation.custom.password-requirements'));
        }
    };
    validatePassword = ({ password: password1, confirmPassword: confirmPassword1 }: {
        password: string;
        confirmPassword: string;
    })=>{
        if (password1 !== confirmPassword1) {
            return {
                confirmPassword: [
                    {
                        type: 'emailConfirmationMatch',
                        value: confirmPassword1,
                        message: `Las contraseñas no coinciden`
                    }
                ]
            };
        }
    };
    @action
    async changePassword(formData1: FormData) {
        formData1.token = this.args.token;
        this.userService.changePassword(formData1).finally(()=>{
            this.router.transitionTo('login');
        });
    }
    @action
    togglePasswordVisibility() {
        this.passwordVisible = !this.passwordVisible;
    }
    @action
    toggleConfirmPasswordVisibility() {
        this.confirmPasswordVisible = !this.confirmPasswordVisible;
    }
    static{
        template(`
    <main class="h-screen w-full bg-lightprimary">
      <div class="h-full w-full flex justify-center items-center">
        <div class="flex justify-center w-full">
          <div class="xl:w-2/6 w-full">
            <div class="max-w-[460px] px-3 mx-auto">
              <div class="card">
                <div class="card-body">
                  <div class="mx-auto text-center mb-12">
                    <div class="flex justify-center">
                      <div class="brand-logo flex items-center">
                        <img src="/images/logos/logo.svg" alt="Logo" />
                      </div>
                    </div>
                  </div>
                  <HeadlessForm
                    @validate={{this.validatePassword}}
                    @validateOn="focusout"
                    @revalidateOn="input"
                    @onSubmit={{this.changePassword}}
                    as |form|
                  >
                    <div class="flex flex-col gap-4 mt-4">
                      <div>
                        <form.Field @name="password" as |field|>
                          <field.Label
                            class="text-dark dark:text-darklink font-semibold mb-2 block"
                          >
                            Nueva contraseña
                          </field.Label>
                          <div class="flex items-center">
                            <field.Input
                              required
                              type="{{if this.passwordVisible 'text' 'password'}}"
                              {{on "invalid" this.validateInputNatively}}
                              class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                              name="password"
                              minlength="12"
                              maxlength="128"
                              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@\$!%*?&_\\-])[A-Za-z\\d@\$!%*?&_\\-]{12,}\$"
                              title={{t "common.form.validation.custom.password-requirements"}}
                            />
                            {{#if this.passwordVisible}}
                              <i
                                class="ti ti-eye -m-6 cursor-pointer"
                                {{on "click" this.togglePasswordVisibility}}
                              />
                            {{else}}
                              <i
                                class="ti ti-eye-off -m-6 cursor-pointer"
                                {{on "click" this.togglePasswordVisibility}}
                              />
                            {{/if}}
                          </div>
                          <field.Errors class="text-xs text-red-500" />
                        </form.Field>
                      </div>
                      <div>
                        <form.Field @name="confirmPassword" as |field|>
                          <field.Label
                            class="text-dark dark:text-darklink font-semibold mb-2 block"
                          >
                            Repetir contraseña
                          </field.Label>
                          <field.Input
                            required
                            type="{{if this.confirmPasswordVisible 'text' 'password'}}"
                            {{on "invalid" this.validateInputNatively}}
                            class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                            name="confirmPassword"
                          />
                          {{#if this.passwordVisible}}
                            <i
                              class="ti ti-eye -m-6 cursor-pointer"
                              {{on "click" this.toggleConfirmPasswordVisibility}}
                            />
                          {{else}}
                            <i
                              class="ti ti-eye-off -m-6 cursor-pointer"
                              {{on "click" this.toggleConfirmPasswordVisibility}}
                            />
                          {{/if}}
                          <field.Errors class="text-xs text-red-500" />
                        </form.Field>
                      </div>
                      <button class="btn btn-md py-3">Actualizar contraseña</button>
                    </div>
                    <div class="text-xs text-red-500 py-1 flex justify-center">
                      {{this.errorMessage}}&#8203;
                    </div>
                    <div class="flex items-center flex-col mt-4">
                      <a
                        href="/login"
                        class="text-sm text-accent1-400 hover:text-primary cursor-pointer"
                      >
                        Volver a iniciar sesión
                      </a>
                    </div>
                  </HeadlessForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate<{
    Args: {
        model: {
            token: string;
        };
    };
}>(template(`<ChangePasswordComponent @token="{{@model.token}}" />`, {
    eval () {
        return eval(arguments[0]);
    }
}));
