import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { modifier } from 'ember-modifier';
import onClickOutSide from 'vfc-admin/modifiers/on-click-outside';
import type Owner from '@ember/owner';
import type { Args } from '@glimmer/component/-private/component';
export class ComboboxItem {
    @tracked
    value: string;
    @tracked
    label: string;
    constructor(label1: string, value1: string | number){
        this.label = label1;
        this.value = String(value1);
    }
}
export interface ComboboxSignature {
    Element: HTMLElement;
    Args: {
        value?: string | number | undefined;
        placeholder?: string;
        isLoading?: boolean;
        items: ComboboxItem[];
        invalid: boolean;
        disabled?: boolean;
        onChange?: (value: string | number | undefined) => void;
    };
}
export default class Combobox extends Component<ComboboxSignature> {
    @tracked
    itemSelected: ComboboxItem | undefined;
    @tracked
    searchInputValue: string = '';
    @tracked
    open: boolean = false;
    @tracked
    invalid: boolean = false;
    valueInput?: HTMLInputElement;
    searchInput?: HTMLInputElement;
    registerSearchInput = modifier((el1: HTMLInputElement)=>{
        this.searchInput = el1;
    });
    registerValueInput = modifier((el1: HTMLInputElement)=>{
        this.valueInput = el1;
    });
    get _itemSelected() {
        let initialValue1 = null;
        if (this.args.value) {
            initialValue1 = (this.args.items || []).find((item1: ComboboxItem)=>String(item1.value) === String(this.args.value));
        }
        return this.itemSelected ?? initialValue1;
    }
    get buttonLabel() {
        return (this._itemSelected?.label || (this.args.isLoading ? 'Cargando...' : this.args.placeholder));
    }
    get icon(): string {
        return 'ti ti-chevron-down';
    }
    get isDisabled(): boolean {
        return this.args.disabled === true;
    }
    get isInvalid(): boolean {
        return this.args.invalid || (this.invalid && !this.open);
    }
    get cssClassLabel(): string {
        return this._itemSelected ? 'text-dark' : 'text-bodytext';
    }
    get filterItems() {
        return (this.args.items || []).filter((item1: ComboboxItem)=>item1.label.toLowerCase().includes(this.searchInputValue));
    }
    onSelectItem = (label1: string, value1: string)=>{
        this.itemSelected = new ComboboxItem(label1, value1);
        this.open = false;
        this.valueInput.value = value1;
        this.args.onChange?.(value1);
        const focusOutEvent1 = new Event('focusout', {
            bubbles: true
        });
        const changeEvent1 = new Event('change', {
            bubbles: true
        });
        this.valueInput?.dispatchEvent(focusOutEvent1);
        this.valueInput?.dispatchEvent(changeEvent1);
    };
    toggle = (open1: boolean)=>{
        if (this.isDisabled) return;
        this.open = open1;
        if (open1) {
            this.searchInput?.focus();
        }
    };
    @action
    isSelectedItem(value1: string): boolean {
        return this._itemSelected?.value === value1;
    }
    @action
    onUpdateInput(event1: Event) {
        this.searchInputValue = (event1.target as HTMLSelectElement).value.toLowerCase();
    }
    @action
    onBlur() {
        this.invalid = this.validation.call(this);
    }
    @action
    defaultValidation() {
        return this._itemSelected === null;
    }
    validation = this.args.validation ?? this.defaultValidation;
    static{
        template(`
    <div {{onClickOutSide (fn this.toggle false)}}>
      <input
        type="text"
        ...attributes
        value={{@value}}
        {{this.registerValueInput}}
        class="hidden"
        disabled={{this.isDisabled}}
      />
      <button
        type="button"
        class="py-2.5 px-4 form-control flex justify-between items-center
          {{if this.isInvalid 'border-error'}}
          {{if this.isDisabled 'cursor-default opacity-50'}}"
        {{on "click" (fn this.toggle true)}}
      >
        <span class="{{this.cssClassLabel}}">{{this.buttonLabel}}</span>
        <i class="{{this.icon}} text-base"></i>
      </button>

      <div class="relative {{if this.open 'z-10' 'opacity-0 -z-50'}}">
        <div class="absolute w-full shadow-md bg-white">
          <input
            type="text"
            class="w-full form-control"
            placeholder="Buscar..."
            {{this.registerSearchInput}}
            {{on "input" this.onUpdateInput}}
            {{on "blur" this.onBlur}}
          />
          <ul class="{{if this.open 'block' 'hidden'}}">
            {{#each this.filterItems as |item|}}
              <li
                class="text-dark p-2 hover:bg-lightgray hover:color-red cursor-pointer
                  {{if (this.isSelectedItem item.value) 'bg-lightgray' ''}}"
                value="{{item.value}}"
                {{on "click" (fn this.onSelectItem item.label item.value)}}
              >
                <span>{{item.label}}</span>
              </li>
            {{/each}}
            {{#unless this.filterItems.length}}
              <li class="p-4"><span>No se han encontrado resultados...</span></li>
            {{/unless}}
          </ul>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
