import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
export default RouteTemplate<{
    Args: {
        model: {
        };
    };
}>(template(`Invoices`, {
    eval () {
        return eval(arguments[0]);
    }
}));
