import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import { TripRoomsTabComponent } from 'vfc-admin/components/pages/trip-rooms-tab';
import type { RoomResponse } from 'vfc-admin/services/trip-room-service';
export default RouteTemplate<{
    Args: {
        model: {
            roomsPromise: Promise<RoomResponse>;
            tripId: string;
        };
    };
}>(template(`
    <TripRoomsTabComponent @tripId={{@model.tripId}} @tripRoomsPromise={{@model.roomsPromise}} />
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
