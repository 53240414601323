import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import { isActiveRoute } from 'vfc-admin/helpers/is-active-route';
import { urlForRoute } from 'vfc-admin/helpers/url-for-route';
import type { TOC } from '@ember/component/template-only';
export const Sidebar: TOC<{
    Element: HTMLElement;
    Blocks: {
        default: [];
    };
}> = template(`
  <aside
    id="application-sidebar-brand"
    class="hs-overlay hs-overlay-open:translate-x-0 -translate-x-full xl:rtl:-translate-x-0 rtl:translate-x-full left-0 rtl:left-auto rtl:right-0 transform hidden xl:block xl:translate-x-0 xl:end-auto xl:bottom-0 fixed top-0 with-vertical left-sidebar transition-all duration-300 h-screen xl:z-[2] z-[60] flex-shrink-0 border-r rtl:border-l rtl:border-r-0 w-[270px] border-border dark:border-darkborder bg-white dark:bg-dark"
  >
    <!-- Start Vertical Layout Sidebar -->
    <div class="py-5 px-5 flex justify-center">
      <div class="brand-logo flex items-center">
        <a href="/" class="text-nowrap logo-img">
          <img src="/images/logos/logo.svg" class="block" alt="Logo" />
        </a>
      </div>
    </div>
    <div class="overflow-hidden">
      <div class="scroll-sidebar simplebar-scrollable-y" data-simplebar="init">
        <div class="simplebar-wrapper" style="margin: 0px;">
          <div class="simplebar-height-auto-observer-wrapper">
            <div class="simplebar-height-auto-observer">
            </div>
          </div>
          <div class="simplebar-mask">
            <div class="simplebar-offset" style="right: 0px; bottom: 0px;">
              <div
                class="simplebar-content-wrapper"
                tabindex="0"
                role="region"
                aria-label="scrollable content"
                style="height: 100%; overflow: hidden scroll;"
              >
                <div class="simplebar-content" style="padding: 0px;">
                  <div class="px-6 mt-8 mini-layout" data-te-sidenav-menu-ref="">
                    <nav class="hs-accordion-group w-full flex flex-col">
                      <ul data-te-sidenav-menu-ref="" id="sidebarnav">
                        <div class="caption capitalize">
                          <li class="sidebar-item">
                            <a
                              class="sidebar-link dark-sidebar-link dark:text-white
                                {{if (isActiveRoute 'authenticated.calendar') 'active activemenu'}}"
                              href={{urlForRoute "authenticated.calendar"}}
                            >
                              <i class="ti ti-calendar text-xl flex-shrink-0"></i>
                              <span class="hide-menu flex-shrink-0">
                                {{t "components.ui.sidebar.menu.calendar"}}
                              </span>
                            </a>
                          </li>
                        </div>
                        <div class="caption">
                          <i class="ti ti-dots nav-small-cap-icon" />
                          <span class="hide-menu">
                            {{t "components.ui.sidebar.menu.section1"}}
                          </span>
                        </div>
                        <li class="sidebar-item">
                          <a
                            class="sidebar-link dark-sidebar-link dark:text-white
                              {{if
                                (isActiveRoute 'authenticated.trips.incomplete')
                                'active activemenu'
                              }}"
                            href={{urlForRoute "authenticated.trips.incomplete"}}
                          >
                            <i class="ti ti-file-broken text-xl flex-shrink-0"></i>
                            <span class="hide-menu flex-shrink-0">
                              {{t "components.ui.sidebar.menu.trips-incomplete"}}
                            </span>
                          </a>
                        </li>
                        <li class="sidebar-item">
                          <a
                            class="sidebar-link dark-sidebar-link dark:text-white
                              {{if
                                (isActiveRoute 'authenticated.trips.pending')
                                'active activemenu'
                              }}"
                            href={{urlForRoute "authenticated.trips.pending"}}
                          >
                            <i class="ti ti-file-report text-xl flex-shrink-0"></i>
                            <span class="hide-menu flex-shrink-0">
                              {{t "components.ui.sidebar.menu.trips-pending"}}
                            </span>
                          </a>
                        </li>
                        <li class="sidebar-item">
                          <a
                            class="sidebar-link dark-sidebar-link dark:text-white
                              {{if
                                (isActiveRoute 'authenticated.trips.current-course')
                                'active activemenu'
                              }}"
                            href={{urlForRoute "authenticated.trips.current-course"}}
                          >
                            <i class="ti ti-plane text-xl flex-shrink-0"></i>
                            <span class="hide-menu flex-shrink-0">
                              {{t "components.ui.sidebar.menu.trips-current-course"}}
                            </span>
                          </a>
                        </li>
                        <div class="caption mt-8">
                          <i class="ti ti-dots nav-small-cap-icon" />
                          <span class="hide-menu">
                            {{t "components.ui.sidebar.menu.section2"}}
                          </span>
                        </div>
                        <li class="sidebar-item">
                          <a
                            class="sidebar-link dark-sidebar-link dark:text-white
                              {{if (isActiveRoute 'authenticated.clients') 'active activemenu'}}"
                            href={{urlForRoute "authenticated.clients"}}
                          >
                            <i class="ti ti-users text-xl flex-shrink-0"></i>
                            <span class="hide-menu flex-shrink-0">
                              {{t "components.ui.sidebar.menu.clients"}}
                            </span>
                          </a>
                        </li>
                        <li class="sidebar-item">
                          <a
                            class="sidebar-link dark-sidebar-link dark:text-white
                              {{if (isActiveRoute 'authenticated.schools') 'active activemenu'}}"
                            href={{urlForRoute "authenticated.schools"}}
                          >
                            <i class="ti ti-school text-xl flex-shrink-0"></i>
                            <span class="hide-menu flex-shrink-0">
                              {{t "components.ui.sidebar.menu.schools"}}
                            </span>
                          </a>
                        </li>
                        <div class="caption mt-8">
                          <i class="ti ti-dots nav-small-cap-icon" />
                          <span class="hide-menu">
                            {{t "components.ui.sidebar.menu.section3"}}
                          </span>
                        </div>
                        <li class="sidebar-item">
                          <a
                            class="sidebar-link dark-sidebar-link dark:text-white
                              {{if (isActiveRoute 'authenticated.faqs') 'active activemenu'}}"
                            href={{urlForRoute "authenticated.faqs"}}
                          >
                            <i class="ti ti-question-mark text-xl flex-shrink-0"></i>
                            <span class="hide-menu flex-shrink-0">
                              {{t "components.ui.sidebar.menu.faqs"}}
                            </span>
                          </a>
                        </li>
                        <li class="hs-accordion sidebar-item" id="auth-login">
                          <a class="hs-accordion-toggle sidebar-link dropdown-menu-link">
                            <i class="ti ti-settings text-xl flex-shrink-0"></i>
                            <span class="hide-menu">Configuración</span>
                            <span class="hide-menu ms-auto">
                              <i
                                class="ti ti-chevron-down text-lg ms-auto hs-accordion-active:hidden"
                              ></i>
                              <i
                                class="ti ti-chevron-up text-lg ms-auto hs-accordion-active:block ml-auto hidden z-10 relative"
                              ></i>
                            </span>
                          </a>

                          <div id="auth-login" class="hs-accordion-content" style="height: 0px;">
                            <ul class="">
                              <li class="pl-4 pr-3">

                                <a
                                  class="dropdown-submenu-link"
                                  href={{urlForRoute "authenticated.configs.payment"}}
                                >
                                  <i class="ti ti-coins flex-shrink-0 text-xs me-3"></i>
                                  <span class="hide-menu">Pagos</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="simplebar-placeholder" style="width: 269px; height: 2827px;">
          </div>
        </div>
        <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
          <div class="simplebar-scrollbar" style="width: 0px; display: none;">
          </div>
        </div>
        <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
          <div
            class="simplebar-scrollbar"
            style="height: 25px; transform: translate3d(0px, 0px, 0px); display: block;"
          >
          </div>
        </div>
      </div>
    </div>

    <!-- </aside> -->

  </aside>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
