import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { type Column, headlessTable, type Row } from 'ember-headless-table';
import { Metadata } from 'ember-headless-table/plugins/metadata';
import { cell } from 'ember-resources';
import TripPaymentFormComponent from 'vfc-admin/components/forms/tripPaymentForm';
import TripPaymentRefundFormComponent from 'vfc-admin/components/forms/tripPaymentRefundForm';
import TripPaymentScheduledFormComponent from 'vfc-admin/components/forms/tripPaymentScheduledForm';
import { NotificationModal } from 'vfc-admin/components/ui/notification-modal';
import TableComponent, { FORMATS } from 'vfc-admin/components/ui/table-component/table-component';
import config from 'vfc-admin/config/environment';
import { awaitPromise } from 'vfc-admin/helpers/await-promise';
import { TripPayment, type TripPaymentForm, TripPaymentPartialExtended, TripPaymentRefund, type TripPaymentRefundForm, TripPaymentScheduled, type TripPaymentScheduledForm } from 'vfc-admin/models/payments';
import { and, not } from 'vfc-admin/utils/boolean-utils';
import { formatCurrency } from 'vfc-admin/utils/currency-utils';
import type { TOC } from '@ember/component/template-only';
import type RouterService from '@ember/routing/router-service';
import type { FormData } from 'ember-headless-form';
import type { IntlService, t } from 'ember-intl';
import type TripPaymentService from 'vfc-admin/services/trip-payment-service';
import type TripService from 'vfc-admin/services/trip-service';
import type { Trip } from 'vfc-admin/types/trip';
const INTL_SCHEDULED_COLUMNS_PREFIX = 'page.trips.current-course.payment-scheduled.table.columns';
const INTL_REFUND_COLUMNS_PREFIX = 'page.trips.current-course.payment-refund.table.columns';
const INTL_COLUMNS_PREFIX = 'page.trips.current-course.payment.table.columns';
const ScheduledActionsCellComponent: TOC<{
    Element: HTMLElement;
    Args: {
        row: Row;
        column: Column;
        onAction: (event: Event) => void;
    };
}> = template(`
  <div class="action-btn flex gap-3 text-center">
    <a {{on "click" (fn @onAction @row.data "edit")}} class="text-info cursor-pointer">
      <i class="ti ti-edit text-lg"></i>
    </a>
    {{#if (not @row.data.paidAmount)}}
      {{#let (cell false) as |showDialog|}}
        <a {{on "click" showDialog.toggle}} class="text-error cursor-pointer">
          <i class="ti ti-trash text-lg"></i>
        </a>
        {{#if showDialog.current}}
          <NotificationModal
            @title="Confirmar eliminación"
            @message="¿Está seguro de que desea eliminar este pago programado?"
            @onConfirm={{(fn @onAction @row.data "delete")}}
            @onClose={{showDialog.toggle}}
          />
        {{/if}}
      {{/let}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const PaymentsScheduledCellComponent: TOC<{
    Element: HTMLElement;
    Args: {
        row: Row;
        column: Column;
    };
}> = template(`
  <div class="action-btn flex gap-3">
    <ul>
      {{#each @row.data.partialPayments as |partialPayment|}}
        <li>
          {{partialPayment.tripPaymentScheduled.description}}
          -
          {{formatCurrency partialPayment.amount}}
        </li>
      {{/each}}
    </ul>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const ActionsCellComponent: TOC<{
    Element: HTMLElement;
    Args: {
        row: Row;
        column: Column;
        onAction: (event: Event) => void;
    };
}> = template(`
  <div class="action-btn flex gap-3 text-center">
    {{#let (cell false) as |showDialog|}}
      <a {{on "click" showDialog.toggle}} class="text-error cursor-pointer">
        <i class="ti ti-trash text-lg"></i>
      </a>
      {{#if showDialog.current}}
        <NotificationModal
          @title="Confirmar eliminación"
          @message="¿Está seguro de que desea eliminar este pago realizado?"
          @onConfirm={{(fn @onAction @row.data "delete")}}
          @onClose={{showDialog.toggle}}
        />
      {{/if}}
    {{/let}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const RefundActionsCellComponent: TOC<{
    Element: HTMLElement;
    Args: {
        row: Row;
        column: Column;
        onAction: (event: Event) => void;
    };
}> = template(`
  <div class="action-btn flex gap-3 text-center">
    {{#let (cell false) as |showDialog|}}
      <a {{on "click" showDialog.toggle}} class="text-error cursor-pointer">
        <i class="ti ti-trash text-lg"></i>
      </a>
      {{#if showDialog.current}}
        <NotificationModal
          @title="Confirmar eliminación"
          @message="¿Está seguro de que desea eliminar esta devolución?"
          @onConfirm={{(fn @onAction @row.data "delete")}}
          @onClose={{showDialog.toggle}}
        />
      {{/if}}
    {{/let}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const AddOrEditTripPaymentScheduledModal: TOC<{
    Element: HTMLElement;
    Args: {
        scheduledPayment: TripPaymentScheduledForm;
        onSave: (data: FormData<TripPaymentScheduledForm>) => void;
        onClose: () => void;
    };
}> = template(`
  <div
    class="fixed inset-0 z-10 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4"
  >
    <div class="bg-white rounded-lg shadow-xl w-full max-w-md transform transition-all">
      <div class="bg-gray-100 rounded-t-lg px-6 py-4 border-b border-gray-200">
        <h2 class="text-xl font-semibold text-gray-800">
          {{#if @scheduledPayment.id}} Editar {{else}} Añadir{{/if}}
          pago programado
        </h2>
      </div>
      <div class="p-6">
        <TripPaymentScheduledFormComponent
          @onSubmit={{@onSave}}
          @scheduledPayment={{@scheduledPayment}}
          @onClose={{@onClose}}
        />
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const AddOrEditTripPaymentModal: TOC<{
    Element: HTMLElement;
    Args: {
        payment: TripPaymentForm;
        availablePartialPayments: TripPaymentPartialExtended[];
        onSave: (data: FormData<TripPaymentForm>) => void;
        onClose: () => void;
    };
}> = template(`
  <div
    class="fixed inset-0 z-10 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4"
  >
    <div class="bg-white rounded-lg shadow-xl w-full max-w-xl transform transition-all">
      <div class="bg-gray-100 rounded-t-lg px-6 py-4 border-b border-gray-200">
        <h2 class="text-xl font-semibold text-gray-800">
          {{#if @payment.id}} Editar {{else}} Añadir{{/if}}
          realizado pago
        </h2>
      </div>
      <div class="p-6">
        <TripPaymentFormComponent
          @availablePartialPayments={{@availablePartialPayments}}
          @onSubmit={{@onSave}}
          @payment={{@payment}}
          @onClose={{@onClose}}
        />
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const AddOrEditTripPaymentRefundModal: TOC<{
    Element: HTMLElement;
    Args: {
        refundPayment: TripPaymentRefundForm;
        onSave: (data: FormData<TripPaymentRefundForm>) => void;
        onClose: () => void;
    };
}> = template(`
  <div
    class="fixed inset-0 z-10 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4"
  >
    <div class="bg-white rounded-lg shadow-xl w-full max-w-xl transform transition-all">
      <div class="bg-gray-100 rounded-t-lg px-6 py-4 border-b border-gray-200">
        <h2 class="text-xl font-semibold text-gray-800">
          {{#if @refundPayment.id}} Editar {{else}} Añadir{{/if}}
          devolución de pago
        </h2>
      </div>
      <div class="p-6">
        <TripPaymentRefundFormComponent
          @onSubmit={{@onSave}}
          @refundPayment={{@refundPayment}}
          @onClose={{@onClose}}
        />
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface TripPaymentTabSignature {
    Element: HTMLDivElement;
    Args: {
        trip: Promise<Trip>;
        tripId: string;
        paymentScheduledPromise: Promise<TripPaymentScheduled[]>;
        paymentRefundPromise: Promise<TripPaymentRefund[]>;
        paymentPromise: Promise<TripPayment[]>;
    };
}
let TripPaymentTabComponent = class TripPaymentTabComponent extends Component<TripPaymentTabSignature> {
    @service
    tripService: TripService;
    @service
    tripPaymentService: TripPaymentService;
    @service
    intl: IntlService;
    @service
    router: RouterService;
    @tracked
    isOpen = false;
    @tracked
    isOpenPayment = false;
    @tracked
    isOpenPaymentRefund = false;
    @tracked
    tripPaymentScheduled: TripPaymentScheduledForm;
    @tracked
    tripPayment: TripPaymentForm;
    @tracked
    tripPaymentRefund: TripPaymentRefundForm;
    @tracked
    availablePartialPayments: TripPaymentPartialExtended[];
    get tripResource() {
        return awaitPromise<Trip>(this.args.trip);
    }
    get paymentResource() {
        return awaitPromise<TripPayment[]>(this.args.paymentPromise);
    }
    get paymentScheduledResource() {
        return awaitPromise<TripPaymentScheduled[]>(this.args.paymentScheduledPromise);
    }
    get paymentRefundResource() {
        return awaitPromise<TripPaymentRefund[]>(this.args.paymentRefundPromise);
    }
    get paymentsScheduled() {
        return this.paymentScheduledResource.result || [];
    }
    get totalRefund(): number {
        const refunds1 = this.paymentRefundResource.result || [];
        return refunds1.reduce((total1, { amount: amount1 })=>{
            return total1 + amount1;
        }, 0);
    }
    get calculateTotal(): number {
        if (!this.paymentScheduledResource.result) return 0;
        const totalToPaid1 = this.paymentScheduledResource.result.reduce((total1, { amount: amount1 })=>{
            return total1 + amount1;
        }, 0);
        return totalToPaid1;
    }
    get calculateTotalPaid(): number {
        if (!this.paymentResource.result) return 0;
        const totalPaid1 = this.paymentResource.result.reduce((total1, { amount: amount1 })=>{
            total1 += amount1;
            return total1;
        }, 0);
        return totalPaid1 - this.totalRefund;
    }
    get calculateTotalPending() {
        if (!this.paymentResource.result || !this.paymentScheduledResource.result) return '--';
        return this.calculateTotal - this.calculateTotalPaid;
    }
    paymentsScheduledTable = headlessTable<TripPaymentScheduled[]>(this, {
        columns: ()=>[
                {
                    name: this.intl.t(`${INTL_SCHEDULED_COLUMNS_PREFIX}.description`),
                    key: 'description'
                },
                {
                    name: this.intl.t(`${INTL_SCHEDULED_COLUMNS_PREFIX}.date`),
                    key: 'date',
                    pluginOptions: [
                        Metadata.forColumn(()=>({
                                formatDate: config.APP.DATE.DEFAULT_FORMAT
                            }))
                    ]
                },
                {
                    name: this.intl.t(`${INTL_SCHEDULED_COLUMNS_PREFIX}.paidAmount`),
                    key: 'paidAmount',
                    pluginOptions: [
                        Metadata.forColumn(()=>({
                                format: FORMATS.Currency
                            }))
                    ]
                },
                {
                    name: this.intl.t(`${INTL_SCHEDULED_COLUMNS_PREFIX}.pendingAmount`),
                    key: 'pendingAmount',
                    pluginOptions: [
                        Metadata.forColumn(()=>({
                                format: FORMATS.Currency
                            }))
                    ]
                },
                {
                    name: this.intl.t(`${INTL_SCHEDULED_COLUMNS_PREFIX}.amount`),
                    key: 'amount',
                    pluginOptions: [
                        Metadata.forColumn(()=>({
                                format: FORMATS.Currency
                            }))
                    ]
                },
                {
                    name: this.intl.t(`${INTL_SCHEDULED_COLUMNS_PREFIX}.actions`),
                    Cell: ScheduledActionsCellComponent,
                    key: 'actions'
                }
            ],
        data: ()=>{
            return this.paymentScheduledResource.result ?? [];
        }
    });
    paymentsTable = headlessTable<TripPayment[]>(this, {
        columns: ()=>[
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.concept`),
                    key: 'concept'
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.date`),
                    key: 'date',
                    pluginOptions: [
                        Metadata.forColumn(()=>({
                                formatDate: config.APP.DATE.DEFAULT_FORMAT
                            }))
                    ]
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.amount`),
                    key: 'amount',
                    pluginOptions: [
                        Metadata.forColumn(()=>({
                                format: FORMATS.Currency
                            }))
                    ]
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.payment-method`),
                    key: 'method',
                    pluginOptions: [
                        Metadata.forColumn(()=>({
                                i18n: (value1: string)=>`enum.payment-method.${value1}`
                            }))
                    ]
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.payments-scheduled`),
                    Cell: PaymentsScheduledCellComponent,
                    key: 'payments-scheduled'
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.actions`),
                    Cell: ActionsCellComponent,
                    key: 'actions'
                }
            ],
        data: ()=>{
            return this.paymentResource.result ?? [];
        }
    });
    paymentsRefundTable = headlessTable<TripPaymentRefund[]>(this, {
        columns: ()=>[
                {
                    name: this.intl.t(`${INTL_REFUND_COLUMNS_PREFIX}.description`),
                    key: 'description'
                },
                {
                    name: this.intl.t(`${INTL_REFUND_COLUMNS_PREFIX}.date`),
                    key: 'date',
                    pluginOptions: [
                        Metadata.forColumn(()=>({
                                formatDate: config.APP.DATE.DEFAULT_FORMAT
                            }))
                    ]
                },
                {
                    name: this.intl.t(`${INTL_REFUND_COLUMNS_PREFIX}.amount`),
                    key: 'amount',
                    pluginOptions: [
                        Metadata.forColumn(()=>({
                                format: FORMATS.Currency
                            }))
                    ]
                },
                {
                    name: this.intl.t(`${INTL_REFUND_COLUMNS_PREFIX}.observations`),
                    key: 'observations'
                },
                {
                    name: this.intl.t(`${INTL_REFUND_COLUMNS_PREFIX}.actions`),
                    Cell: RefundActionsCellComponent,
                    key: 'actions'
                }
            ],
        data: ()=>{
            return this.paymentRefundResource.result ?? [];
        }
    });
    @action
    onAddPaymentScheduled() {
        this.tripPaymentScheduled = new TripPaymentScheduled();
        this.onOpen();
    }
    @action
    onAddPayment() {
        this.tripPayment = new TripPayment();
        this.availablePartialPayments = this.getAvailablePartialPayments();
        this.onOpenPayment();
    }
    @action
    onAddRefundPayment() {
        this.tripPaymentRefund = new TripPaymentRefund();
        this.onOpenPaymentRefund();
    }
    @action
    getAvailablePartialPayments() {
        return this.paymentsScheduled.filter((paymentScheduled1)=>!paymentScheduled1.paid).map((paymentScheduled1)=>{
            return new TripPaymentPartialExtended(paymentScheduled1.id, paymentScheduled1.date, paymentScheduled1.description, paymentScheduled1.amount - paymentScheduled1.paidAmount, 0);
        });
    }
    @action
    onScheduledAction(data1: TripPaymentScheduled, action1: string) {
        if (action1 === 'edit') {
            this.paymentScheduledResource.result.forEach((scheculedPayment1)=>{
                if (scheculedPayment1.id === data1.id) {
                    this.tripPaymentScheduled = TripPaymentScheduled.parse(scheculedPayment1) as TripPaymentScheduledForm;
                    this.onOpen();
                }
            });
            this.onOpen();
        } else if (action1 === 'delete') {
            this.tripPaymentService.deleteScheduled(this.args.tripId, String(data1.id)).then(()=>{
                this.router.refresh(); //TODO - improve it to avoid refreshing the whole page
            }).catch((error1)=>{
                // TODO Manage errors
                console.log(error1);
            });
        }
    }
    @action
    async onAction(data1: TripPayment, action1: string) {
        if (action1 === 'delete') {
            this.tripPaymentService.delete(this.args.tripId, String(data1.id)).then(async ()=>{
                const trip1 = await this.args.trip;
                if ([
                    'inprogress',
                    'closed'
                ].includes(trip1.state) && this.paymentResource.result.length === 1) {
                    await this.tripService.update(trip1.id, {
                        ...trip1,
                        state: 'pending'
                    });
                    this.router.transitionTo('authenticated.trips.pending.trip.payments', trip1.id);
                } else {
                    this.router.refresh();
                }
            }).catch((error1)=>{
                // TODO Manage errors
                console.log(error1);
            });
        }
    }
    @action
    onRefundAction(data1: TripPaymentRefund, action1: string) {
        if (action1 === 'delete') {
            this.tripPaymentService.deleteRefund(this.args.tripId, String(data1.id)).then(()=>{
                this.router.refresh(); //TODO - improve it to avoid refreshing the whole page
            }).catch((error1)=>{
                // TODO Manage errors
                console.log(error1);
            });
        }
    }
    @action
    onOpen() {
        this.isOpen = true;
    }
    @action
    onClose() {
        this.isOpen = false;
    }
    @action
    onOpenPayment() {
        this.isOpenPayment = true;
    }
    @action
    onOpenPaymentRefund() {
        this.isOpenPaymentRefund = true;
    }
    @action
    onClosePayment() {
        this.isOpenPayment = false;
    }
    @action
    onClosePaymentRefund() {
        this.isOpenPaymentRefund = false;
    }
    @action
    onSaveScheduled(data1: FormData<TripPaymentScheduledForm>) {
        let promise1 = null;
        if (data1.id) {
            promise1 = this.tripPaymentService.updateScheduled(this.args.tripId, String(data1.id), data1);
        } else {
            promise1 = this.tripPaymentService.createScheduled(this.args.tripId, data1);
        }
        // TODO Show loader
        promise1.then(()=>{
            this.router.refresh(); //TODO - improve it to avoid refreshing the whole page
            this.onClose();
        }).catch((error1)=>{
            // TODO Manage errors
            console.log(error1);
        });
    }
    @action
    onSavePayment(data1: FormData<TripPaymentForm>) {
        this.tripPaymentService.create(this.args.tripId, data1).then(()=>{
            this.args.trip.then((trip1)=>{
                if (trip1.state === 'pending' && this.paymentResource.result.length === 0) {
                    this.router.transitionTo('authenticated.trips.current-course.trip.payments', trip1.id);
                } else {
                    this.router.refresh();
                }
                this.onClosePayment();
            });
        }).catch((error1)=>{
            // TODO Manage errors
            console.log(error1);
        });
    }
    @action
    onSavePaymentRefund(data1: FormData<TripPaymentRefundForm>) {
        this.tripPaymentService.createRefund(this.args.tripId, data1).then(()=>{
            this.router.refresh(); //TODO - improve it to avoid refreshing the whole page
            this.onClosePaymentRefund();
        }).catch((error1)=>{
            // TODO Manage errors
            console.log(error1);
        });
    }
    @action
    generateProforma(event1: Event) {
        if (!this.tripResource.result) return;
        const eventTarget1 = event1.target as HTMLInputElement;
        eventTarget1.disabled = true;
        eventTarget1.value = 'Cargando...';
        this.tripService.generateProforma(String(this.tripResource.result.id)).catch((error1: Error)=>{
            console.error(error1);
        }).finally(()=>{
            eventTarget1.disabled = false;
        });
    }
    static{
        template(`
    {{#if this.isOpen}}
      <AddOrEditTripPaymentScheduledModal
        @scheduledPayment={{this.tripPaymentScheduled}}
        @onSave={{this.onSaveScheduled}}
        @onClose={{this.onClose}}
      />
    {{/if}}
    {{#if (and this.isOpenPayment this.paymentScheduledResource.isSuccess)}}
      <AddOrEditTripPaymentModal
        @payment={{this.tripPayment}}
        @availablePartialPayments={{this.availablePartialPayments}}
        @onSave={{this.onSavePayment}}
        @onClose={{this.onClosePayment}}
      />
    {{/if}}
    {{#if (and this.isOpenPaymentRefund this.paymentRefundResource.isSuccess)}}
      <AddOrEditTripPaymentRefundModal
        @refundPayment={{this.tripPaymentRefund}}
        @onSave={{this.onSavePaymentRefund}}
        @onClose={{this.onClosePaymentRefund}}
      />
    {{/if}}
    <div class="flex justify-end">
      <button
        {{on "click" this.generateProforma}}
        type="button"
        class="group/contract btn-md inline-flex items-center gap-x-2 text-sm font-semibold rounded-md border border-transparent bg-lightsecondary text-secondaryemphasis enabled:hover:bg-secondary enabled:hover:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 disabled:opacity-50 disabled:bg-lightsecondary"
      >
        <i class="ti ti-file-text text-base group-disabled/contract:hidden"></i>
        <span
          class="animate-spin inline-block size-4 border-[3px] border-current border-t-transparent text-secondaryemphasis enabled:group-hover/contract:text-white rounded-full group-enabled/contract:hidden"
          role="status"
          aria-label="loading"
        ></span>
        Generar proforma
      </button>
    </div>

    <div class="grid grid-cols-12 md:gap-6 gap-y-3">
      <div class="md:col-span-4 col-span-12">
        <label class="text-dark dark:text-darklink font-semibold block m-2">
          Total pagado
        </label>
        <span class="py-2.5 px-4 block w-full rounded-md border border-slate-300 bg-slate-50">
          {{#if (and this.paymentScheduledResource.isSuccess this.paymentRefundResource.isSuccess)}}
            {{formatCurrency this.calculateTotalPaid}}
          {{else}}
            <span
              class="pt-0.5 animate-spin inline-block size-4 border-[3px] border-current border-t-transparent rounded-full"
              role="status"
              aria-label="loading"
            ></span>
          {{/if}}
        </span>
      </div>
      <div class="md:col-span-4 col-span-12">
        <label class="text-dark dark:text-darklink font-semibold block m-2">
          Total pendiente
        </label>
        <span class="py-2.5 px-4 block w-full rounded-md border border-slate-300 bg-slate-50">
          {{#if (and this.paymentScheduledResource.isSuccess this.paymentRefundResource.isSuccess)}}
            {{formatCurrency this.calculateTotalPending}}
          {{else}}
            <span
              class="pt-0.5 animate-spin inline-block size-4 border-[3px] border-current border-t-transparent rounded-full"
              role="status"
              aria-label="loading"
            ></span>
          {{/if}}
        </span>
      </div>
      <div class="md:col-span-4 col-span-12">
        <label class="text-dark dark:text-darklink font-semibold block m-2">
          Total Viaje
        </label>
        <span class="py-2.5 px-4 block w-full rounded-md border border-slate-300 bg-slate-50">
          {{#if (and this.paymentScheduledResource.isSuccess this.paymentRefundResource.isSuccess)}}
            {{formatCurrency this.calculateTotal}}
          {{else}}
            <span
              class="pt-0.5 animate-spin inline-block size-4 border-[3px] border-current border-t-transparent rounded-full"
              role="status"
              aria-label="loading"
            ></span>
          {{/if}}
        </span>
      </div>
    </div>
    <fieldset class="my-4 border p-4 rounded">
      <legend class="text-dark dark:text-darklink font-semibold text-lg">
        Pagos programados
        <button
          {{on "click" this.onAddPaymentScheduled}}
          class="mx-2 btn-md text-sm font-semibold rounded-md border border-primary text-primary hover:bg-primary hover:text-white"
        >
          <i class="ti ti-plus text-base"></i>
          <span class="max-sm:hidden">Añadir</span>
        </button>
      </legend>

      <TableComponent
        @contentPromise={{this.args.paymentScheduledPromise}}
        @contentTable={{this.paymentsScheduledTable}}
        @onAction={{this.onScheduledAction}}
      />

    </fieldset>
    <fieldset class="mt-8 mb-4 border p-4 rounded">
      <legend class="text-dark dark:text-darklink font-semibold text-lg">
        Pagos realizados
        <button
          disabled={{not this.paymentResource.isSuccess}}
          {{on "click" this.onAddPayment}}
          class="mx-2 btn-md text-sm font-semibold rounded-md border border-primary text-primary enabled:hover:bg-primary enabled:hover:text-white disabled:opacity-50"
        >
          <i class="ti ti-plus text-base"></i>
          <span class="max-sm:hidden">Añadir</span>
        </button>
      </legend>

      <TableComponent
        @contentPromise={{this.args.paymentPromise}}
        @contentTable={{this.paymentsTable}}
        @onAction={{this.onAction}}
      />

    </fieldset>

    <fieldset class="mt-8 mb-4 border p-4 rounded">
      <legend class="text-dark dark:text-darklink font-semibold text-lg">
        Devoluciones
        <button
          disabled={{not this.paymentResource.isSuccess}}
          {{on "click" this.onAddRefundPayment}}
          class="mx-2 btn-md text-sm font-semibold rounded-md border border-primary text-primary enabled:hover:bg-primary enabled:hover:text-white disabled:opacity-50"
        >
          <i class="ti ti-plus text-base"></i>
          <span class="max-sm:hidden">Añadir</span>
        </button>
      </legend>

      <TableComponent
        @contentPromise={{this.args.paymentScheduledPromise}}
        @contentTable={{this.paymentsRefundTable}}
        @onAction={{this.onRefundAction}}
      />

    </fieldset>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export { TripPaymentTabComponent };
