import Route from '@ember/routing/route';
import { service } from '@ember/service';

import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import type { Session } from 'ember-simple-auth/services/session';

export default class ChangePassword extends Route {
  @service declare router: RouterService;
  @service declare session: Session;

  queryParams = {
    token: { refreshModel: false },
  };

  async beforeModel(transition: Transition) {
    const toQueryParams = transition.to.queryParams;

    this.session.prohibitAuthentication('authenticated');

    if (!toQueryParams.token) {
      transition.abort();
      this.router.transitionTo('login');
    }
  }

  async model(params: { token: string }) {
    return { token: params.token };
  }
}
