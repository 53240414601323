import { htmlSafe } from '@ember/template';

export const noSpacesAndLowercase = (str: string) => String(str).replace(/\s/g, '').toLowerCase();
export const noAccentAndLowercase = (str: string) =>
  String(str)
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .toLowerCase();
export const concat = (...args: Array<string | number>) => args.join('');

// In a component or service
export const extractTextFromHtml = (htmlString: string) => {
  const div = document.createElement('div');

  const safeHtml = htmlSafe(htmlString);

  div.innerHTML = safeHtml && safeHtml.toString();

  return div.textContent || div.innerText || '';
};
