import Service from '@ember/service';
import { service } from '@ember/service';
import type { FormData } from 'ember-headless-form';

import config from 'vfc-admin/config/environment';

import type AuthenticatedHttpClient from 'vfc-admin/services/auth/http-client';
import type { Payment } from 'vfc-admin/types/configs/payment';

export default class ConfigService extends Service {
  @service('auth/http-client') declare authenticatedHttpClient: AuthenticatedHttpClient;

  static ENDPOINTS = {
    payment: '/config/payment',
  };

  getPayment(): Promise<Payment> {
    return this.authenticatedHttpClient.GET<Payment>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${ConfigService.ENDPOINTS.payment}`,
    });
  }

  updatePayment(data: FormData<Payment>): Promise<Payment> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    return this.authenticatedHttpClient.PUT<Payment>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${ConfigService.ENDPOINTS.payment}`,
      body: data,
      headers,
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'user-service': AuthenticatedHttpClient;
  }
}
