import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { type FormData } from 'ember-headless-form';
import TripFormComponent from 'vfc-admin/components/forms/tripForm';
import config from 'vfc-admin/config/environment';
import { awaitPromise } from 'vfc-admin/helpers/await-promise';
import { formatDate } from 'vfc-admin/utils/date-utils';
import type Owner from '@ember/owner';
import type RouterService from '@ember/routing/router-service';
import type { Args } from '@glimmer/component/-private/component';
import type { IntlService } from 'ember-intl';
import type { Client } from 'vfc-admin/models/Client';
import type { School } from 'vfc-admin/models/School';
import type TripService from 'vfc-admin/services/trip-service';
import type { Trip, TripForm, ClientRelation } from 'vfc-admin/types/trip';
interface TripDetailsTabSignature {
    Element: HTMLDivElement;
    Args: {
        trip: Promise<Trip>;
        clientPromise: Promise<Client>;
        schoolPromise: Promise<School>;
        clientRelationsPromise: Promise<ClientRelation>;
        parentRedirectUrl: string;
    };
}
let TripDetailsTabComponent = class TripDetailsTabComponent extends Component<TripDetailsTabSignature> {
    @service
    tripService: TripService;
    @service
    router: RouterService;
    @service
    intl: IntlService;
    get tripResource() {
        return awaitPromise<Trip>(this.args.trip);
    }
    get formData(): TripForm {
        const trip1 = this.tripResource.result;
        if (!trip1) return {} as TripForm;
        return {
            id: trip1.id,
            state: trip1.state,
            totalStudents: trip1.totalStudents,
            totalRequestedStudents: trip1.requestedStudents,
            totalTeachers: trip1.totalTeachers,
            totalRequestedTeachers: trip1.requestedTeachers,
            image: trip1.image,
            reference: trip1.reference,
            clientId: String(trip1?.client?.id),
            clientName: trip1?.client?.name,
            clientEmail: trip1?.client?.email,
            schoolId: trip1?.school?.id,
            schoolName: trip1?.school?.name,
            schoolProvince: trip1?.school?.province,
            clientRelationId: String(trip1?.clientRelation?.id),
            clientRelationName: trip1?.clientRelation?.name,
            destinationId: trip1?.destination?.id,
            destinationName: trip1?.destination?.name,
            destinationCityId: trip1?.destination?.city?.id,
            destinationCityName: trip1?.destination?.city?.name,
            acceptConditions: isPresent(trip1.acceptedDate) ? 'SI' : 'NO',
            acceptedDate: trip1.acceptedDate ? formatDate(trip1.acceptedDate, config.APP.DATE.DATE_TYPE_FORMAT) : '',
            modifyClientData: trip1.modifyClientData,
            requestedCheckIn: formatDate(trip1.requestedCheckIn, config.APP.DATE.DATE_TYPE_FORMAT),
            checkIn: formatDate(trip1.checkIn, config.APP.DATE.DATE_TYPE_FORMAT),
            requestedCheckOut: formatDate(trip1.requestedCheckOut, config.APP.DATE.DATE_TYPE_FORMAT),
            checkOut: formatDate(trip1.checkOut, config.APP.DATE.DATE_TYPE_FORMAT),
            groupAge: trip1.groupAge,
            totalPrice: trip1.totalPrice,
            anythingElse: trip1.anythingElse,
            observations: trip1.observations ?? ''
        };
    }
    @action
    async onSubmit(data1: FormData<TripForm>) {
        try {
            await this.tripService.update(String(data1.id), data1 as TripForm);
            if (this.args.parentRedirectUrl) {
                this.router.transitionTo(this.args.parentRedirectUrl);
            }
        } catch (error1) {
            console.error(error1);
            throw error1;
        }
    }
    constructor(owner1: Owner, args1: Args<TripDetailsTabSignature>){
        super(owner1, args1);
    }
    static{
        template(`
    {{#if this.tripResource.isPending}}
      Loading
    {{else if this.tripResource.isSuccess}}
      <TripFormComponent
        @tripForm={{this.formData}}
        @clientPromise={{@clientPromise}}
        @schoolPromise={{@schoolPromise}}
        @clientRelationsPromise={{@clientRelationsPromise}}
        @onSubmit={{this.onSubmit}}
        @onResetForm={{this.onResetForm}}
      />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export { TripDetailsTabComponent };
