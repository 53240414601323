import Route from '@ember/routing/route';
import { service } from '@ember/service';

import type TagService from 'vfc-admin/services/tag-service';

export default class FaqCreate extends Route {
  @service declare tagService: TagService;

  async model() {
    const tagPromise = this.tagService.findAll();

    return { tagPromise };
  }
}
