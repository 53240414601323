export interface SchoolInterface {
  id: number;
  name?: string;
  cif?: string;
  phone?: string;
  email?: string;
  url?: string;
  address?: string;
  postalCode?: string;
  province: string;
  population?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export class School implements SchoolInterface {
  id!: number;
  name?: string;
  cif?: string;
  phone?: string;
  email?: string;
  url?: string;
  address?: string;
  postalCode?: string;
  province!: string;
  population?: string;
  createdAt?: Date;
  updatedAt?: Date;

  static parse(data: SchoolInterface) {
    const school = new School();
    school.id = data.id;
    school.name = data.name || undefined;
    school.cif = data.cif || undefined;
    school.phone = data.phone || undefined;
    school.email = data.email || undefined;
    school.url = data.url || undefined;
    school.address = data.address || undefined;
    school.postalCode = data.postalCode || undefined;
    school.province = data.province;
    school.population = data.population || undefined;
    school.createdAt = data.createdAt || undefined;
    school.updatedAt = data.updatedAt || undefined;

    return school;
  }
}

export type SchoolType = School;
export type SchoolForm = Omit<School, 'id' | 'createdAt' | 'updatedAt'>;
