import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type SchoolService from 'vfc-admin/services/school-service';

export default class SchoolEdit extends Route {
  @service declare schoolService: SchoolService;

  async model(params: { id: string }) {
    // TODO Manage 404 error
    const schoolPromise = this.schoolService.get(params.id);

    return { schoolPromise };
  }
}
