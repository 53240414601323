import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { get } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
const KIND = {
    'solid-primary': 'btn-md text-sm font-medium rounded-md border border-transparent bg-primary hover:bg-primaryemphasis text-white ',
    'solid-secondary': 'btn-md text-sm font-medium rounded-md border border-transparent bg-secondary hover:bg-secondaryemphasis  text-white  ',
    'solid-success': 'btn-md text-sm font-medium rounded-md border border-transparent bg-success hover:bg-successemphasis text-white',
    'solid-warning': 'btn-md text-sm font-medium rounded-md border border-transparent bg-warning hover:bg-warningemphasis text-white',
    'solid-error': 'btn-md text-sm font-medium rounded-md border border-transparent bg-error hover:bg-erroremphasis text-white ',
    'solid-dark': 'btn-md text-sm font-medium rounded-md border border-transparent bg-gray-800 text-white hover:bg-gray-900',
    'solid-gray': 'btn-md text-sm font-medium rounded-md border border-transparent bg-gray-500 text-white hover:bg-gray-600',
    'soft-primary': 'btn-md text-sm font-semibold rounded-md border border-transparent bg-lightprimary text-primaryemphasis hover:bg-primary',
    'soft-secondary': 'btn-md text-sm font-semibold rounded-md border border-transparent bg-lightsecondary text-secondaryemphasis hover:bg-secondary',
    'soft-success': 'btn-md text-sm font-semibold rounded-md border border-transparent bg-lightsuccess hover:text-white text-successemphasis hover:bg-success',
    'soft-warning': 'btn-md text-sm font-semibold rounded-md border border-transparent bg-lightwarning text-warningemphasis hover:text-white hover:bg-warning',
    'soft-error': 'btn-md text-sm font-semibold rounded-md border border-transparent bg-lighterror text-erroremphasis hover:text-white hover:bg-error',
    'soft-dark': 'btn-md text-sm font-semibold rounded-md border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200',
    'soft-gray': 'btn-md text-sm font-semibold rounded-md border border-transparent bg-gray-100 text-gray-500 hover:bg-gray-200'
};
type KIND_KEYS = keyof typeof KIND;
export default class ButtonText extends Component<{
    Element: HTMLButtonElement;
    Args: {
        kind: KIND_KEYS;
        size: 'large' | 'medium' | 'small';
        onClick?: (event: Event) => void;
        isLoading?: boolean;
    };
    Blocks: {
        default: [];
    };
}> {
    static KIND = KIND;
    get kind() {
        return this.args.kind ?? 'solid-primary';
    }
    @action
    onClick(event1: Event) {
        if (this.args.onClick) this.args.onClick(event1);
    }
    static{
        template(`
    {{! @glint-nocheck: not typesafe yet }}
    <button
      type="button"
      class="rounded-full
        {{get ButtonText.KIND this.kind}}
        {{if this.args.isLoading 'inline-flex items-center gap-x-2 font-semibold'}}
        disabled:opacity-50 disabled:pointer-events-none"
      {{on "click" this.onClick}}
      ...attributes
    >
      {{#if this.args.isLoading}}
        <span
          class="animate-spin inline-block size-4 border-[3px] border-current border-t-transparent text-white rounded-full"
          role="status"
          aria-label="loading"
        ></span>
      {{/if}}
      {{yield}}
    </button>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
