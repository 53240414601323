import Service from '@ember/service';

import { TrackedArray } from 'tracked-built-ins';

type NotificationType = 'error' | 'success';

class Notification {
  id = self.crypto.randomUUID();
  type: NotificationType;
  message: string;
  timeoutId?: number;

  constructor({ type, message }: { type: NotificationType; message: string }) {
    this.type = type;
    this.message = message;
  }
}
export default class NotificationService extends Service {
  notifications: Array<Notification> = new TrackedArray();

  notify = (notification: Notification) => {
    this.notifications.push(notification);
  };

  delete = (notification: Notification) => {
    const index = this.notifications.findIndex((n) => n.id === notification.id);

    this.notifications.splice(index, 1);
  };
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'notification-service': NotificationService;
  }
}

export { Notification };
