import Route from '@ember/routing/route';
import { service } from '@ember/service';

import type FaqsService from 'vfc-admin/services/faqs-service';
import type TagService from 'vfc-admin/services/tag-service';

export default class FaqEdit extends Route {
  @service declare faqsService: FaqsService;
  @service declare tagService: TagService;

  async model(params: { id: string }) {
    // TODO Manage 404 error
    const faqPromise = this.faqsService.get(params.id);
    const tagPromise = this.tagService.findAll();

    return { faqPromise, tagPromise };
  }
}
