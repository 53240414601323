import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { type Column, type Table } from 'ember-headless-table';
import { forColumn } from 'ember-headless-table/plugins/metadata';
import { t, type IntlService } from 'ember-intl';
import TableSkeletonLoader from 'vfc-admin/components/ui/table-skeleton-loader';
import { awaitPromise } from 'vfc-admin/helpers/await-promise';
import { formatDate } from 'vfc-admin/utils/date-utils';
import { formatCurrency } from 'vfc-admin/utils/currency-utils';
// TODO Check if it's needed refactor date format
export const FORMATS = {
    Currency: 'currency'
};
const isCurrency = (column1: Column<any>)=>{
    return forColumn(column1, 'format') === 'currency';
};
const isFormatDate = (column1: Column<any>)=>{
    return forColumn(column1, 'formatDate');
};
const isI18n = (column1: Column<any>)=>{
    return forColumn(column1, 'i18n');
};
export interface TableComponentSignature {
    Element: HTMLElement;
    Args: {
        contentPromise: Promise<any>;
        contentTable: Table<any>;
    };
}
export default class TableComponent extends Component<TableComponentSignature> {
    @service
    intl: IntlService;
    get contentResource() {
        return awaitPromise<any>(this.args.contentPromise);
    }
    i18n = (value1: string)=>{
        return this.intl.t(value1);
    };
    static{
        template(`
    <div class="-m-1.5 overflow-x-auto">
      <div class="p-1.5 min-w-full inline-block align-middle">
        <div class="overflow-hidden">
          <table class="min-w-full divide-y divide-border dark:divide-darkborder">
            <thead>
              <tr>
                {{#each this.args.contentTable.columns as |column|}}
                  <th
                    scope="col"
                    class="text-left rtl:text-right p-4 ps-0 font-semibold text-dark dark:text-white"
                  >
                    {{column.name}}
                  </th>
                {{/each}}
              </tr>
            </thead>
            <tbody class="divide-y divide-border dark:divide-darkborder">
              {{#if this.contentResource.isPending}}
                <TableSkeletonLoader
                  @rows={{8}}
                  @columns={{this.args.contentTable.columns.length}}
                  @columnClass={{"py-3"}}
                />
              {{else if this.contentResource.isError}}
                <tr class="text-center text-red-500">
                  <td colspan="{{this.args.contentTable.columns.length}}">
                    <div>{{t "components.ui.table.unexpected-error"}}</div>
                    <div class="text-sm">{{this.contentResource.error.message}}</div>
                  </td>
                </tr>
              {{else}}
                {{#if this.args.contentTable.rows.length}}
                  {{#each this.args.contentTable.rows as |row|}}
                    <tr>
                      {{#each this.args.contentTable.columns as |column|}}
                        <td class="text-dark dark:text-darklink text-base font-light py-3">
                          {{#if column.Cell}}
                            <column.Cell
                              @row={{row}}
                              @column={{column}}
                              @onAction={{this.args.onAction}}
                            />
                          {{else if (isCurrency column)}}
                            <p>{{formatCurrency (column.getValueForRow row)}}</p>
                          {{else}}
                            {{#let
                              (isFormatDate column) (isI18n column)
                              as |formatDateValue i18nFn|
                            }}
                              {{#if formatDateValue}}
                                {{formatDate (column.getValueForRow row) formatDateValue}}
                              {{else if i18nFn}}
                                {{this.i18n (i18nFn (column.getValueForRow row))}}
                              {{else}}
                                {{column.getValueForRow row}}
                              {{/if}}
                            {{/let}}
                          {{/if}}
                        </td>
                      {{/each}}
                    </tr>
                  {{/each}}
                {{else}}
                  <tr class="text-center">
                    <td class="pt-3" colspan="{{this.args.contentTable.columns.length}}">
                      {{t "components.ui.table.empty"}}
                    </td>
                  </tr>
                {{/if}}
              {{/if}}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
