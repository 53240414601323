import { template } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { get } from '@ember/helper';
const badgeTypes = {
    warning: 'bg-yellow-50 text-yellow-800 ring-yellow-600/20'
};
export const Badge: TOC<{
    Element: HTMLSpanElement;
    Args: {
        type?: 'warning';
    };
    Blocks: {
        default: [];
    };
}> = template(`
  <span
    ...attributes
    class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset
      {{if @type (get badgeTypes @type)}}"
  >
    {{yield}}
  </span>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default Badge;
