import { template } from "@ember/template-compiler";
import 'quill/dist/quill.snow.css';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { registerDestructor } from '@ember/destroyable';
import { htmlSafe } from '@ember/template';
import DOMPurify from 'dompurify';
import { modifier } from 'ember-modifier';
import Quill from 'quill';
interface HtmlEditorArgs {
    content: string;
    onUpdate: (content: string) => void;
}
let HtmlEditor = class HtmlEditor extends Component<HtmlEditorArgs> {
    private quill: Quill | null = null;
    //we need to do a copy so our args.content is not tracked
    content = this.args.content;
    setupEditor = modifier((element1: HTMLElement)=>{
        const toolbarOptions1 = [
            [
                'bold',
                'italic',
                'underline',
                'strike'
            ],
            [
                'blockquote',
                'code-block'
            ],
            [
                'link',
                'image' /*'video', 'formula'*/ 
            ],
            [
                {
                    header: 1
                },
                {
                    header: 2
                }
            ],
            [
                {
                    list: 'ordered'
                },
                {
                    list: 'bullet'
                },
                {
                    list: 'check'
                }
            ],
            //[{ script: 'sub' }, { script: 'super' }], // superscript/subscript
            [
                {
                    indent: '-1'
                },
                {
                    indent: '+1'
                }
            ],
            //[{ direction: 'rtl' }], // text direction
            [
                {
                    size: [
                        'small',
                        false,
                        'large',
                        'huge'
                    ]
                }
            ],
            [
                {
                    header: [
                        1,
                        2,
                        3,
                        4,
                        5,
                        6,
                        false
                    ]
                }
            ],
            [
                {
                    color: []
                },
                {
                    background: []
                }
            ],
            [
                {
                    font: []
                }
            ],
            [
                {
                    align: []
                }
            ],
            [
                'clean'
            ]
        ];
        this.quill = new Quill(element1, {
            theme: 'snow',
            modules: {
                toolbar: toolbarOptions1
            }
        });
        this.quill.on('text-change', ()=>{
            if (this.quill) {
                this.args.onUpdate(this.quill.root.innerHTML);
            }
        });
        registerDestructor(this, ()=>{
            if (this.quill) {
                this.quill.off('text-change');
                this.quill = null;
            }
        });
    });
    get safeContent() {
        const sanitized1 = DOMPurify.sanitize(this.content);
        return htmlSafe(sanitized1);
    }
    static{
        template(`
    <div {{this.setupEditor}} ...attributes>
      {{this.safeContent}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export { HtmlEditor };
