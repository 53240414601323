import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { type FormData } from 'ember-headless-form';
import RouteTemplate from 'ember-route-template';
import ClientFormComponent from 'vfc-admin/components/forms/clientForm';
import { ComboboxItem } from 'vfc-admin/components/inputs/combobox/combobox';
import { awaitPromise } from 'vfc-admin/helpers/await-promise';
import { Client, type ClientForm } from 'vfc-admin/models/Client';
import type { School } from 'vfc-admin/models/School';
import type ClientService from 'vfc-admin/services/client-service';
import type { Paginated } from 'vfc-admin/types/paginated';
import type { ClientRelation } from 'vfc-admin/types/trip';
interface ClientCreateSignature {
    Element: HTMLDivElement;
    Args: {
    };
}
let ClientCreateComponent = class ClientCreateComponent extends Component<ClientCreateSignature> {
    @service
    clientService: ClientService;
    @service
    router: RouterService;
    clientForm: ClientForm = new Client() as ClientForm;
    schools: Paginated<School>;
    clientRelations: ClientRelation[];
    @action
    async onSubmit(data1: FormData<ClientForm>) {
        this.clientService.create(data1).then((client1)=>{
            this.router.transitionTo('authenticated.clients');
        }).catch((error1)=>{
            console.log(error1);
        });
    }
    static{
        template(`
    <div class="card">
      <div class="col-span-12">
        <div class="border-b border-light-dark py-4 px-6 flex items-center justify-between">
          <h5 class="card-title mb-0">Nuevo cliente</h5>
        </div>
      </div>

      <div class="card-body">
        <ClientFormComponent @onSubmit={{this.onSubmit}} @client={{this.clientForm}} />
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate<{
    Args: {
        model: {
        };
    };
}>(template(`<ClientCreateComponent />`, {
    eval () {
        return eval(arguments[0]);
    }
}));
