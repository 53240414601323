import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { type FormData, HeadlessForm } from 'ember-headless-form';
import InputTags from 'vfc-admin/components/forms/input-tags';
import { HtmlEditor } from 'vfc-admin/components/ui/html-editor';
import { urlForRoute } from 'vfc-admin/helpers/url-for-route';
import { validateInputNatively } from 'vfc-admin/utils/form-utils';
import { extractTextFromHtml } from 'vfc-admin/utils/string-utils';
import type RouterService from '@ember/routing/router-service';
import type { IntlService } from 'ember-intl';
import type { Faq } from 'vfc-admin/types/faq';
import type { Tag } from 'vfc-admin/types/tag';
export interface FaqFormComponentSignature {
    Element: HTMLElement;
    Args: {
        faq: Faq;
        tagPromise: Promise<Array<Tag>>;
        onSubmit?: (data: FormData<Faq>) => void;
    };
}
export default class FaqFormComponent extends Component<FaqFormComponentSignature> {
    @service
    router: RouterService;
    @service
    intl: IntlService;
    validateInputNatively = (event1: Event)=>validateInputNatively.call(this, event1);
    @action
    validateHtmlEditor(value1: string) {
        const text1 = extractTextFromHtml(value1);
        if (!text1) {
            return [
                {
                    type: 'valueMissing',
                    message: this.intl.t('common.form.validation.valueMissing')
                }
            ];
        }
    }
    static{
        template(`
    <HeadlessForm @data={{@faq}} @onSubmit={{@onSubmit}} @revalidateOn="input" as |form|>
      <div class="grid grid-cols-12 gap-x-6 gap-y-4 mt-5">
        <div class="col-span-12">
          <div class="flex flex-col gap-4">
            <div>
              <form.Field @name="title" as |field|>
                <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                  Título
                  <span class="text-red-500">*</span>
                </field.Label>
                <field.Input
                  required
                  maxLength="255"
                  {{on "invalid" this.validateInputNatively}}
                  class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                />
                <field.Errors class="py-1 text-xs text-red-300" />
              </form.Field>
            </div>
            <div>
              <form.Field @name="description" @validate={{this.validateHtmlEditor}} as |field|>
                <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                  Descripción
                  <span class="text-red-500">*</span>
                </field.Label>

                <HtmlEditor
                  @content={{field.value}}
                  @onUpdate={{field.setValue}}
                  class="min-h-20 ql-container ql-snow {{if field.isInvalid '!border-error'}}"
                />

                <field.Errors class="py-1 text-xs text-red-300" />
              </form.Field>
            </div>
            <div>
              <form.Field @name="tags" as |field|>
                <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                  Etiquetas
                </field.Label>
                <InputTags
                  @value={{field.value}}
                  @onChange={{field.setValue}}
                  @autocomplete={{true}}
                  @availableTagsPromise={{this.args.tagPromise}}
                  @maxVisible={{3}}
                  class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                />

                <field.Errors class="py-1 text-xs text-red-300" />
              </form.Field>
            </div>
          </div>
        </div>
        <div class="col-span-12">
          <div class="flex gap-3 justify-end">
            <button class="btn btn-md" type="submit">Guardar</button>
            <a class="btn btn-light-error" href="{{urlForRoute 'authenticated.faqs'}}">
              Cancelar
            </a>
          </div>
        </div>
      </div>
    </HeadlessForm>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
