import { template } from "@ember/template-compiler";
import './full-calendar.css';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { registerDestructor } from '@ember/destroyable';
import { action } from '@ember/object';
import { Calendar } from '@fullcalendar/core';
import { type EventInput } from '@fullcalendar/core';
import esLocale from '@fullcalendar/core/locales/es';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { modifier } from 'ember-modifier';
import type { DatesSetArg } from '@fullcalendar/core/dist/types/types/date-input';
interface FullCalendarArgs {
    events?: Array<EventInput>;
    onDatesSet?: (calendarInstance: Calendar, dates: DatesSetArg) => void;
    onEventClick?: (info: {
        event: EventInput;
        jsEvent: Event;
    }) => void;
}
export default class FullCalendar extends Component<FullCalendarArgs> {
    @tracked
    private calendarInstance: Calendar | null = null;
    setupCalendar = modifier((element1: HTMLElement)=>{
        this.calendarInstance = new Calendar(element1, {
            plugins: [
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin
            ],
            initialView: 'dayGridMonth',
            headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                //right: 'dayGridMonth,timeGridWeek,timeGridDay',
                right: ''
            },
            events: this.args.events || [],
            editable: true,
            selectable: true,
            selectMirror: true,
            dayMaxEvents: true,
            eventContent: this.renderEventContent,
            locale: 'es',
            locales: [
                esLocale
            ],
            eventClick: (info1: {
                event: EventInput;
                jsEvent: Event;
            })=>{
                if (this.args.onEventClick) {
                    this.args.onEventClick(info1);
                }
            },
            datesSet: (dates1: DatesSetArg)=>{
                this.args.onDatesSet(this.calendarInstance, dates1);
            }
        });
        this.calendarInstance.render();
        registerDestructor(this, ()=>{
            if (this.calendarInstance) {
                this.calendarInstance.destroy();
                this.calendarInstance = null;
            }
        });
    });
    @action
    renderEventContent(eventInfo1: {
        event: EventInput;
    }): {
        html: string;
    } {
        const event1 = eventInfo1.event;
        const icon1 = event1?.extendedProps?.['icon'] ?? '';
        return {
            html: `<div class="fc-event-main-frame" style="background-color: ${event1.backgroundColor}; border-color: ${event1.borderColor}; color: ${event1.textColor};">
               <div class="fc-event-title-container px-2 flex items-center gap-1">
                  ${icon1}
                  <div class="fc-event-title fc-sticky">${event1.title}</div>
               </div>
             </div>`
        };
    }
    static{
        template(`
    <div {{this.setupCalendar}}>
      <div class="full-calendar-container"></div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
