export const PaymentMethod: Record<string, string> = {
  BankTransfer: 'bank-transfer',
  Cash: 'cash',
  DirectDebit: 'direct-debit',
  PayPal: 'paypal',
  TPV: 'tpv',
  Discount: 'discount',
} as const;

export type PaymentMethodType = typeof PaymentMethod;
