import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type ToastService from 'vfc-admin/services/toast-service';
import type { Notification } from 'vfc-admin/services/toast-service';
interface ToastNotificationSignature {
    Element: HTMLElement;
    Args: {
        notification: Notification;
    };
}
export default class ToastNotification extends Component<ToastNotificationSignature> {
    @service
    toastService: ToastService;
    @tracked
    isOpen = false;
    get isSuccess() {
        return this.args.notification.type === 'success';
    }
    get isInfo() {
        return this.args.notification.type === 'info';
    }
    get isWarning() {
        return this.args.notification.type === 'warning';
    }
    get isError() {
        return this.args.notification.type === 'error';
    }
    @action
    onClose() {
        this.toastService.remove(this.args.notification.id);
    }
    static{
        template(`
    {{#if this.isSuccess}}
      <div
        class="bg-lightsuccess border border-lightsuccess text-sm text-successemphasis rounded-md p-4 dark:bg-darksuccess dark:border-darksuccess dark:text-success"
        role="alert"
      >
        <i class="ti ti-circle-check"></i>
        {{@notification.message}}
      </div>
    {{else if this.isWarning}}
      <div
        class="bg-lightwarning border border-lightwarning text-sm text-warningemphasis rounded-md p-4 dark:bg-darkwarning dark:border-darkwarning dark:text-warning"
        role="alert"
      >
        <i class="ti ti-alert-triangle"></i>
        {{@notification.message}}
      </div>
    {{else if this.isInfo}}
      <div
        class="bg-lightprimary border border-lightprimary text-sm text-primaryemphasis rounded-md p-4 dark:bg-darkprimary dark:border-darkprimary dark:text-primary"
        role="alert"
      >
        <i class="ti ti-info-circle"></i>
        {{@notification.message}}
      </div>
    {{else if this.isError}}
      <div
        class="bg-lighterror border border-lighterror text-sm text-erroremphasis rounded-md p-4 dark:bg-darkerror dark:border-darkerror dark:text-error"
        role="alert"
      >
        <i class="ti ti-exclamation-circle"></i>
        {{@notification.message}}
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
