import './assets/css/theme.css';
import './assets/tailwind/components/calendar.css';
import './assets/tailwind/components/simplebar.css';
import './assets/tailwind/utilities/customizer.css';
import './assets/tailwind/utilities/dark.css';
import './assets/tailwind/utilities/default_colors.css';
import './assets/tailwind/utilities/header.css';
import './assets/tailwind/utilities/horizontal.css';
import './assets/tailwind/utilities/landingpage.css';
import './assets/tailwind/utilities/layout.css';
import './assets/tailwind/utilities/rtl-layout.css';
import './assets/tailwind/utilities/sidebar.css';
import './assets/fonts/icons/tabler-icons/tabler-icons.css';
import './assets/libs/simplebar/simplebar.min.js';
import 'dayjs/locale/es';

import Application from '@ember/application';

import dayjs from 'dayjs';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import loadInitializers from 'ember-load-initializers';
import Resolver from 'ember-resolver';
import config from 'vfc-admin/config/environment';

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

dayjs.locale('es');
dayjs.extend(relativeTimePlugin);

loadInitializers(App, config.modulePrefix);
