import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { type FormData } from 'ember-headless-form';
import RouteTemplate from 'ember-route-template';
import FaqFormComponent from 'vfc-admin/components/forms/faqForm';
import type RouterService from '@ember/routing/router-service';
import type FaqsService from 'vfc-admin/services/faqs-service';
import type { Faq } from 'vfc-admin/types/faq';
import type { Tag } from 'vfc-admin/types/tag';
interface FaqNewSignature {
    Element: HTMLDivElement;
    Args: {
        tagPromise: Promise<Tag[]>;
    };
}
let FaqNewComponent = class FaqNewComponent extends Component<FaqNewSignature> {
    @service
    faqsService: FaqsService;
    @service
    router: RouterService;
    @action
    async onSubmit(data1: FormData<Faq>) {
        try {
            await this.faqsService.create(data1);
            this.router.transitionTo('authenticated.faqs');
        } catch (error1) {
            console.log(error1);
        }
    }
    get faq() {
        return {} as Faq;
    }
    static{
        template(`
    <div class="card">
      <div class="col-span-12">
        <div class="border-b border-light-dark py-4 px-6 flex items-center justify-between">
          <h5 class="card-title mb-0">Nueva pregunta</h5>
        </div>
      </div>
      <div class="card-body">
        <FaqFormComponent
          @faq={{this.faq}}
          @tagPromise={{this.args.tagPromise}}
          @onSubmit={{this.onSubmit}}
        />
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate<{
    Args: {
    };
}>(template(`<FaqNewComponent @tagPromise={{@model.tagPromise}} />`, {
    eval () {
        return eval(arguments[0]);
    }
}));
