import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { dataFromEvent } from 'ember-primitives/components/form';
import RouteTemplate from 'ember-route-template';
import type RouterService from '@ember/routing/router-service';
import type UserService from 'vfc-client/services/user-service';
interface RequestPasswordSignature {
    Element: HTMLDivElement;
}
let RequestPasswordComponent = class RequestPasswordComponent extends Component<RequestPasswordSignature> {
    @service
    router: RouterService;
    @service
    userService: UserService;
    @tracked
    passwordReset = false;
    @action
    async requestPassword(event1: Event) {
        event1.preventDefault();
        const formData1 = dataFromEvent<{
            email: string;
        }>(event1);
        this.userService.resetPassword(formData1).finally(()=>{
            this.passwordReset = true;
        });
    }
    static{
        template(`
    <main class="h-screen w-full bg-lightprimary">
      <div class="h-full w-full flex justify-center items-center">
        <div class="flex justify-center w-full">
          <div class="xl:w-2/6 w-full">
            <div class="max-w-[460px] px-3 mx-auto">
              <div class="card">
                <div class="card-body">
                  <div class="mx-auto text-center mb-12">
                    <div class="flex justify-center">
                      <div class="brand-logo flex items-center">
                        <img src="/images/logos/logo.svg" alt="Logo" />
                      </div>
                    </div>
                  </div>
                  {{#if this.passwordReset}}
                    <div class="text-center font-semibold">
                      Se ha enviado un correo electrónico con las instrucciones para restablecer la
                      contraseña
                    </div>
                  {{else}}
                    <form {{this.setElementForm}} {{on "submit" this.requestPassword}}>
                      <div class="flex flex-col gap-4 mt-4">
                        <div class="mb-4 text-lg self-center text-gray-700">
                          ¿Olvidaste tu contraseña?
                        </div>
                        <div>
                          <label class="text-dark dark:text-darklink font-semibold mb-2 block">
                            Email
                          </label>
                          <input required type="email" class="form-control py-2" name="email" />
                        </div>
                        <button class="btn btn-md py-3">Reestablecer contraseña</button>
                      </div>
                    </form>
                  {{/if}}

                  <div class="flex items-center flex-col mt-4">
                    <a
                      href="/login"
                      class="text-sm underline text-accent1-400 hover:text-primary cursor-pointer"
                    >
                      Volver a iniciar sesión
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(template(`<RequestPasswordComponent />`, {
    eval () {
        return eval(arguments[0]);
    }
}));
