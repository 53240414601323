import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { School } from 'vfc-admin/models/school';
import type SchoolService from 'vfc-admin/services/school-service';

export default class SchoolEdit extends Route {
  @service declare schoolService: SchoolService;

  async model() {
    const school = new School();

    return { school };
  }
}
