import Service from '@ember/service';
import { service } from '@ember/service';

import config from 'vfc-admin/config/environment';

import type AuthenticatedHttpClient from 'vfc-admin/services/auth/http-client';
import type { Faq, Tag } from 'vfc-admin/types/faq';
import type { Pagination } from 'vfc-admin/types/pagination';

export type FaqsResponse = {
  data: Array<Faq>;
  meta: {
    total: number;
    page: number;
    pageSize: number;
  };
};

export default class FaqsService extends Service {
  @service('auth/http-client') declare authenticatedHttpClient: AuthenticatedHttpClient;

  static ENDPOINTS = {
    findAll: '/page/faqs',
    get: '/page/faqs/:id',
    create: '/page/faqs',
    update: '/page/faqs/:id',
    delete: '/page/faqs/:id',
  };

  findAll(
    query: string,
    pagination: Pagination = { page: 1, pageSize: 10 }
  ): Promise<{ data: Array<Faq> }> {
    const filters = new URLSearchParams({
      page: String(pagination.page),
      pageSize: String(pagination.pageSize),
    });

    if (query) {
      filters.append('query', query);
    }

    return this.authenticatedHttpClient.GET<{ data: Array<Faq> }>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${FaqsService.ENDPOINTS.findAll}?${filters.toString()}`,
    });
  }

  get(id: string): Promise<Faq> {
    return this.authenticatedHttpClient.GET<Faq>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${FaqsService.ENDPOINTS.get.replace(':id', id)}`,
    });
  }

  create(data: FormData<Faq>): Promise<Faq> {
    const headers = new Headers();

    headers.append('Content-Type', 'application/json');

    data.tags = (data.tags || []).map((tag: Tag) => tag.name);

    return this.authenticatedHttpClient.POST<Faq>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${FaqsService.ENDPOINTS.create}`,
      body: data,
      headers,
    });
  }

  update(id: string, data: FormData<Faq>): Promise<Faq> {
    const headers = new Headers();

    headers.append('Content-Type', 'application/json');

    data.tags = (data.tags || []).map((tag: Tag) => tag.name);

    return this.authenticatedHttpClient.PUT<Faq>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${FaqsService.ENDPOINTS.update.replace(':id', id)}`,
      body: data,
      headers,
    });
  }

  delete(id: string): Promise<void> {
    return this.authenticatedHttpClient.DELETE({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${FaqsService.ENDPOINTS.delete.replace(':id', id)}`,
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'user-service': AuthenticatedHttpClient;
  }
}
