import { modifier } from 'ember-modifier';

export default modifier((element, [callback]: [callback: Function]) => {
  function handleScroll(event: Event) {
    callback(event);
  }

  document.addEventListener('scroll', handleScroll);

  return () => {
    document.removeEventListener('scroll', handleScroll);
  };
});
