import { tracked } from '@glimmer/tracking';

const PromiseCache = new WeakMap<Promise<T>, PromiseState>();

class PromiseState<T = unknown> {
  @tracked result: T | null = null;
  @tracked error: Error | null = null;
  @tracked isPending = true;
  @tracked isSuccess = false;
  @tracked isError = false;

  constructor(promise: Promise<T>) {
    promise
      .then((result) => {
        this.result = result;
        this.isPending = false;
        this.isSuccess = true;
      })
      .catch((error) => {
        this.error = error;
        this.isPending = false;
        this.isError = true;
      });
  }
}

export function awaitPromise<T = unknown>(promise: Promise<T>) {
  let state = PromiseCache.get(promise) as PromiseState<T> | undefined;

  if (!state) {
    state = new PromiseState(promise);
    PromiseCache.set(promise, state);
  }

  return state;
}
