import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { assert } from '@ember/debug';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { cell } from 'ember-resources';
import { NotificationModal } from 'vfc-admin/components/ui/notification-modal';
import { awaitPromise } from 'vfc-admin/helpers/await-promise';
import { eq } from 'vfc-admin/utils/boolean-utils';
import { downloadFile } from 'vfc-admin/utils/file-utils';
import type TripDocumentsService from 'vfc-admin/services/trip-documents-service';
import type TripService from 'vfc-admin/services/trip-service';
import type { Trip } from 'vfc-admin/types/trip';
let FilesState = class FilesState {
    @tracked
    budgetFile?: File;
    @tracked
    budgetFileName?: string;
    @tracked
    proformaFile?: File;
    @tracked
    proformaFileName?: string;
    @tracked
    contractFile?: File;
    @tracked
    contractFileName?: string;
    @tracked
    contractState?: string;
};
interface TripDocumentsTabSignature {
    Element: HTMLDivElement;
    Args: {
        tripPromise: Promise<Trip>;
    };
}
let TripDocumentsTabComponent = class TripDocumentsTabComponent extends Component<TripDocumentsTabSignature> {
    @tracked
    filesState = new FilesState();
    @service
    tripDocumentsService: TripDocumentsService;
    @service
    tripService: TripService;
    get downloadFunctions() {
        return {
            summary: this.tripDocumentsService.downloadSummaryPDF.bind(this.tripDocumentsService),
            contract: this.tripDocumentsService.downloadContractPDF.bind(this.tripDocumentsService),
            proforma: this.tripDocumentsService.downloadProformaPDF.bind(this.tripDocumentsService)
        } as Record<string, (tripId: string) => Promise<Response>>;
    }
    get tripResource() {
        return awaitPromise<Trip>(this.args.tripPromise);
    }
    get budget() {
        if (!this.tripResource.isSuccess) return;
        return {
            name: this.filesState.budgetFileName ?? this.tripResource?.result?.summaryPdf,
            file: this.filesState.budgetFile
        };
    }
    get proforma() {
        if (!this.tripResource.isSuccess) return;
        return {
            name: this.filesState.proformaFileName ?? this.tripResource?.result?.proforma,
            file: this.filesState.proformaFile
        };
    }
    get contract() {
        if (!this.tripResource.isSuccess) return;
        return {
            name: this.filesState.contractFileName ?? this.tripResource?.result?.contract,
            file: this.filesState.contractFile,
            state: this.filesState.contractState ?? this.tripResource?.result?.contractState
        };
    }
    get tripId() {
        if (!this.tripResource.isSuccess) return;
        return String(this.tripResource?.result?.id);
    }
    @action
    onFileChange(event1: Event) {
        const target1 = event1.target as HTMLInputElement;
        const files1 = target1.files;
        if (files1 && files1.length > 0) {
            const file1 = files1[0];
            if (!file1) return;
            switch(target1.id){
                case 'budget-file-upload':
                    this.filesState.budgetFile = file1;
                    this.filesState.budgetFileName = file1?.name;
                    break;
                case 'proforma-file-upload':
                    this.filesState.proformaFile = file1;
                    this.filesState.proformaFileName = file1?.name;
                    break;
                case 'contract-file-upload':
                    this.filesState.contractFile = file1;
                    this.filesState.contractFileName = file1?.name;
            }
            if (!this.tripId) throw new Error('tripId is required');
            this.tripDocumentsService.upload(this.tripId, target1.name, file1);
        }
    }
    @action
    onView(documentType1: string, file1: File | undefined) {
        if (!file1 || !this.tripId) {
            this.downloadFunctions[documentType1](this.tripId).then((response1: Response)=>{
                downloadFile(response1);
            });
        } else {
            window.open(URL.createObjectURL(file1), '_blank');
        }
    }
    @action
    onAccept(documentType1: string) {
        if (!this.tripId) return;
        if (documentType1 === 'contract') {
            this.filesState.contractState = 'accepted';
            this.tripDocumentsService.setContractState(this.tripId, 'accept').then(()=>{});
        }
    }
    @action
    onReject(documentType1: string) {
        if (!this.tripId) return;
        if (documentType1 === 'contract') {
            this.filesState.contractState = 'rejected';
            this.tripDocumentsService.setContractState(this.tripId, 'reject').then(()=>{});
        }
    }
    @action
    generateContract(event1: Event) {
        if (!this.tripResource.result) return;
        const eventTarget1 = event1.target as HTMLInputElement;
        eventTarget1.disabled = true;
        eventTarget1.value = 'Cargando...';
        this.tripService.generateContract(String(this.tripResource.result.id)).catch((error1: Error)=>{
            console.error(error1);
        }).finally(()=>{
            this.filesState.contractState = 'pending';
            eventTarget1.disabled = false;
        });
    }
    @action
    generateProforma(event1: Event) {
        if (!this.tripResource.result) return;
        const eventTarget1 = event1.target as HTMLInputElement;
        eventTarget1.disabled = true;
        eventTarget1.value = 'Cargando...';
        this.tripService.generateProforma(String(this.tripResource.result.id)).catch((error1: Error)=>{
            console.error(error1);
        }).finally(()=>{
            eventTarget1.disabled = false;
        });
    }
    @action
    onDelete(id1: string) {
        assert('tripId is required', this.tripId);
        switch(id1){
            case 'budget-file-upload':
                this.tripDocumentsService.removeSummaryPDF(this.tripId);
                this.filesState.budgetFile = undefined;
                this.filesState.budgetFileName = '';
                break;
            case 'proforma-file-upload':
                this.tripDocumentsService.removeProformaPDF(this.tripId);
                this.filesState.proformaFile = undefined;
                this.filesState.proformaFileName = '';
                break;
            case 'contract-file-upload':
                this.tripDocumentsService.removeContractPDF(this.tripId);
                this.filesState.contractFile = undefined;
                this.filesState.contractFileName = '';
                this.filesState.contractState = 'pending';
                break;
        }
        const inputFile1 = document.getElementById(id1) as HTMLInputElement;
        inputFile1.value = '';
    }
    static{
        template(`
    <div class="flex gap-4 justify-end">
      <button
        {{on "click" this.generateProforma}}
        type="button"
        class="group/contract btn-md inline-flex items-center gap-x-2 text-sm font-semibold rounded-md border border-transparent bg-lightsecondary text-secondaryemphasis enabled:hover:bg-secondary enabled:hover:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 disabled:opacity-50 disabled:bg-lightsecondary"
      >
        <i class="ti ti-file-text text-base group-disabled/contract:hidden"></i>
        <span
          class="animate-spin inline-block size-4 border-[3px] border-current border-t-transparent text-secondaryemphasis enabled:group-hover/contract:text-white rounded-full group-enabled/contract:hidden"
          role="status"
          aria-label="loading"
        ></span>
        Generar proforma
      </button>
      <button
        {{on "click" this.generateContract}}
        type="button"
        class="group/contract btn-md inline-flex items-center gap-x-2 text-sm font-semibold rounded-md border border-transparent bg-lightprimary text-primaryemphasis enabled:hover:bg-primary enabled:hover:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 disabled:opacity-50 disabled:bg-lightprimary"
      >
        <i class="ti ti-file-text text-base group-disabled/contract:hidden"></i>
        <span
          class="animate-spin inline-block size-4 border-[3px] border-current border-t-transparent text-primaryemphasis enabled:group-hover/contract:text-white rounded-full group-enabled/contract:hidden"
          role="status"
          aria-label="loading"
        ></span>
        Generar contrato
      </button>
    </div>
    <div class="flex flex-col gap-y-5 my-2">
      <div class="flex items-center space-x-4">
        <div class="flex items-center space-x-4 border border-gray-300 rounded-lg pr-2">
          <label for="budget-file-upload" class="group flex items-center cursor-pointer w-full">
            <span
              class="group-hover:bg-blue-100 flex items-center cursor-pointer min-w-32 bg-blue-50 text-blue-700 font-semibold py-2 px-4 rounded-l-lg"
            >Presupuesto</span>
            {{#if this.tripResource.isPending}}
              <span
                role="status"
                class="min-w-48 max-w-sm animate-pulse flex items-center justify-center"
              >
                <span class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></span>
              </span>
            {{else if this.tripResource.isSuccess}}
              <span class="ml-2 truncate text-sm px-5 min-w-48" title={{this.budget.name}}>
                {{if this.budget.name this.budget.name "Seleccionar archivo"}}</span>
            {{/if}}
          </label>
          <input
            id="budget-file-upload"
            name="summary"
            type="file"
            accept=".pdf"
            class="hidden"
            {{on "change" this.onFileChange}}
          />
        </div>
        {{#if this.budget.name}}
          <div class="flex gap-3 text-center">
            <button
              {{on "click" (fn this.onView "summary" this.budget.file)}}
              class="text-info edit"
            >
              <i class="ti ti-eye text-lg"></i>
            </button>

            {{#let (cell false) as |showDialog|}}
              <button {{on "click" showDialog.toggle}} class="text-error cursor-pointer">
                <i class="ti ti-trash text-lg"></i>
              </button>
              {{#if showDialog.current}}
                <NotificationModal
                  @title="Confirmar eliminación"
                  @message="¿Está seguro de que desea eliminar el archivo de presupuesto?"
                  @onConfirm={{(fn this.onDelete "budget-file-upload")}}
                  @onClose={{showDialog.toggle}}
                />
              {{/if}}
            {{/let}}

          </div>
        {{/if}}
      </div>
      <div class="flex items-center space-x-4">
        <div class="flex items-center space-x-4 border border-gray-300 rounded-lg pr-2">
          <label for="proforma-file-upload" class="group flex items-center cursor-pointer w-full">
            <span
              class="group-hover:bg-blue-100 flex items-center cursor-pointer min-w-32 bg-blue-50 text-blue-700 font-semibold py-2 px-4 rounded-l-lg"
            >Proforma</span>
            {{#if this.tripResource.isPending}}
              <span
                role="status"
                class="min-w-48 max-w-sm animate-pulse flex items-center justify-center"
              >
                <span class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></span>
              </span>
            {{else if this.tripResource.isSuccess}}
              <span class="ml-2 truncate text-sm px-5 min-w-48" title={{this.proforma.name}}>
                {{if this.proforma.name this.proforma.name "Seleccionar archivo"}}</span>
            {{/if}}
          </label>
          <input
            id="proforma-file-upload"
            name="proforma"
            type="file"
            accept=".pdf"
            class="hidden"
            {{on "change" this.onFileChange}}
          />
        </div>
        {{#if this.proforma.name}}
          <div class="flex gap-3 text-center">
            <button
              {{on "click" (fn this.onView "proforma" this.proforma.file)}}
              class="text-info edit"
            >
              <i class="ti ti-eye text-lg"></i>
            </button>
            {{#let (cell false) as |showDialog|}}
              <button {{on "click" showDialog.toggle}} class="text-error cursor-pointer">
                <i class="ti ti-trash text-lg"></i>
              </button>
              {{#if showDialog.current}}
                <NotificationModal
                  @title="Confirmar eliminación"
                  @message="¿Está seguro de que desea eliminar el archivo de proforma?"
                  @onConfirm={{(fn this.onDelete "proforma-file-upload")}}
                  @onClose={{showDialog.toggle}}
                />
              {{/if}}
            {{/let}}
          </div>
        {{/if}}
      </div>
      <div class="flex items-center space-x-4">
        <div class="flex items-center space-x-4 border border-gray-300 rounded-lg pr-2">
          <label for="contract-file-upload" class="group flex items-center cursor-pointer w-full">
            <span
              class="group-hover:bg-blue-100 flex items-center cursor-pointer min-w-32 bg-blue-50 text-blue-700 font-semibold py-2 px-4 rounded-l-lg"
            >Contrato</span>
            {{#if this.tripResource.isPending}}
              <span
                role="status"
                class="min-w-48 max-w-sm animate-pulse flex items-center justify-center"
              >
                <span class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></span>
              </span>
            {{else if this.tripResource.isSuccess}}
              <span class="ml-2 truncate text-sm px-5 min-w-48" title={{this.contract.name}}>
                {{if this.contract.name this.contract.name "Seleccionar archivo"}}</span>
            {{/if}}
          </label>
          <input
            id="contract-file-upload"
            name="contract"
            type="file"
            accept=".pdf"
            class="hidden"
            {{on "change" this.onFileChange}}
          />
        </div>
        {{#if this.contract.name}}
          <div class="flex gap-3 text-center">
            <button
              {{on "click" (fn this.onView "contract" this.contract.file)}}
              class="text-info edit"
            >
              <i class="ti ti-eye text-lg"></i>
            </button>
            {{#let (cell false) as |showDialog|}}
              <button {{on "click" showDialog.toggle}} class="text-error cursor-pointer">
                <i class="ti ti-trash text-lg"></i>
              </button>
              {{#if showDialog.current}}
                <NotificationModal
                  @title="Confirmar eliminación"
                  @message="¿Está seguro de que desea eliminar el archivo de contrato?"
                  @onConfirm={{(fn this.onDelete "contract-file-upload")}}
                  @onClose={{showDialog.toggle}}
                />
              {{/if}}
            {{/let}}
            {{#if (eq this.contract.state "signed")}}
              <div class="flex ms-4 gap-2 items-center">
                Pendiente de revisar:
                <div class="flex gap-8">
                  <button
                    {{on "click" (fn this.onAccept "contract")}}
                    type="button"
                    class="btn-md text-sm flex gap-1 items-center font-medium rounded-md border border-transparent bg-success text-white hover:bg-successemphasis dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  >
                    Aceptar
                    <i class="ti ti-circle-check text-sm"></i>
                  </button>
                  <button
                    {{on "click" (fn this.onReject "contract")}}
                    type="button"
                    class="btn-md text-sm flex gap-1 items-center font-medium rounded-md border border-transparent bg-error text-white hover:bg-erroremphasis dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  >
                    Rechazar
                    <i class="ti ti-x text-sm"></i>
                  </button>
                </div>
              </div>
            {{else if (eq this.contract.state "accepted")}}
              <span
                class="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-lightsuccess text-success dark:bg-darksuccess dark:text-success"
              >
                Aceptado
              </span>
            {{else if (eq this.contract.state "pending")}}
              <span
                class="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-lightwarning text-warning dark:bg-darkwarning dark:text-warning"
              >
                Pendiente
              </span>

            {{else if (eq this.contract.state "rejected")}}
              <span
                class="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-lighterror text-error dark:bg-darkerror dark:text-error"
              >
                Rechazado
              </span>
            {{/if}}
          </div>
        {{/if}}
      </div>

    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export { TripDocumentsTabComponent };
