import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import RouteTemplate from 'ember-route-template';
import FullCalendar from 'vfc-admin/components/ui/full-calendar';
import { concat } from 'vfc-admin/utils/string-utils';
import type { EventInput } from '@fullcalendar/core';
import type { Calendar } from '@fullcalendar/core';
import type { DatesSetArg } from '@fullcalendar/core/dist/types/types/date-input';
import type RouterService from 'ember-router/services/router';
import type CalendarService from 'vfc-admin/services/calendar-service';
const STATE_TO_ROUTE_PREFIX = {
    inprogress: 'current-course',
    pending: 'pending',
    closed: 'current-course',
    incomplete: 'incomplete'
};
const EVENT_TYPE_TO_ROUTE = {
    'trip-start': (routePrefix1)=>`authenticated.trips.${routePrefix1}.trip`,
    'trip-end': (routePrefix1)=>`authenticated.trips.${routePrefix1}.trip`,
    'trip-scheduled-payment': (routePrefix1)=>`authenticated.trips.${routePrefix1}.trip.payments`
};
let CalendarPage = class CalendarPage extends Component {
    @service
    calendarService: CalendarService;
    @service
    router: RouterService;
    get colorLegend() {
        return [
            {
                label: 'Salidas Clientes',
                color: '#fdede8'
            },
            {
                label: 'LLegadas Clientes',
                color: '#E6FFFA'
            },
            {
                label: 'Fechas Limites Pago',
                color: '#ebf3fe'
            }
        ];
    }
    @action
    onDatesSet(calendar1: Calendar, dates1: DatesSetArg) {
        this.calendarService.findEntriesByDateRange(dates1.startStr, dates1.endStr).then((events1)=>{
            let bgColor1 = null;
            let textColor1 = null;
            let icon1 = null;
            //TODO: Improve how each event is rendered by
            // - Using generators to exit when a new data set is provided
            // - Using a web worker to render the events in the background
            events1.data.forEach((event1)=>{
                switch(event1.type){
                    case 'trip-start':
                        {
                            bgColor1 = '#fdede8';
                            textColor1 = '#FA896B';
                            icon1 = '<i class="ti ti-plane-departure"></i>';
                            break;
                        }
                    case 'trip-end':
                        {
                            bgColor1 = '#E6FFFA';
                            textColor1 = '#13DEB9';
                            icon1 = '<i class="ti ti-plane-arrival"></i>';
                            break;
                        }
                    case 'trip-scheduled-payment':
                        {
                            bgColor1 = '#ebf3fe';
                            textColor1 = '#539bff';
                            icon1 = '<i class="ti ti-coin-euro"></i>';
                            break;
                        }
                }
                calendar1.addEvent({
                    title: event1.title,
                    start: event1.startDate,
                    end: event1.endDate,
                    backgroundColor: bgColor1,
                    borderColor: 'transparent',
                    textColor: textColor1,
                    icon: icon1,
                    type: event1.type,
                    data: event1.data
                });
            });
        });
    }
    @action
    onEventClick(info1: {
        event: EventInput;
        jsEvent: Event;
    }) {
        const { event: event1, jsEvent: jsEvent1 } = info1;
        // double click
        if (jsEvent1.detail === 2) {
            const { type: type1, data: { trip: { id: id1, state: state1 } } } = event1.extendedProps;
            const routePrefix1 = STATE_TO_ROUTE_PREFIX[state1];
            if (!routePrefix1) return;
            const route1 = EVENT_TYPE_TO_ROUTE[type1](routePrefix1);
            if (route1) {
                this.router.transitionTo(route1, id1);
            }
        }
    }
    static{
        template(`
    <div class="lg:col-span-8 md:col-span-6 sm:col-span-12 col-span-12">
      <div class="card">
        <div class="border-b border-light-dark py-4 px-6 flex items-center justify-between">
          <h5 class="card-title mb-0">Calendario</h5>
        </div>
        <div class="card-body">
          <div class="flex justify-end flex-wrap gap-4 my-4">
            {{#each this.colorLegend as |item|}}
              <div class="flex items-center">
                <div
                  class="w-4 h-4 mr-2 border border-gray-300 rounded"
                  style={{htmlSafe (concat "background-color: " item.color)}}
                ></div>
                <span class="text-sm">{{item.label}}</span>
              </div>
            {{/each}}
          </div>
          <FullCalendar @onDatesSet={{this.onDatesSet}} @onEventClick={{this.onEventClick}} />
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(template(`<CalendarPage />`, {
    eval () {
        return eval(arguments[0]);
    }
}));
