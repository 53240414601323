import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import { TripListPage } from 'vfc-admin/components/pages/trip-list-page';
import type { TripsResponse } from 'vfc-admin/services/trip-service';
export default RouteTemplate<{
    Args: {
        model: {
            trips: Promise<TripsResponse>;
            query: string;
        };
    };
}>(template(`
    <TripListPage
      @title="Viajes - Pendientes"
      @tripType="pending"
      @trips={{@model.trips}}
      @query={{@model.query}}
    />
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
