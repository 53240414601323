const eq = (a: unknown, b: unknown) => {
  return a === b;
};

const and = (a: unknown, b: unknown) => {
  return Boolean(a) && Boolean(b);
};

const or = (a: boolean, b: boolean) => {
  return Boolean(a) || Boolean(b);
};

const not = (a: boolean) => {
  return !a;
};

export { and, eq, not, or };
