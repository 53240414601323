import { modifier } from 'ember-modifier';

import type { Modifier } from 'ember-modifier';

interface OnKeyOptions {
  key?: string;
  altKey?: boolean;
  ctrlKey?: boolean;
  metaKey?: boolean;
  shiftKey?: boolean;
}

type OnKeyCallback = (event: KeyboardEvent) => void;

const onKey: Modifier<[OnKeyCallback, OnKeyOptions?]> = modifier(
  (element, [callback, options = {}]) => {
    function handleKeyDown(event: KeyboardEvent) {
      if (options.key && event.key !== options.key) return;
      if (options.altKey && !event.altKey) return;
      if (options.ctrlKey && !event.ctrlKey) return;
      if (options.metaKey && !event.metaKey) return;
      if (options.shiftKey && !event.shiftKey) return;

      callback(event);
    }

    element.addEventListener('keydown', handleKeyDown);

    return () => {
      element.removeEventListener('keydown', handleKeyDown);
    };
  }
);

export { onKey };
