const noop = () => {};

let debounceTimeoutId: ReturnType<typeof setTimeout> | null = null;

const debounce = <T extends (...args: unknown[]) => void>(
  func: T,
  delay: number
): ((...args: Parameters<T>) => void) => {
  return (...args: Parameters<T>): void => {
    if (debounceTimeoutId) {
      clearTimeout(debounceTimeoutId);
      debounceTimeoutId = null;
    }

    debounceTimeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export { debounce, noop };
