import Route from '@ember/routing/route';
import { service } from '@ember/service';

import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import type ClientService from 'vfc-admin/services/client-service';
import type SchoolService from 'vfc-admin/services/school-service';
import type TripService from 'vfc-admin/services/trip-service';
import type { Trip } from 'vfc-admin/types/trip';
import type RelationService from 'vfc-admin/services/relation-service';

export default class TripView extends Route {
  @service declare router: RouterService;
  @service declare tripService: TripService;
  @service declare clientService: ClientService;
  @service declare schoolService: SchoolService;
  @service declare relationService: RelationService;

  async model(params: { id: string }, transition: Transition) {
    const tripId = params.id;
    const tripPromise = this.tripService.get(tripId);
    const clientPromise = this.clientService.findAll();
    const schoolPromise = this.schoolService.findAll();
    const clientRelationsPromise = this.relationService.findAll();

    tripPromise.then((trip: Trip) => {
      if (!['inprogress', 'closed'].includes(trip.state)) {
        transition.abort();
        this.router.transitionTo('/not-found');
      }
    });

    return {
      tripId,
      tripPromise,
      clientPromise,
      schoolPromise,
      clientRelationsPromise,
      parentRedirectUrl: 'authenticated.trips.current-course',
    };
  }
}
