import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { eq } from 'vfc-admin/utils/boolean-utils';
import type RouterService from '@ember/routing/router-service';
const TOTAL_PAGES = 7;
const LEFT_PAGES = 5;
const RIGHT_PAGES = 5;
let Pagination = class Pagination extends Component<{
    Element: HTMLElement;
    Args: {
        currentPage: number;
        totalPages: number;
        pageSize: number;
        onLeftNavPageClick: (page: number) => string;
        onRightNavPageClick: (page: number) => string;
        onNavPageClick: (page: number) => string;
    };
}> {
    @service
    router: RouterService;
    get pagesToDisplay() {
        const { totalPages: totalPages1, currentPage: currentPage1 } = this.args;
        let pages1: Array<string | number> = [];
        if (totalPages1 <= TOTAL_PAGES) {
            pages1 = Array.from(Array(totalPages1), (_1, index1)=>index1 + 1);
        } else if (currentPage1 + 1 <= LEFT_PAGES) {
            const firstPages1 = Array.from(Array(LEFT_PAGES), (_1, index1)=>index1 + 1);
            pages1 = [
                ...firstPages1,
                '...',
                totalPages1
            ];
        } else if (currentPage1 > totalPages1 - (RIGHT_PAGES - 1)) {
            pages1 = [
                1,
                '...',
                ...Array.from(Array(RIGHT_PAGES), (_1, index1)=>totalPages1 - (RIGHT_PAGES - 1) + index1)
            ];
        } else {
            pages1 = [
                1,
                '...',
                currentPage1 - 1,
                currentPage1,
                currentPage1 + 1,
                '...',
                totalPages1
            ];
        }
        return pages1;
    }
    onLeftNavPageClick = (page1: number)=>{
        if (this.args.onLeftNavPageClick) {
            return this.args.onLeftNavPageClick(page1);
        }
        const url1 = new URL(this.router.currentURL, window.location.origin);
        url1.searchParams.set('page', String(page1 - 1));
        return url1.pathname + url1.search;
    };
    onRightNavPageClick = (page1: number)=>{
        if (this.args.onRightNavPageClick) {
            return this.args.onRightNavPageClick(page1);
        }
        const url1 = new URL(this.router.currentURL, window.location.origin);
        url1.searchParams.set('page', String(page1 + 1));
        return url1.pathname + url1.search;
    };
    onNavPageClick = (page1: number)=>{
        if (this.args.onNavPageClick) {
            return this.args.onNavPageClick(page1);
        }
        const url1 = new URL(this.router.currentURL, window.location.origin);
        url1.searchParams.set('page', String(page1));
        return url1.pathname + url1.search;
    };
    isLeftNavPageDisabled = ()=>{
        const { currentPage: currentPage1 } = this.args;
        return currentPage1 <= 1;
    };
    isRightNavPageDisabled = ()=>{
        const { currentPage: currentPage1, totalPages: totalPages1 } = this.args;
        if (totalPages1 === 0) return true;
        return currentPage1 === totalPages1;
    };
    static{
        template(`
    <nav class="flex items-center gap-x-1">
      <a
        href={{this.onLeftNavPageClick @currentPage}}
        class="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex jusify-center items-center gap-x-2 text-sm rounded-md text-gray-800
          {{if this.isLeftNavPageDisabled 'opacity-50 pointer-events-none' 'hover:bg-gray-100'}}
          focus:outline-none focus:bg-gray-100 dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
      >
        <i class="ti ti-chevron-left text-sm leading-tight font-medium"></i>
        <span aria-hidden="true" class="sr-only leading-tight">Previous</span>
      </a>
      <div class="flex items-center gap-x-1">
        {{#each this.pagesToDisplay as |page|}}
          {{#if (eq page "...")}}
            ...
          {{else}}
            <a
              href={{this.onNavPageClick page}}
              class="min-h-[38px] min-w-[38px] flex justify-center items-center
                {{if (eq page @currentPage) 'bg-gray-200 pointer-events-none' 'hover:bg-gray-100'}}
                text-gray-800 py-2 px-3 text-sm rounded-md focus:outline-none focus:bg-gray-300 dark:bg-gray-600 dark:text-white dark:focus:bg-gray-500"
              aria-current="page"
            >{{page}}</a>
          {{/if}}
        {{/each}}
      </div>
      <a
        href={{this.onRightNavPageClick @currentPage}}
        class="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex jusify-center items-center gap-x-2 text-sm rounded-md text-gray-800
          {{if this.isRightNavPageDisabled 'opacity-50 pointer-events-none' 'hover:bg-gray-100'}}
          focus:outline-none focus:bg-gray-100 dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
      >
        <span aria-hidden="true" class="sr-only leading-tight">Next</span>
        <i class="ti ti-chevron-right text-sm leading-tight font-medium"></i>
      </a>
    </nav>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export { Pagination };
