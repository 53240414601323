import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { type FormData } from 'ember-headless-form';
import RouteTemplate from 'ember-route-template';
import SchoolFormComponent from 'vfc-admin/components/forms/schoolForm';
import type { School, SchoolForm } from 'vfc-admin/models/school';
import type SchoolService from 'vfc-admin/services/school-service';
interface SchoolNewSignature {
    Element: HTMLDivElement;
    Args: {
        school: School;
    };
}
let SchoolNewComponent = class SchoolNewComponent extends Component<SchoolNewSignature> {
    @service
    schoolService: SchoolService;
    @service
    router: RouterService;
    @action
    async onSubmit(data1: FormData<SchoolForm>) {
        try {
            await this.schoolService.create(data1);
            this.router.transitionTo('authenticated.schools');
        } catch (error1) {
            console.log(error1);
        }
    }
    static{
        template(`
    <div class="card">
      <div class="col-span-12">
        <div class="border-b border-light-dark py-4 px-6 flex items-center justify-between">
          <h5 class="card-title mb-0">Nuevo colegio</h5>
        </div>
      </div>
      <div class="card-body">
        <SchoolFormComponent @school={{@school}} @onSubmit={{this.onSubmit}} />
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate<{
    Args: {
        model: {
            school: School;
        };
    };
}>(template(`<SchoolNewComponent @school={{@model.school}} />`, {
    eval () {
        return eval(arguments[0]);
    }
}));
