import Service from '@ember/service';
import { service } from '@ember/service';

import config from 'vfc-admin/config/environment';

import type { Room } from 'vfc-admin/model/room';
import type AuthenticatedHttpClient from 'vfc-admin/services/auth/http-client';

export type RoomResponse = {
  disableRoomAvailability: string;
  enableRoomAvailability: string;
  rooms: Array<Room>;
};

export default class TripRoomService extends Service {
  @service('auth/http-client') declare authenticatedHttpClient: AuthenticatedHttpClient;

  static ENDPOINTS = {
    findAll: '/trip/:id/rooms',
    create: '/trip/:id/rooms',
    update: '/trip/:id/rooms/:roomId',
    delete: '/trip/:id/rooms/:roomId',
  };

  findAll(id: string): Promise<RoomResponse> {
    const endpoint = TripRoomService.ENDPOINTS.findAll.replace(':id', id);

    return this.authenticatedHttpClient.GET<RoomResponse>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
    });
  }

  createRoom(tripId: string, body: Room): Promise<Room> {
    const endpoint = TripRoomService.ENDPOINTS.findAll.replace(':id', tripId);
    const headers = new Headers();

    headers.append('Content-Type', 'application/json');

    return this.authenticatedHttpClient.POST<Room>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
      headers,
      body,
    });
  }

  updateRoom(tripId: string, body: Room): Promise<Room> {
    const endpoint = TripRoomService.ENDPOINTS.update
      .replace(':id', tripId)
      .replace(':roomId', body.id);
    const headers = new Headers();

    headers.append('Content-Type', 'application/json');

    return this.authenticatedHttpClient.PUT<Room>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
      headers,
      body,
    });
  }

  deleteRoom(tripId: string, roomId: string): Promise<Room> {
    const endpoint = TripRoomService.ENDPOINTS.delete
      .replace(':id', tripId)
      .replace(':roomId', roomId);

    return this.authenticatedHttpClient.DELETE<Room>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'trip-room-service': TripRoomService;
  }
}
