import { tracked } from '@glimmer/tracking';

class Room {
  @tracked id?: string;
  @tracked name = '';
  @tracked observations = '';
  @tracked beds = 0;
  @tracked type = 'male';

  constructor({
    id,
    name,
    observations,
    beds,
    type,
  }: {
    id?: string;
    name: string;
    observations: string;
    beds: number;
    type: string;
  } = {}) {
    this.id = id;
    this.name = name;
    this.observations = observations;
    this.beds = beds;
    this.type = type;
  }
}

export { Room };
