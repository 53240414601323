import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
interface HomeSignature {
    Element: HTMLDivElement;
    Args: {
    };
}
// eslint-disable-next-line ember/no-empty-glimmer-component-classes
let HomeComponent = class HomeComponent extends Component<HomeSignature> {
    static{
        template(``, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(HomeComponent);
