import Service from '@ember/service';
import { service } from '@ember/service';
import { TripDocumentsTabComponent } from 'vfc-admin/components/pages/trip-documents-tab';

import config from 'vfc-admin/config/environment';
import {
  TripPayment,
  TripPaymentRefund,
  TripPaymentScheduled,
  type TripPaymentForm,
  type TripPaymentRefundForm,
  type TripPaymentScheduledForm,
} from 'vfc-admin/models/payments';

import type AuthenticatedHttpClient from 'vfc-admin/services/auth/http-client';

export default class TripPaymentService extends Service {
  @service('auth/http-client') declare authenticatedHttpClient: AuthenticatedHttpClient;

  static ENDPOINTS = {
    payment: '/trip/:tripId/payments/:paymentId',
    payments: '/trip/:tripId/payments',
    paymentScheduled: '/trip/:tripId/payments-scheduled/:paymentId',
    paymentsScheduled: '/trip/:tripId/payments-scheduled',
    paymentRefund: '/trip/:tripId/refund-payments/:paymentRefundId',
    paymentsRefund: '/trip/:tripId/refund-payments',
  };

  // Payments

  delete(tripId: string, paymentId: string): Promise<void> {
    const endpoint = TripPaymentService.ENDPOINTS.payment
      .replace(':tripId', tripId)
      .replace(':paymentId', paymentId);
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    return this.authenticatedHttpClient.DELETE<void>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
      headers,
    });
  }

  findAll(tripId: string): Promise<TripPayment[]> {
    const endpoint = TripPaymentService.ENDPOINTS.payments.replace(':tripId', tripId);

    return this.authenticatedHttpClient
      .GET<TripPayment[]>({
        url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
      })
      .then((data: any) => {
        return data.map((data: any) => TripPayment.parse(data));
      });
  }

  create(tripId: string, body: TripPaymentForm): Promise<void> {
    const endpoint = TripPaymentService.ENDPOINTS.payments.replace(':tripId', tripId);
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    return this.authenticatedHttpClient.POST<void>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
      body,
      headers,
    });
  }

  // Scheduled payments
  findAllScheduled(tripId: string): Promise<TripPaymentScheduled[]> {
    const endpoint = TripPaymentService.ENDPOINTS.paymentsScheduled.replace(':tripId', tripId);

    return this.authenticatedHttpClient
      .GET<TripPaymentScheduled[]>({
        url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
      })
      .then((data: any) => {
        return data['scheduled'].map((data: any) => TripPaymentScheduled.parse(data));
      });
  }

  createScheduled(tripId: string, body: TripPaymentScheduledForm): Promise<void> {
    const endpoint = TripPaymentService.ENDPOINTS.paymentsScheduled.replace(':tripId', tripId);
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    return this.authenticatedHttpClient.POST<void>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
      body,
      headers,
    });
  }

  updateScheduled(
    tripId: string,
    paymentId: string,
    body: TripPaymentScheduledForm
  ): Promise<void> {
    const endpoint = TripPaymentService.ENDPOINTS.paymentScheduled
      .replace(':tripId', tripId)
      .replace(':paymentId', paymentId);
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    return this.authenticatedHttpClient.PUT<void>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
      body,
      headers,
    });
  }

  deleteScheduled(tripId: string, paymentId: string): Promise<void> {
    const endpoint = TripPaymentService.ENDPOINTS.paymentScheduled
      .replace(':tripId', tripId)
      .replace(':paymentId', paymentId);
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    return this.authenticatedHttpClient.DELETE<void>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
      headers,
    });
  }

  // Rrefund payments
  findAllRefunds(tripId: string): Promise<TripPaymentRefund[]> {
    const endpoint = TripPaymentService.ENDPOINTS.paymentsRefund.replace(':tripId', tripId);

    return this.authenticatedHttpClient
      .GET<TripPaymentRefund[]>({
        url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
      })
      .then((data: any) => {
        return data.map((data: any) => TripPaymentRefund.parse(data));
      });
  }

  createRefund(tripId: string, body: TripPaymentRefundForm): Promise<void> {
    const endpoint = TripPaymentService.ENDPOINTS.paymentsRefund.replace(':tripId', tripId);
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    return this.authenticatedHttpClient.POST<void>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
      body,
      headers,
    });
  }

  deleteRefund(tripId: string, paymentRefundId: string): Promise<void> {
    const endpoint = TripPaymentService.ENDPOINTS.paymentRefund
      .replace(':tripId', tripId)
      .replace(':paymentRefundId', paymentRefundId);
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    return this.authenticatedHttpClient.DELETE<void>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
      headers,
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'trip-payment-service': TripPaymentService;
  }
}
