import Service from '@ember/service';
import { service } from '@ember/service';

import config from 'vfc-admin/config/environment';

export default class PassengerService extends Service {
  @service('auth/http-client') declare authenticatedHttpClient: AuthenticatedHttpClient;

  static ENDPOINTS = {
    deleteStudent: '/trip/:tripId/students/:studentId',
  };

  deleteStudent(tripId: string, studentId: string): Promise<void> {
    const endpoint = PassengerService.ENDPOINTS.deleteStudent
      .replace(':tripId', tripId)
      .replace(':studentId', studentId);

    return this.authenticatedHttpClient.DELETE<void>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
    });
  }
}
