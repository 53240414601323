import { tracked } from '@glimmer/tracking';
import Service from '@ember/service';

enum ColorTheme {
  Blue_Theme = 'Blue_Theme',
  Aqua_Theme = 'Aqua_Theme',
  Purple_Theme = 'Purple_Theme',
  Green_Theme = 'Green_Theme',
  Cyan_Theme = 'Cyan_Theme',
  Orange_Theme = 'Orange_Theme',
}

export class ThemeSettings {
  @tracked layout: 'vertical' | 'horizontal' = 'vertical';
  @tracked sidebarType: 'full' | 'mini-sidebar' = 'full';
  @tracked boxedLayout: boolean = true;
  @tracked direction: 'ltr' | 'rtl' = 'ltr';
  @tracked theme: 'dark' | 'light' = 'light';
  @tracked colorTheme: ColorTheme = ColorTheme.Blue_Theme;
  @tracked cardBorder: boolean = false;

  constructor(props: Object) {
    Object.assign(this, props);
  }

  toJSON() {
    return {
      layout: this.layout,
      sidebarType: this.sidebarType,
      boxedLayout: this.boxedLayout,
      direction: this.direction,
      theme: this.theme,
      colorTheme: this.colorTheme,
      cardBorder: this.cardBorder,
    };
  }
}

export default class ThemeService extends Service {
  @tracked private _themeSettings: ThemeSettings;

  constructor() {
    super();

    this._themeSettings = new ThemeSettings(this._getInitvalues());
  }

  get layout() {
    return this._themeSettings.layout;
  }

  set sidebarType(sidebarType: 'full' | 'mini-sidebar') {
    this._themeSettings.sidebarType = sidebarType;
    this._updatedThemeSettings();
  }

  get sidebarType() {
    return this._themeSettings.sidebarType;
  }

  set boxedLayout(boxedLayout: boolean) {
    this._themeSettings.boxedLayout = boxedLayout;
    this._updatedThemeSettings();
  }

  get boxedLayout() {
    return this._themeSettings.boxedLayout;
  }

  set direction(direction: 'ltr' | 'rtl') {
    this._themeSettings.direction = direction;
    this._updatedThemeSettings();
  }

  get direction() {
    return this._themeSettings.direction;
  }

  get theme() {
    return this._themeSettings.theme;
  }

  set colorTheme(colorTheme: ColorTheme) {
    this._themeSettings.colorTheme = colorTheme;
    this._updatedThemeSettings();
  }

  get colorTheme() {
    return this._themeSettings.colorTheme;
  }

  set cardBorder(cardBorder: boolean) {
    this._themeSettings.cardBorder = cardBorder;
    this._updatedThemeSettings();
  }

  get cardBorder() {
    return this._themeSettings.cardBorder;
  }

  _updatedThemeSettings() {
    localStorage.setItem('themeSettings', JSON.stringify(this._themeSettings));
  }

  _getInitvalues() {
    return JSON.parse(localStorage.getItem('themeSettings') || '{}');
  }
}
