import Service from '@ember/service';
import { service } from '@ember/service';

import config from 'vfc-admin/config/environment';
import { School, type SchoolForm, type SchoolInterface } from 'vfc-admin/models/School';

import type AuthenticatedHttpClient from 'vfc-admin/services/auth/http-client';
import type { Paginated } from 'vfc-admin/types/paginated';
import type { Pagination } from 'vfc-admin/types/pagination';

export default class SchoolService extends Service {
  @service('auth/http-client') declare authenticatedHttpClient: AuthenticatedHttpClient;

  static ENDPOINTS = {
    findAll: '/school',
    get: '/school/:id',
  };

  findAll(
    query?: string,
    pagination: Pagination = { page: 1, pageSize: 10 }
  ): Promise<Paginated<School>> {
    const filters = new URLSearchParams({
      page: String(pagination.page),
      pageSize: String(pagination.pageSize),
    });

    if (query) {
      filters.append('query', query);
    }

    return this.authenticatedHttpClient.GET<Paginated<School>>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${SchoolService.ENDPOINTS.findAll}?${filters.toString()}`,
    });
  }

  get(id: string): Promise<School> {
    const endpoint = SchoolService.ENDPOINTS.get.replace(':id', id);

    return this.authenticatedHttpClient
      .GET<SchoolInterface>({
        url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
      })
      .then((data) => School.parse(data));
  }

  update(id: string, body: SchoolForm): Promise<void> {
    const endpoint = SchoolService.ENDPOINTS.get.replace(':id', id);
    const headers = new Headers();

    headers.append('Content-Type', 'application/json');

    return this.authenticatedHttpClient.PUT<void>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
      body,
      headers,
    });
  }

  create(body: SchoolForm): Promise<void> {
    const endpoint = SchoolService.ENDPOINTS.findAll;
    const headers = new Headers();

    headers.append('Content-Type', 'application/json');

    return this.authenticatedHttpClient.POST<void>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
      body,
      headers,
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'user-service': AuthenticatedHttpClient;
  }
}
