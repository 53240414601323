import { modifier } from 'ember-modifier';

export default modifier((element, [callback]: [callback: Function]) => {
  function handleClick(event: Event) {
    if (!element.contains(event.target)) callback(event);
  }

  document.addEventListener('click', handleClick);

  return () => {
    document.removeEventListener('click', handleClick);
  };
});
