import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import RouteTemplate from 'ember-route-template';
import { TrackedObject } from 'tracked-built-ins';
import type { IntlService } from 'ember-intl';
import type { Session } from 'ember-simple-auth/services/session';
import type UserService from 'vfc-admin/services/user-service';
type UserFormData = {
    email: string;
    password: string;
};
interface LoginRouteSignature {
    Args: {
        model: void;
    };
}
let LoginRouteComponent = class LoginRouteComponent extends Component<LoginRouteSignature> {
    @service
    session: Session;
    @service
    userService: UserService;
    @service
    intl: IntlService;
    data = new TrackedObject<UserFormData>();
    @tracked
    errorMessage?: string;
    @tracked
    passwordVisible = false;
    @action
    async onSubmit(event1: Event) {
        event1.preventDefault();
        try {
            await this.session.authenticate('authenticator:token', this.data.email, this.data.password);
        } catch (error1) {
            // eslint-disable-next-line no-console
            console.log(error1);
            this.errorMessage = this.intl.t('page.login.invalid-user-or-password');
        }
    }
    @action
    onInput({ currentTarget: formElement1 }: {
        currentTarget: EventTarget | null;
    }) {
        let formData1 = new FormData(formElement1 as HTMLFormElement);
        this.data = Object.fromEntries(formData1.entries()) as UserFormData;
    }
    @action
    togglePasswordVisibility() {
        this.passwordVisible = !this.passwordVisible;
    }
    static{
        template(`
    <main class="h-screen w-full bg-lightprimary">
      <div class="h-full w-full flex justify-center items-center">
        <div class="flex justify-center w-full">
          <div class="xl:w-2/6 w-full">
            <div class="max-w-[460px] px-3 mx-auto">
              <div class="card">
                <div class="card-body">
                  <div class="mx-auto text-center mb-12">
                    <div class="flex justify-center">
                      <div class="brand-logo flex items-center">
                        <img src="/images/logos/logo.svg" alt="Logo" />
                      </div>
                    </div>
                  </div>
                  <form {{on "submit" this.onSubmit}} {{on "input" this.onInput}}>
                    <div class="flex flex-col gap-4 mt-4">
                      <div>
                        <label class="text-dark dark:text-darklink font-semibold mb-2 block">
                          Email
                        </label>
                        <input required type="text" class="form-control py-2" name="email" />
                      </div>
                      <div>
                        <label class="text-dark dark:text-darklink font-semibold mb-2 block">
                          Contraseña
                        </label>
                        <input
                          required
                          type={{if this.passwordVisible "text" "password"}}
                          class="form-control py-2"
                          name="password"
                        />
                        {{#if this.passwordVisible}}
                          <i
                            class="ti ti-eye -m-6 cursor-pointer"
                            {{on "click" this.togglePasswordVisibility}}
                          />
                        {{else}}
                          <i
                            class="ti ti-eye-off -m-6 cursor-pointer"
                            {{on "click" this.togglePasswordVisibility}}
                          />
                        {{/if}}
                      </div>
                      <button class="btn btn-md py-3">Iniciar sesión</button>
                    </div>
                    <div class="text-xs text-red-500 py-1 flex justify-center">
                      {{this.errorMessage}}&#8203;
                    </div>
                    <div class="flex items-center flex-col mt-4">
                      <a
                        href="/request-password"
                        class="text-sm text-accent1-400 hover:text-primary cursor-pointer"
                      >
                        ¿Olvidaste tu contraseña?
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(LoginRouteComponent);
