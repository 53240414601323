import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import RouteTemplate from 'ember-route-template';
import Header from 'vfc-admin/components/header';
import SettingsOverlay from 'vfc-admin/components/settingsOverlay';
import { Sidebar } from 'vfc-admin/components/sidebar';
import type ThemeService from 'vfc-admin/services/theme-service';
import type { MyProfileType } from 'vfc-admin/services/user-service';
interface AuthenticatedRouteSignature {
    Args: {
        model: {
            myProfile: MyProfileType;
        };
    };
}
let AuthenticatedRouteComponent = class AuthenticatedRouteComponent extends Component<AuthenticatedRouteSignature> {
    @service
    themeService: ThemeService;
    static{
        template(`
    <Sidebar />
    <div class="page-wrapper w-full" role="main">
      <Header @myProfile={{@model.myProfile}} />
      <!-- Main Content -->
      <div class="max-w-full pt-6">
        <div class="{{if this.themeService.boxedLayout '' 'max-w-full'}} container full-container">
          {{outlet}}
        </div>
      </div>

    </div>
    <SettingsOverlay />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AuthenticatedRouteComponent);
