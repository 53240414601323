class SortUtils {
  static getNestedValue = (obj: Record<string, unknown>, path: string): string => {
    const parts = path.split('.');
    let result = obj;

    for (const part of parts) {
      if (result == null) {
        break;
      }

      result = result[part] as any;
    }

    return result as any;
  };
  static sortBy(
    items: Array<unknown>,
    direction: 'ASC' | 'DESC' = 'ASC',
    path?: string,
    comparator?: (key: string) => (a: Record<string, string>, b: Record<string, string>) => number
  ) {
    const key: string = path ?? 'name';

    if (!comparator) {
      comparator = (key: string) => (a: Record<string, string>, b: Record<string, string>) => {
        let valueA = key?.includes('.') ? SortUtils.getNestedValue(a, key) : a[key];
        let valueB = key?.includes('.') ? SortUtils.getNestedValue(a, key) : b[key];

        if (!valueA) throw new Error(`no key ${key} in ${a}`);
        if (!valueB) throw new Error(`no key ${key} in ${b}`);
        valueA = valueA.toLowerCase().replace(/\s/g, '');
        valueB = valueB.toLowerCase().replace(/\s/g, '');
        if (valueA === valueB) return 0;
        if (valueA > valueB) return 1;

        return -1;
      };
    }

    items.sort(comparator(key) as any);

    if ('DESC' === direction) {
      items.reverse();
    }
  }
}

const MapperComparator =
  (mapper: Record<string, string | number>) =>
  (key: string) =>
  (a: Record<string, unknown>, b: Record<string, unknown>) => {
    let valueA = key?.includes('.') ? SortUtils.getNestedValue(a, key) : a[key];
    let valueB = key?.includes('.') ? SortUtils.getNestedValue(a, key) : b[key];

    valueA = mapper[a[key] as number | string];
    valueB = mapper[b[key] as number | string];

    if (valueA === null || valueA === undefined) throw new Error(`no key ${key} in ${a}`);
    if (valueB === null || valueB === undefined) throw new Error(`no key ${key} in ${b}`);

    if (valueA === valueB) return 0;
    if (valueA > valueB) return 1;

    return -1;
  };

export { MapperComparator, SortUtils };
