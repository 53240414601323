import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { get } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { headlessTable } from 'ember-headless-table';
import RouteTemplate from 'ember-route-template';
import { Pagination } from 'vfc-admin/components/ui/pagination';
import TableSkeletonLoader from 'vfc-admin/components/ui/table-skeleton-loader';
import config from 'vfc-admin/config/environment';
import { awaitPromise } from 'vfc-admin/helpers/await-promise';
import { debounce } from 'vfc-admin/utils/fn-utils';
import type { TOC } from '@ember/component/template-only';
import type { Column, Row } from 'ember-headless-table';
import type { IntlService } from 'ember-intl';
import type RouterService from '@ember/routing/router-service';
import type { Paginated } from 'vfc-admin/types/paginated';
import type { School } from 'vfc-admin/models/School';
interface SchoolsSignature {
    Element: HTMLDivElement;
    Args: {
        schoolsPromise: Promise<Paginated<School>>;
    };
}
const INTL_COLUMNS_PREFIX = 'page.schools.table.columns';
const ActionsCellComponent: TOC<{
    Element: HTMLElement;
    Args: {
        row: Row;
        column: Column;
    };
}> = template(`
  <div class="action-btn flex gap-3 text-center">
    <a href="/schools/{{get @row.data 'id'}}" class="text-info edit">
      <i class="ti ti-pencil text-lg"></i>
    </a>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
let SchoolsComponent = class SchoolsComponent extends Component<SchoolsSignature> {
    @service
    intl: IntlService;
    @service
    router: RouterService;
    get schoolsResource() {
        return awaitPromise<Paginated<School>>(this.args.schoolsPromise);
    }
    get totalPages() {
        if (!this.schoolsResource.isSuccess) return 0;
        return Math.ceil((this.schoolsResource?.result?.meta?.total ?? 1) / (this.schoolsResource?.result?.meta?.pageSize ?? 1));
    }
    get pageSize() {
        return this.schoolsResource?.result?.meta?.pageSize ?? config.APP.PAGE_SIZE;
    }
    get page() {
        return this.schoolsResource?.result?.meta?.page ?? 1;
    }
    schoolsTable = headlessTable<School>(this, {
        columns: ()=>[
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.name`),
                    key: 'name'
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.phone`),
                    key: 'phone'
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.email`),
                    key: 'email'
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.actions`),
                    Cell: ActionsCellComponent
                }
            ],
        data: ()=>{
            return this.schoolsResource.result?.data ?? [];
        }
    });
    @action
    setfilterQuery(event1: Event) {
        const value1 = (event1.target as HTMLInputElement).value;
        debounce(()=>{
            if (this.args.query !== value1) {
                this.router.transitionTo('authenticated.schools.index', {
                    queryParams: {
                        query: value1,
                        page: undefined
                    }
                });
            }
        }, config.APP.TYPING_DELAY)();
    }
    static{
        template(`
    <div class="lg:col-span-8 md:col-span-6 sm:col-span-12 col-span-12">
      <div class="card">
        <div class="border-b border-light-dark py-4 px-6 flex items-center justify-between">
          <h5 class="card-title mb-0">Colegios</h5>
          <a
            href="/schools/create"
            class="btn-md text-sm font-semibold rounded-md border border-primary text-primary hover:bg-primary hover:text-white"
            type="button"
          >
            Nuevo<i class="ti ti-plus text-sm"></i>
          </a>
        </div>
        <div class="card-body">
          <form class="relative w-full mb-4">
            <input
              type="text"
              placeholder="Buscar"
              class="form-control py-2 ps-10 w-1/4 min-w-72"
              {{on "input" this.setfilterQuery}}
              value={{this.args.query}}
            />
            <i
              class="ti ti-search absolute top-2.5 start-0 translate-middle-y text-lg text-dark ms-3"
            ></i>
          </form>
          <div class="flex flex-col">
            <div class="-m-1.5 overflow-x-auto">
              <div class="p-1.5 min-w-full inline-block align-middle">
                <div class="overflow-hidden">
                  <table
                    class="min-w-full divide-y divide-border dark:divide-darkborder"
                    {{this.schoolsTable.modifiers.container}}
                  >
                    <thead>
                      <tr>
                        {{#each this.schoolsTable.columns as |column|}}
                          <th
                            scope="col"
                            class="text-left rtl:text-right p-4 ps-0 font-semibold text-dark dark:text-white"
                          >
                            {{column.name}}
                          </th>
                        {{/each}}
                      </tr>
                    </thead>
                    <tbody class="divide-y divide-border dark:divide-darkborder">
                      {{#if this.schoolsResource.isPending}}
                        <TableSkeletonLoader @rows={{8}} @columns={{7}} @columnClass={{"py-3"}} />
                      {{else if this.schoolsResource.isError}}
                        <tr class="text-center text-red-500">
                          <td colspan="7">
                            <div>Unexpected error has happened</div>
                            <div class="text-sm">{{this.schoolsResource.error.message}}</div>
                          </td>
                        </tr>
                      {{else}}
                        {{#each this.schoolsTable.rows as |row|}}
                          <tr>
                            {{#each this.schoolsTable.columns as |column|}}
                              <td class="text-dark dark:text-darklink text-base font-light py-3">
                                {{#if column.Cell}}
                                  <column.Cell @row={{row}} @column={{column}} />
                                {{else}}
                                  {{column.getValueForRow row}}
                                {{/if}}
                              </td>
                            {{/each}}
                          </tr>
                        {{/each}}
                      {{/if}}
                    </tbody>
                  </table>
                  {{#if this.schoolsResource.isSuccess}}
                    <div class="flex justify-center mt-4">
                      <Pagination
                        @totalPages={{this.totalPages}}
                        @currentPage={{this.page}}
                        @pageSize={{this.pageSize}}
                      />
                    </div>
                  {{/if}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate<{
    Args: {
        model: {
            schoolsPromise: Promise<Paginated<School>>;
        };
    };
}>(template(`<SchoolsComponent @schoolsPromise={{@model.schoolsPromise}} />`, {
    eval () {
        return eval(arguments[0]);
    }
}));
