import Route from '@ember/routing/route';
import { service } from '@ember/service';

import type Transition from '@ember/routing/transition';
import type { Session } from 'ember-simple-auth/services/session';
import type UserService from 'vfc-admin/services/user-service';

export default class Authenticated extends Route {
  @service declare session: Session;
  @service declare userService: UserService;

  async beforeModel(transition: Transition) {
    this.session.requireAuthentication(transition, 'login');
  }

  async model() {
    const myProfile = await this.userService.userProfile;

    return {
      myProfile,
    };
  }
}
