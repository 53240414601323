import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { type FormData } from 'ember-headless-form';
import RouteTemplate from 'ember-route-template';
import FaqFormComponent from 'vfc-admin/components/forms/faqForm';
import { awaitPromise } from 'vfc-admin/helpers/await-promise';
import { validateInputNatively } from 'vfc-admin/utils/form-utils';
import type RouterService from '@ember/routing/router-service';
import type FaqsService from 'vfc-admin/services/faqs-service';
import type { Faq } from 'vfc-admin/types/faq';
import type { Tag } from 'vfc-admin/types/tag';
interface FaqEditSignature {
    Element: HTMLDivElement;
    Args: {
        faqPromise: Promise<Faq>;
        tagPromise: Promise<Array<Tag>>;
    };
}
let FaqEditComponent = class FaqEditComponent extends Component<FaqEditSignature> {
    @service
    faqsService: FaqsService;
    @service
    router: RouterService;
    validateInputNatively = (event1: Event)=>validateInputNatively.call(this, event1);
    get faqResource() {
        return awaitPromise<Faq>(this.args.faqPromise);
    }
    get faq() {
        return this.faqResource.result as Faq;
    }
    @action
    async onSubmit(data1: FormData<Faq>) {
        try {
            await this.faqsService.update(String(this.faq.id), data1);
            this.router.transitionTo('authenticated.faqs');
        } catch (error1) {
            console.log(error1);
        }
    }
    static{
        template(`
    {{#if this.faqResource.isPending}}
      Loading
    {{else if this.faqResource.isSuccess}}
      <div class="card">
        <div class="col-span-12">
          <div class="border-b border-light-dark py-4 px-6 flex items-center justify-between">
            <h5 class="card-title mb-0"> Editar Pregunta</h5>
          </div>
        </div>
        <div class="card-body">
          <FaqFormComponent
            @faq={{this.faq}}
            @tagPromise={{this.args.tagPromise}}
            @onSubmit={{this.onSubmit}}
          />
        </div>
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate<{
    Args: {
        model: {
            faqPromise: Promise<Faq>;
            tagPromise: Promise<Array<Tag>>;
        };
    };
}>(template(`
    <FaqEditComponent @faqPromise={{@model.faqPromise}} @tagPromise={{@model.tagPromise}} />
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
