import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { HeadlessForm } from 'ember-headless-form';
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import { awaitPromise } from 'vfc-admin/helpers/await-promise';
import { validateInputNatively } from 'vfc-admin/utils/form-utils';
import type RouterService from '@ember/routing/router-service';
import type { Args } from '@glimmer/component/-private/component';
import type IntlService from 'ember-intl/services/intl';
import type ToastService from 'vfc-admin/services/toast-service';
import type UserService from 'vfc-admin/services/user-service';
import type { User } from 'vfc-admin/types/user';
import type { TODO } from 'vfc-admin/utility-types';
interface ProfileFormSignature {
    Element: HTMLDivElement;
    Args: {
        userPromise: Promise<User>;
    };
}
let ProfileFormComponent = class ProfileFormComponent extends Component<ProfileFormSignature> {
    @service
    userService: UserService;
    @service
    router: RouterService;
    @service
    intl: IntlService;
    @service
    toastService: ToastService;
    @service
    session: TODO;
    constructor(owner1: unknown, args1: Args<ProfileFormSignature>){
        super(owner1, args1);
    }
    validateInputNatively = (event1: Event)=>{
        const input1 = event1.target as HTMLInputElement;
        const validityState1 = validateInputNatively.call(this, event1);
        if (validityState1.patternMismatch) {
            input1.setCustomValidity(this.intl.t('common.form.validation.custom.password-requirements'));
        }
    };
    hasUserDataChanged = (user1: User | undefined, data1: FormData<User>)=>{
        if (!user1) return true;
        return (user1.username !== data1.username || user1.email !== data1.email || user1.name !== data1.name || user1.phone !== data1.phone || data1.password.length > 0);
    };
    validatePassword = ({ password: password1, confirmPassword: confirmPassword1 }: {
        password: string;
        confirmPassword: string;
        token: string;
    })=>{
        if (password1 != confirmPassword1) {
            return {
                confirmPassword: [
                    {
                        value: confirmPassword1,
                        type: 'emailConfirmationMatch',
                        message: 'Las contrasena no coinciden'
                    }
                ]
            };
        }
        return true;
    };
    @action
    async onSubmit(data1: FormData<User>) {
        if (this.hasUserDataChanged(this.user, data1)) {
            await this.userService.update(data1);
            this.router.refresh();
            this.toastService.add({
                id: crypto.randomUUID(),
                message: 'Se ha guardado la configuración de usuario',
                type: 'success',
                duration: 3000
            });
        }
    }
    get userResource() {
        return awaitPromise<User>(this.args.userPromise);
    }
    get user() {
        if (!this.userResource.isSuccess) return;
        const user1 = {
            id: this.userResource.result.id,
            name: this.userResource.result.name ?? '',
            email: this.userResource.result.email ?? '',
            phone: this.userResource.result.phone ?? '',
            username: this.userResource.result.username
        };
        return user1;
    }
    static{
        template(`
    {{#if this.userResource.isPending}}
      Loading
    {{else if this.userResource.isSuccess}}
      <HeadlessForm
        @data={{this.user}}
        @onSubmit={{this.onSubmit}}
        @validate={{this.validatePassword}}
        @validateOn="focusout"
        @revalidateOn="input"
        as |form|
      >
        <div class="grid grid-cols-12 gap-x-6 gap-y-4">
          <div class="lg:col-span-6 col-span-12">
            <div class="flex flex-col gap-4">
              <div>
                <form.Field @name="username" as |field|>
                  <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                    Username
                    <span class="text-red-500">*</span>
                  </field.Label>
                  <field.Input
                    disabled
                    required
                    maxLength="255"
                    {{on "invalid" this.validateInputNatively}}
                    class="form-control py-2 bg-gray-100 {{if field.isInvalid 'border-error'}}"
                  />
                  <field.Errors class="py-1 text-xs text-red-300" />
                </form.Field>
              </div>
              <div>
                <form.Field @name="email" as |field|>
                  <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                    Email
                    <span class="text-red-500">*</span>
                  </field.Label>
                  <field.Input
                    required
                    type="email"
                    {{on "invalid" this.validateInputNatively}}
                    class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                  />
                  <field.Errors class="py-1 text-xs text-red-300" />
                </form.Field>
              </div>
              <div>
                <form.Field @name="password" as |field|>
                  <div>
                    <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                      Contraseña
                    </field.Label>
                    <field.Input
                      type="password"
                      {{on "invalid" this.validateInputNatively}}
                      minlength="12"
                      maxlength="128"
                      pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@\$!%*?&_\\-])[A-Za-z\\d@\$!%*?&_\\-]{12,}\$"
                      title={{t "common.form.validation.custom.password-requirements"}}
                      class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                    />
                    <field.Errors class="text-xs text-red-500" />
                    <small class="text-gray-500">
                      Dejar en blanco para no cambiar la contraseña
                    </small>
                  </div>
                </form.Field>
              </div>
            </div>
          </div>
          <div class="lg:col-span-6 col-span-12">
            <div class="flex flex-col gap-4">
              <div>
                <form.Field @name="name" as |field|>
                  <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                    Name
                    <span class="text-red-500">*</span>
                  </field.Label>
                  <field.Input
                    required
                    maxLength="255"
                    {{on "invalid" this.validateInputNatively}}
                    class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                  />
                  <field.Errors class="py-1 text-xs text-red-300" />
                </form.Field>
              </div>
              <div>
                <form.Field @name="phone" as |field|>
                  <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                    Phone
                  </field.Label>
                  <field.Input
                    maxLength="255"
                    {{on "invalid" this.validateInputNatively}}
                    class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                  />
                  <field.Errors class="py-1 text-xs text-red-300" />
                </form.Field>
              </div>
              <div>
                <form.Field @name="confirmPassword" as |field|>
                  <div>
                    <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                      Repetir contraseña
                    </field.Label>
                    <field.Input
                      type="password"
                      {{on "invalid" this.validateInputNatively}}
                      class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                    />
                    <field.Errors class="text-xs text-red-500" />
                  </div>
                </form.Field>
              </div>
            </div>
          </div>
          <div class="col-span-12 mt-5">
            <div class="flex justify-center">
              <button class="btn btn-md" type="submit">Guardar</button>
            </div>
          </div>
        </div>
      </HeadlessForm>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate<{
    Args: {
        model: {
            userPromise: Promise<User>;
        };
    };
}>(template(`
    <div class="container mx-auto p-4">
      <h1 class="text-2xl font-bold mb-4">Admin Profile</h1>
      <ProfileFormComponent @userPromise={{@model.userPromise}} />
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
