import Service from '@ember/service';
import { service } from '@ember/service';

import config from 'vfc-admin/config/environment';

import type AuthenticatedHttpClient from 'vfc-admin/services/auth/http-client';
import type { TODO } from 'vfc-admin/utility-types';

export default class RelationService extends Service {
  @service('auth/http-client') declare authenticatedHttpClient: AuthenticatedHttpClient;

  static ENDPOINTS = {
    findAll: '/client-relation',
  };

  findAll(): ClientRelation[] {
    return this.authenticatedHttpClient.GET<ClientRelation[]>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${RelationService.ENDPOINTS.findAll}`,
    });
    return Promise.resolve([{ id: 1, name: 'Profesor' }]);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'user-service': AuthenticatedHttpClient;
  }
}
