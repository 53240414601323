import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type RelationService from 'vfc-admin/services/relation-service';
import type SchoolService from 'vfc-admin/services/school-service';

export default class ClientCreateRoute extends Route {
  @service declare schoolService: SchoolService;
  @service declare relationService: RelationService;

  async model() {
    const schoolsPromise = this.schoolService.findAll();
    const clientRelationsPromise = this.relationService.findAll();

    return { clientRelationsPromise, schoolsPromise };
  }
}
