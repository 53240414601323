import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import { TripPaymentTabComponent } from 'vfc-admin/components/pages/trip-payment-tab';
import type { TripPayment, TripPaymentRefund, TripPaymentScheduled } from 'vfc-admin/models/payments';
import type { Trip } from 'vfc-admin/types/trip';
export default RouteTemplate<{
    Args: {
        model: {
            tripPromise: Promise<Trip>;
            paymentScheduledPromise: Promise<TripPaymentScheduled[]>;
            paymentRefundPromise: Promise<TripPaymentRefund[]>;
            paymentPromise: Promise<TripPayment[]>;
            tripId: string;
        };
    };
}>(template(`
    <TripPaymentTabComponent
      @trip={{@model.tripPromise}}
      @paymentScheduledPromise={{@model.paymentScheduledPromise}}
      @paymentRefundPromise={{@model.paymentRefundPromise}}
      @paymentPromise={{@model.paymentPromise}}
      @tripId={{@model.tripId}}
    />
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
