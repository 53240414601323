import { template } from "@ember/template-compiler";
import './table-skeleton-loader.css';
import Component from '@glimmer/component';
export interface TableSkeletonLoaderSignature {
    Element: HTMLElement;
    Args: {
        rows: number;
        columns: number;
        columnClass: string;
    };
}
export default class TableSkeletonLoader extends Component<TableSkeletonLoaderSignature> {
    get rows() {
        return Array(this.args.rows);
    }
    get columns() {
        return Array(this.args.columns);
    }
    static{
        template(`
    {{#each this.rows}}
      <tr>
        {{#each this.columns}}
          <td class="loading {{@columnClass}}">
            <div class="bar"></div>
          </td>
        {{/each}}
      </tr>
    {{/each}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
