import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type ThemeService from 'vfc-admin/services/theme-service';
import type { TODO } from 'vfc-admin/utility-types';
const htmlTag = document.documentElement;
const body = document.body;
export interface SettingsOverlaySignature {
    // We have a `<div>` as our root element
    Element: HTMLElement;
}
export default class SettingsOverlay extends Component<SettingsOverlaySignature> {
    @service
    themeService: ThemeService;
    @action
    setSidebarType(sidebarType1: 'full' | 'mini-sidebar') {
        this.themeService.sidebarType = sidebarType1;
        body.setAttribute('data-sidebartype', sidebarType1);
    }
    @action
    checkProperty(property1: string, value1: TODO): boolean {
        return this.themeService[property1] == value1;
    }
    @action
    setCardBorder(cardBorder1: boolean) {
        this.themeService.cardBorder = cardBorder1;
        htmlTag.setAttribute('data-card', cardBorder1 ? 'border' : 'shadow');
    }
    @action
    setBoxedLayout(boxedLayout1: boolean) {
        this.themeService.boxedLayout = boxedLayout1;
        htmlTag.setAttribute('data-boxed-layout', boxedLayout1 ? 'boxed' : 'full');
    }
    @action
    setDirection(direction1: string) {
        this.themeService.direction = direction1;
        htmlTag.setAttribute('dir', direction1);
    }
    @action
    setColorTheme(colorTheme1: string) {
        this.themeService.colorTheme = colorTheme1;
        document.documentElement.setAttribute('data-color-theme', colorTheme1);
    }
    static{
        template(`
    <button
      type="button"
      class="btn overflow-hidden sm:h-14 sm:w-14 h-10 w-10 rounded-full fixed sm:bottom-8 bottom-5 right-8 flex justify-center items-center rtl:left-8 rtl:right-auto z-10"
      data-hs-overlay="#hs-overlay-right"
    >
      <i class="ti ti-settings sm:text-2xl text-lg text-white"></i>
    </button>

    <div
      id="hs-overlay-right"
      class="customizer rounded-none hs-overlay bg-white dark:bg-dark hs-overlay-open:translate-x-0 translate-x-full rtl:hs-overlay-open:translate-x-0 rtl:-translate-x-full fixed top-0 right-0 rtl:left-0 rtl:right-auto transition-all duration-300 transform h-full max-w-xs w-full z-[60] hidden"
    >
      <div
        class="flex justify-between items-center border-border dark:border-darkborder border-b py-3 px-6"
      >
        <h3 class="font-semibold text-lg text-dark dark:text-white">
          Settings
        </h3>
        <button
          type="button"
          class="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white text-sm"
          data-hs-overlay="#hs-overlay-right"
        >
          <span class="sr-only">Close modal</span>
          <i class="ti ti-x text-xl text-dark dark:text-white"></i>
        </button>
      </div>

      <div
        class="px-6 py-6 simplebar-scrollable-y"
        data-simplebar="init"
        style="height: calc(100vh - 80px)"
      >
        <div class="simplebar-wrapper" style="margin: -24px;">
          <div class="simplebar-height-auto-observer-wrapper">
            <div class="simplebar-height-auto-observer">
            </div>
          </div>
          <div class="simplebar-mask"><div
              class="simplebar-offset"
              style="right: 0px; bottom: 0px;"
            ><div
                class="simplebar-content-wrapper"
                tabindex="0"
                role="region"
                aria-label="scrollable content"
                style="height: 100%; overflow: hidden scroll;"
              >
                <div class="simplebar-content" style="padding: 24px;">
                  <h6 class="font-semibold text-dark dark:text-white mb-2">Theme</h6>
                  <div class="flex flex-row gap-3 customizer-box mb-8" role="group">
                    <input type="radio" class="btn-check" name="theme-layout" autocomplete="off" />
                    <label
                      class="btn btn-outline border border-border dark:border-darkborder text-primary h-full py-3 px-5 hs-dark-mode-active:text-darklink cursor-pointer"
                      for="light-layout"
                      data-hs-theme-click-value="light"
                    >
                      <i class="icon ti ti-sun text-2xl me-2"></i>
                      Light
                    </label>
                    <input type="radio" class="btn-check" name="theme-layout" autocomplete="off" />
                    <label
                      class="btn btn-outline border border-border dark:border-darkborder text-link h-full py-3 px-5 cursor-pointer hs-dark-mode-active:text-primary"
                      for="dark-layout"
                      data-hs-theme-click-value="dark"
                    ><i class="icon ti ti-moon text-2xl me-2"></i> Dark</label>
                  </div>

                  <h6 class="font-semibold mb-2 text-dark dark:text-white">Theme Direction</h6>
                  <div class="flex flex-row gap-3 customizer-box mb-8" role="group">
                    <input
                      type="radio"
                      class="btn-check"
                      name="direction-l"
                      id="ltr-layout"
                      autocomplete="off"
                      checked={{this.checkProperty "direction" "ltr"}}
                      {{on "click" (fn this.setDirection "ltr")}}
                    />
                    <label
                      class="btn btn-outline border border-border dark:border-darkborder text-link dark:text-darklink h-full py-3 px-5 cursor-pointer"
                      for="ltr-layout"
                    ><i class="icon ti ti-text-direction-ltr text-2xl me-2"></i>LTR</label>
                    <input
                      type="radio"
                      class="btn-check"
                      name="direction-l"
                      id="rtl-layout"
                      autocomplete="off"
                      checked={{this.checkProperty "direction" "rtl"}}
                      {{on "click" (fn this.setDirection "rtl")}}
                    />
                    <label
                      class="btn btn-outline border border-border dark:border-darkborder text-link dark:text-darklink h-full py-3 px-5 cursor-pointer"
                      for="rtl-layout"
                    >
                      <i class="icon ti ti-text-direction-rtl text-2xl me-2"></i>RTL</label>
                  </div>

                  <h6 class="font-semibold mb-2 text-dark dark:text-white">Theme Colors</h6>
                  <div
                    class="flex flex-row flex-wrap gap-3 customizer-box color-pallete mb-8"
                    role="group"
                  >
                    <input
                      type="radio"
                      class="btn-check"
                      name="color-theme-layout"
                      id="Blue_Theme"
                      autocomplete="off"
                      checked={{this.checkProperty "colorTheme" "Blue_Theme"}}
                      {{on "click" (fn this.setColorTheme "Blue_Theme")}}
                    />
                    <label
                      class="hs-tooltip btn py-4 px-5 btn-outline border border-border dark:border-darkborder flex items-center justify-center hs-tooltip-toggle cursor-pointer"
                      for="Blue_Theme"
                      data-bs-title="BLUE_THEME"
                    >
                      <div class="color-box rounded-full flex items-center justify-center skin-1">
                        <i class="ti ti-check flex icon text-white fs-5"></i>
                      </div>
                      <span
                        class="rounded-md hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 absolute invisible z-10 text-fs_12 py-1 px-2 bg-dark dark:bg-darkgray text-white dark:text-gray-300 hidden"
                        role="tooltip"
                        style="position: fixed; inset: auto auto 0px 0px; margin: 0px; transform: translate(64px, -686px);"
                        data-popper-placement="top"
                      >
                        Blue_Theme
                      </span>
                    </label>

                    <input
                      type="radio"
                      class="btn-check"
                      name="color-theme-layout"
                      id="Aqua_Theme"
                      autocomplete="off"
                      checked={{this.checkProperty "colorTheme" "Aqua_Theme"}}
                      {{on "click" (fn this.setColorTheme "Aqua_Theme")}}
                    />
                    <label
                      class="hs-tooltip hs-tooltip-toggle btn py-4 px-5 btn-outline border border-border dark:border-darkborder flex items-center justify-center cursor-pointer"
                      for="Aqua_Theme"
                      data-bs-title="AQUA_THEME"
                    >
                      <div class="color-box rounded-full flex items-center justify-center skin-2">
                        <i class="ti ti-check text-white flex icon fs-5"></i>
                      </div>
                      <span
                        class="rounded-md hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 absolute invisible z-10 text-fs_12 py-1 px-2 bg-dark dark:bg-darkgray text-white dark:text-gray-300 hidden"
                        role="tooltip"
                        style="position: fixed; inset: auto auto 0px 0px; margin: 0px; transform: translate(156px, -686px);"
                        data-popper-placement="top"
                      >
                        Aqua_Theme
                      </span>
                    </label>

                    <input
                      type="radio"
                      class="btn-check"
                      name="color-theme-layout"
                      id="Purple_Theme"
                      autocomplete="off"
                      checked={{this.checkProperty "colorTheme" "Purple_Theme"}}
                      {{on "click" (fn this.setColorTheme "Purple_Theme")}}
                    />
                    <label
                      class="hs-tooltip hs-tooltip-toggle btn py-4 px-5 btn-outline border border-border dark:border-darkborder flex items-center justify-center cursor-pointer"
                      for="Purple_Theme"
                      data-bs-title="PURPLE_THEME"
                    >
                      <div class="color-box rounded-full flex items-center justify-center skin-3">
                        <i class="ti ti-check text-white flex icon fs-5"></i>
                      </div>
                      <span
                        class="rounded-md hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 absolute invisible z-10 text-fs_12 py-1 px-2 bg-dark dark:bg-darkgray text-white dark:text-gray-300 hidden"
                        role="tooltip"
                        style="position: fixed; inset: auto auto 0px 0px; margin: 0px; transform: translate(39px, -63px);"
                        data-popper-placement="top"
                      >
                        Purple_Theme
                      </span>
                    </label>

                    <input
                      type="radio"
                      class="btn-check"
                      name="color-theme-layout"
                      id="green-theme-layout"
                      autocomplete="off"
                      checked={{this.checkProperty "colorTheme" "Green_Theme"}}
                      {{on "click" (fn this.setColorTheme "Green_Theme")}}
                    />
                    <label
                      class="hs-tooltip hs-tooltip-toggle btn py-4 px-5 btn-outline border border-border dark:border-darkborder flex items-center justify-center cursor-pointer"
                      onclick="handleColorTheme('Green_Theme')"
                      for="green-theme-layout"
                      data-bs-title="GREEN_THEME"
                    >
                      <div class="color-box rounded-full flex items-center justify-center skin-4">
                        <i class="ti ti-check text-white flex icon fs-5"></i>
                      </div>
                      <span
                        class="rounded-md hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 absolute invisible z-10 text-fs_12 py-1 px-2 bg-dark dark:bg-darkgray text-white dark:text-gray-300"
                        role="tooltip"
                        data-popper-placement="top"
                        style="position: fixed; inset: auto auto 0px 0px; margin: 0px; transform: translate(15px, -557px);"
                      >
                        Green_Theme
                      </span>
                    </label>

                    <input
                      type="radio"
                      class="btn-check"
                      name="color-theme-layout"
                      id="cyan-theme-layout"
                      autocomplete="off"
                      checked={{this.checkProperty "colorTheme" "Cyan_Theme"}}
                      {{on "click" (fn this.setColorTheme "Cyan_Theme")}}
                    />
                    <label
                      class="hs-tooltip hs-tooltip-toggle btn py-4 px-5 btn-outline border border-border dark:border-darkborder flex items-center justify-center cursor-pointer"
                      for="cyan-theme-layout"
                      data-bs-title="CYAN_THEME"
                    >
                      <div class="color-box rounded-full flex items-center justify-center skin-5">
                        <i class="ti ti-check text-white flex icon fs-5"></i>
                      </div>
                      <span
                        class="rounded-md hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 absolute invisible z-10 text-fs_12 py-1 px-2 bg-dark dark:bg-darkgray text-white dark:text-gray-300 hidden"
                        role="tooltip"
                        style="position: fixed; inset: auto auto 0px 0px; margin: 0px; transform: translate(156px, -615px);"
                        data-popper-placement="top"
                      >
                        Cyan_Theme
                      </span>
                    </label>

                    <input
                      type="radio"
                      class="btn-check"
                      name="color-theme-layout"
                      id="orange-theme-layout"
                      autocomplete="off"
                      checked={{this.checkProperty "colorTheme" "Orange_Theme"}}
                      {{on "click" (fn this.setColorTheme "Orange_Theme")}}
                    />
                    <label
                      class="hs-tooltip hs-tooltip-toggle btn py-4 px-5 btn-outline border border-border dark:border-darkborder flex items-center justify-center cursor-pointer"
                      for="orange-theme-layout"
                      data-bs-title="ORANGE_THEME"
                    >
                      <div class="color-box rounded-full flex items-center justify-center skin-6">
                        <i class="ti ti-check text-white flex icon fs-5"></i>
                      </div>
                      <span
                        class="rounded-md hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 absolute invisible z-10 text-fs_12 py-1 px-2 bg-dark dark:bg-darkgray text-white dark:text-gray-300"
                        role="tooltip"
                        data-popper-placement="top"
                        style="position: fixed; inset: auto auto 0px 0px; margin: 0px; transform: translate(195px, -557px);"
                      >
                        Orange_Theme
                      </span>
                    </label>
                  </div>

                  <h6 class="font-semibold mb-2 text-dark dark:text-white">Container Option</h6>
                  <div class="flex flex-row gap-3 customizer-box mb-8" role="group">
                    <input
                      type="radio"
                      class="btn-check"
                      name="layout"
                      id="boxed-layout"
                      autocomplete="off"
                      {{on "click" (fn this.setBoxedLayout true)}}
                      checked={{this.checkProperty "boxedLayout" true}}
                    />
                    <label
                      class="btn btn-outline border border-border dark:border-darkborder text-link dark:text-darklink h-full py-3 px-5 cursor-pointer"
                      for="boxed-layout"
                    >
                      <i class="icon ti ti-layout-distribute-vertical text-2xl me-2"></i>
                      Boxed
                    </label>

                    <input
                      type="radio"
                      class="btn-check"
                      name="layout"
                      id="full-layout"
                      autocomplete="off"
                      checked={{this.checkProperty "boxedLayout" false}}
                      {{on "click" (fn this.setBoxedLayout false)}}
                    />
                    <label
                      class="btn btn-outline border border-border dark:border-darkborder text-link dark:text-darklink h-full py-3 px-5 cursor-pointer"
                      for="full-layout"
                    >
                      <i class="icon ti ti-layout-distribute-horizontal text-2xl me-2"></i>
                      Full
                    </label>
                  </div>

                  <h6 class="font-semibold mb-2 text-dark dark:text-white">Sidebar Type</h6>
                  <div class="flex flex-row gap-3 customizer-box mb-8" role="group">
                    <a href="javascript:void(0)" class="fullsidebar">
                      <input
                        type="radio"
                        class="btn-check"
                        name="sidebar-type"
                        id="full-sidebar"
                        autocomplete="off"
                        checked={{this.checkProperty "sidebarType" "full"}}
                        {{on "click" (fn this.setSidebarType "full")}}
                      />
                      <label
                        class="btn btn-outline border border-border dark:border-darkborder text-link dark:text-darklink h-full py-3 px-5 cursor-pointer"
                        for="full-sidebar"
                      >
                        <i class="icon ti ti-layout-sidebar-right text-2xl me-2"></i>
                        Full
                      </label>
                    </a>
                    <div>
                      <input
                        type="radio"
                        class="btn-check"
                        name="sidebar-type"
                        id="mini-sidebar"
                        autocomplete="off"
                        checked={{this.checkProperty "sidebarType" "mini-sidebar"}}
                        {{on "click" (fn this.setSidebarType "mini-sidebar")}}
                      />
                      <label
                        class="btn btn-outline border border-border dark:border-darkborder text-link dark:text-darklink h-full py-3 px-5 cursor-pointer"
                        for="mini-sidebar"
                      >
                        <i class="icon ti ti-layout-sidebar text-2xl me-2"></i>
                        Collapse
                      </label>
                    </div>
                  </div>

                  <h6 class="font-semibold mb-2 text-dark dark:text-white">Card With</h6>
                  <div class="flex flex-row gap-3 customizer-box mb-8" role="group">
                    <input
                      type="radio"
                      class="btn-check"
                      name="card-layout"
                      id="card-with-border"
                      autocomplete="off"
                      checked={{this.checkProperty "cardBorder" true}}
                      {{on "click" (fn this.setCardBorder true)}}
                    />
                    <label
                      class="btn btn-outline border border-border dark:border-darkborder text-link dark:text-darklink h-full py-3 px-5 cursor-pointer"
                      for="card-with-border"
                    ><i class="icon ti ti-border-outer text-2xl me-2"></i>Border</label>

                    <input
                      type="radio"
                      class="btn-check"
                      name="card-layout"
                      id="card-without-border"
                      autocomplete="off"
                      checked={{this.checkProperty "cardBorder" false}}
                      {{on "click" (fn this.setCardBorder false)}}
                    />
                    <label
                      class="btn btn-outline border border-border dark:border-darkborder text-link dark:text-darklink h-full py-3 px-5 cursor-pointer"
                      for="card-without-border"
                    >
                      <i class="icon ti ti-border-none text-2xl me-2"></i>Shadow</label>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="simplebar-placeholder" style="width: 320px; height: 946px;"></div>
        </div>
        <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
          <div class="simplebar-scrollbar" style="width: 0px; display: none;">
          </div>
        </div>
        <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
          <div
            class="simplebar-scrollbar"
            style="height: 833px; display: block; transform: translate3d(0px, 0px, 0px);"
          >
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
