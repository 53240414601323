import Service from '@ember/service';
import { service } from '@ember/service';

import config from 'vfc-admin/config/environment';

import type AuthenticatedHttpClient from 'vfc-admin/services/auth/http-client';
import type { CalendarEntry } from 'vfc-admin/types/calendar-entry';

export type CalendarEntryResponse = {
  data: Array<CalendarEntry>;
};

export default class CalendarService extends Service {
  static ENDPOINTS = {
    entriesByDateRange: '/calendar',
  };

  @service('auth/http-client') declare authenticatedHttpClient: AuthenticatedHttpClient;

  findEntriesByDateRange(startDate: string, endDate: string): Promise<CalendarEntryResponse> {
    const filters = new URLSearchParams({
      from: startDate,
      to: endDate,
    });

    return this.authenticatedHttpClient.GET<CalendarEntryResponse>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${CalendarService.ENDPOINTS.entriesByDateRange}?${filters.toString()}`,
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'calendar-service': CalendarService;
  }
}
