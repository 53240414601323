import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { hash } from '@ember/helper';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { modifier } from 'ember-modifier';
import type { IntlService } from 'ember-intl';
import type { FunctionBasedModifier } from 'ember-modifier';
import type { EmptyObject } from 'ember-modifier/-private/signature.js';
export interface HeadlessFormWrapperSignature {
    // We have a `<div>` as our root element
    Element: HTMLElement;
    Args: {
    };
    Blocks: {
        default: [{
                options: {
                    onResetFormData: (event: Event) => void;
                    evaluateIfDataFormHasChanged: (originalData: unknown) => boolean;
                    validateInputNatively: (event: Event) => void;
                    setupCharacterDataMutationObserver: FunctionBasedModifier<{
                        Args: {
                            Positional: unknown[];
                            Named: EmptyObject;
                        };
                        Element: HTMLTextAreaElement;
                    }>;
                    registerForm: FunctionBasedModifier<{
                        Args: {
                            Positional: unknown[];
                            Named: EmptyObject;
                        };
                        Element: HTMLFormElement;
                    }>;
                    hasFormDataChanged: boolean;
                    formElement?: HTMLFormElement;
                };
            }];
    };
}
export class HeadlessFormWrapper extends Component<HeadlessFormWrapperSignature> {
    @tracked
    hasFormDataChanged = false;
    @service
    intl: IntlService;
    formElement?: HTMLFormElement;
    registerForm = modifier((el1: HTMLFormElement)=>{
        this.formElement = el1;
    });
    @action
    onResetFormData() {
        this.hasFormDataChanged = false;
    }
    @action
    evaluateIfDataFormHasChanged(originalData1: unknown) {
        const formEl1 = this.formElement;
        let formData1 = new FormData(formEl1);
        let data1 = Object.fromEntries(formData1.entries());
        const _data1 = originalData1 as Record<string, unknown>;
        for(let attr1 in data1){
            if (attr1 === 'id') continue;
            this.hasFormDataChanged = data1[attr1] !== _data1[attr1];
            if (this.hasFormDataChanged) break;
        }
        return this.hasFormDataChanged;
    }
    @action
    validateInputNatively(event1: Event) {
        const input1 = event1.target as HTMLInputElement;
        const validityState1 = input1.validity;
        if (validityState1.valueMissing) {
            input1.setCustomValidity(this.intl.t('common.form.validation.valueMissing'));
        } else if (validityState1.patternMismatch) {
            input1.setCustomValidity(this.intl.t('common.form.validation.patternMismatch'));
        } else if (validityState1.tooShort) {
            input1.setCustomValidity(this.intl.t('common.form.validation.tooShort'));
        } else if (validityState1.tooLong) {
            input1.setCustomValidity(this.intl.t('common.form.validation.tooLong'));
        } else {
            input1.setCustomValidity('');
        }
    }
    /*
   * For any reason I dont know, the textarea (innerHTML) is not properly propagated using ember-headless-form
   * so this mutation observer is a workaround to temporary fix it
   */ setupCharacterDataMutationObserver = modifier((element1: HTMLTextAreaElement)=>{
        const observer1 = new MutationObserver((mutations1)=>{
            mutations1.forEach((mutation1)=>{
                if (mutation1.type === 'characterData') {
                    const data1 = (mutation1.target as CharacterData).data;
                    (mutation1.target.parentElement as HTMLTextAreaElement).value = data1;
                }
            });
        });
        // Configuration for the observer
        const config1 = {
            characterData: true,
            attributes: false,
            childList: false,
            subtree: true
        };
        // Start observing the textarea for configured mutations
        observer1.observe(element1, config1);
    });
    get options() {
        return {
            onResetFormData: this.onResetFormData,
            validateInputNatively: this.validateInputNatively,
            evaluateIfDataFormHasChanged: this.evaluateIfDataFormHasChanged,
            hasFormDataChanged: this.hasFormDataChanged,
            setupCharacterDataMutationObserver: this.setupCharacterDataMutationObserver,
            registerForm: this.registerForm,
            formElement: this.formElement
        };
    }
    static{
        template(`{{yield (hash options=this.options)}}`, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
