import { modifier } from 'ember-modifier';

export default modifier((element, [callback]: [callback: Function]) => {
  function handleResize(event: Event) {
    callback(event);
  }

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
});
