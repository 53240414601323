import Route from '@ember/routing/route';
import { service } from '@ember/service';

import type { TripRoomService } from 'vfc-admin/services/trip-room-service';

export default class TripRooms extends Route {
  @service declare tripRoomService: TripRoomService;

  async model() {
    const { tripId } = this.modelFor('authenticated.trips.current-course.trip') as {
      tripId: string;
    };

    const roomsPromise = this.tripRoomService.findAll(tripId);

    return { tripId, roomsPromise };
  }
}
