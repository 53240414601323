
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


i("./-embroider-implicit-modules.js");

d("vfc-admin/app", function(){ return i("vfc-admin/app.ts");});
d("vfc-admin/assets/libs/simplebar/simplebar.min", function(){ return i("vfc-admin/assets/libs/simplebar/simplebar.min.js");});
d("vfc-admin/authenticators/token", function(){ return i("vfc-admin/authenticators/token.ts");});
d("vfc-admin/config/environment", function(){ return i("vfc-admin/config/environment.js");});
d("vfc-admin/models/Client", function(){ return i("vfc-admin/models/Client.ts");});
d("vfc-admin/models/School", function(){ return i("vfc-admin/models/School.ts");});
d("vfc-admin/models/enums/PaymentMethod", function(){ return i("vfc-admin/models/enums/PaymentMethod.ts");});
d("vfc-admin/models/payments", function(){ return i("vfc-admin/models/payments.ts");});
d("vfc-admin/models/response-error", function(){ return i("vfc-admin/models/response-error.ts");});
d("vfc-admin/models/room", function(){ return i("vfc-admin/models/room.ts");});
d("vfc-admin/models/student", function(){ return i("vfc-admin/models/student.ts");});
d("vfc-admin/models/tag", function(){ return i("vfc-admin/models/tag.ts");});
d("vfc-admin/models/teacher", function(){ return i("vfc-admin/models/teacher.ts");});
d("vfc-admin/router", function(){ return i("vfc-admin/router.ts");});
d("vfc-admin/services/auth/http-client", function(){ return i("vfc-admin/services/auth/http-client.ts");});
d("vfc-admin/services/calendar-service", function(){ return i("vfc-admin/services/calendar-service.ts");});
d("vfc-admin/services/client-service", function(){ return i("vfc-admin/services/client-service.ts");});
d("vfc-admin/services/config-service", function(){ return i("vfc-admin/services/config-service.ts");});
d("vfc-admin/services/faqs-service", function(){ return i("vfc-admin/services/faqs-service.ts");});
d("vfc-admin/services/notification-service", function(){ return i("vfc-admin/services/notification-service.ts");});
d("vfc-admin/services/passenger-service", function(){ return i("vfc-admin/services/passenger-service.ts");});
d("vfc-admin/services/relation-service", function(){ return i("vfc-admin/services/relation-service.ts");});
d("vfc-admin/services/school-service", function(){ return i("vfc-admin/services/school-service.ts");});
d("vfc-admin/services/tag-service", function(){ return i("vfc-admin/services/tag-service.ts");});
d("vfc-admin/services/theme-service", function(){ return i("vfc-admin/services/theme-service.ts");});
d("vfc-admin/services/toast-service", function(){ return i("vfc-admin/services/toast-service.ts");});
d("vfc-admin/services/trip-documents-service", function(){ return i("vfc-admin/services/trip-documents-service.ts");});
d("vfc-admin/services/trip-payment-service", function(){ return i("vfc-admin/services/trip-payment-service.ts");});
d("vfc-admin/services/trip-room-service", function(){ return i("vfc-admin/services/trip-room-service.ts");});
d("vfc-admin/services/trip-service", function(){ return i("vfc-admin/services/trip-service.ts");});
d("vfc-admin/services/user-service", function(){ return i("vfc-admin/services/user-service.ts");});
d("vfc-admin/types/calendar-entry", function(){ return i("vfc-admin/types/calendar-entry.ts");});
d("vfc-admin/types/configs/payment", function(){ return i("vfc-admin/types/configs/payment.ts");});
d("vfc-admin/types/faq", function(){ return i("vfc-admin/types/faq.ts");});
d("vfc-admin/types/paginated", function(){ return i("vfc-admin/types/paginated.ts");});
d("vfc-admin/types/pagination", function(){ return i("vfc-admin/types/pagination.ts");});
d("vfc-admin/types/trip", function(){ return i("vfc-admin/types/trip.ts");});
d("vfc-admin/types/user", function(){ return i("vfc-admin/types/user.ts");});
d("vfc-admin/utils/authenticated-remote-data", function(){ return i("vfc-admin/utils/authenticated-remote-data.ts");});
d("vfc-admin/utils/boolean-utils", function(){ return i("vfc-admin/utils/boolean-utils.ts");});
d("vfc-admin/utils/currency-utils", function(){ return i("vfc-admin/utils/currency-utils.ts");});
d("vfc-admin/utils/date-utils", function(){ return i("vfc-admin/utils/date-utils.ts");});
d("vfc-admin/utils/file-utils", function(){ return i("vfc-admin/utils/file-utils.ts");});
d("vfc-admin/utils/fn-utils", function(){ return i("vfc-admin/utils/fn-utils.ts");});
d("vfc-admin/utils/form-utils", function(){ return i("vfc-admin/utils/form-utils.ts");});
d("vfc-admin/utils/math-utils", function(){ return i("vfc-admin/utils/math-utils.ts");});
d("vfc-admin/utils/sort-utils", function(){ return i("vfc-admin/utils/sort-utils.ts");});
d("vfc-admin/utils/string-utils", function(){ return i("vfc-admin/utils/string-utils.ts");});
d("vfc-admin/utils/url-utils", function(){ return i("vfc-admin/utils/url-utils.ts");});
d("vfc-admin/utils/user-utils", function(){ return i("vfc-admin/utils/user-utils.ts");});
d("vfc-admin/services/page-title", function(){ return i("vfc-admin/services/page-title.js");});
d("vfc-admin/services/ember-primitives/setup", function(){ return i("vfc-admin/services/ember-primitives/setup.js");});
d("vfc-admin/services/cookies", function(){ return i("vfc-admin/services/cookies.js");});
d("vfc-admin/initializers/ember-simple-auth", function(){ return i("vfc-admin/initializers/ember-simple-auth.js");});
d("vfc-admin/services/session", function(){ return i("vfc-admin/services/session.js");});
d("vfc-admin/session-stores/application", function(){ return i("vfc-admin/session-stores/application.js");});
d("vfc-admin/utils/inject", function(){ return i("vfc-admin/utils/inject.js");});
d("vfc-admin/utils/is-fastboot", function(){ return i("vfc-admin/utils/is-fastboot.js");});
d("vfc-admin/utils/location", function(){ return i("vfc-admin/utils/location.js");});
d("vfc-admin/utils/objects-are-equal", function(){ return i("vfc-admin/utils/objects-are-equal.js");});
d("vfc-admin/component-managers/glimmer", function(){ return i("vfc-admin/component-managers/glimmer.js");});
d("vfc-admin/services/intl", function(){ return i("vfc-admin/services/intl.js");});
d("vfc-admin/services/-ensure-registered", function(){ return i("vfc-admin/services/-ensure-registered.js");});
d("vfc-admin/container-debug-adapter", function(){ return i("vfc-admin/container-debug-adapter.js");});
d("vfc-admin/templates/application", function(){ return i("vfc-admin/templates/application.ts");});
d("vfc-admin/routes/application", function(){ return i("vfc-admin/routes/application.ts");});
d("vfc-admin/templates/authenticated", function(){ return i("vfc-admin/templates/authenticated.ts");});
d("vfc-admin/routes/authenticated", function(){ return i("vfc-admin/routes/authenticated.ts");});
d("vfc-admin/templates/authenticated/clients/create", function(){ return i("vfc-admin/templates/authenticated/clients/create.ts");});
d("vfc-admin/routes/authenticated/clients/create", function(){ return i("vfc-admin/routes/authenticated/clients/create.ts");});
d("vfc-admin/templates/authenticated/clients/edit", function(){ return i("vfc-admin/templates/authenticated/clients/edit.ts");});
d("vfc-admin/routes/authenticated/clients/edit", function(){ return i("vfc-admin/routes/authenticated/clients/edit.ts");});
d("vfc-admin/templates/authenticated/clients/index", function(){ return i("vfc-admin/templates/authenticated/clients/index.ts");});
d("vfc-admin/routes/authenticated/clients/index", function(){ return i("vfc-admin/routes/authenticated/clients/index.ts");});
d("vfc-admin/templates/authenticated/configs/payment", function(){ return i("vfc-admin/templates/authenticated/configs/payment.ts");});
d("vfc-admin/routes/authenticated/configs/payment", function(){ return i("vfc-admin/routes/authenticated/configs/payment.ts");});
d("vfc-admin/templates/authenticated/faqs/create", function(){ return i("vfc-admin/templates/authenticated/faqs/create.ts");});
d("vfc-admin/routes/authenticated/faqs/create", function(){ return i("vfc-admin/routes/authenticated/faqs/create.ts");});
d("vfc-admin/templates/authenticated/faqs/edit", function(){ return i("vfc-admin/templates/authenticated/faqs/edit.ts");});
d("vfc-admin/routes/authenticated/faqs/edit", function(){ return i("vfc-admin/routes/authenticated/faqs/edit.ts");});
d("vfc-admin/templates/authenticated/faqs/index", function(){ return i("vfc-admin/templates/authenticated/faqs/index.ts");});
d("vfc-admin/routes/authenticated/faqs/index", function(){ return i("vfc-admin/routes/authenticated/faqs/index.ts");});
d("vfc-admin/templates/authenticated/profile", function(){ return i("vfc-admin/templates/authenticated/profile.ts");});
d("vfc-admin/routes/authenticated/profile", function(){ return i("vfc-admin/routes/authenticated/profile.ts");});
d("vfc-admin/templates/authenticated/schools/create", function(){ return i("vfc-admin/templates/authenticated/schools/create.ts");});
d("vfc-admin/routes/authenticated/schools/create", function(){ return i("vfc-admin/routes/authenticated/schools/create.ts");});
d("vfc-admin/templates/authenticated/schools/edit", function(){ return i("vfc-admin/templates/authenticated/schools/edit.ts");});
d("vfc-admin/routes/authenticated/schools/edit", function(){ return i("vfc-admin/routes/authenticated/schools/edit.ts");});
d("vfc-admin/templates/authenticated/schools/index", function(){ return i("vfc-admin/templates/authenticated/schools/index.ts");});
d("vfc-admin/routes/authenticated/schools/index", function(){ return i("vfc-admin/routes/authenticated/schools/index.ts");});
d("vfc-admin/templates/authenticated/trips/current-course/index", function(){ return i("vfc-admin/templates/authenticated/trips/current-course/index.ts");});
d("vfc-admin/routes/authenticated/trips/current-course/index", function(){ return i("vfc-admin/routes/authenticated/trips/current-course/index.ts");});
d("vfc-admin/templates/authenticated/trips/current-course/trip", function(){ return i("vfc-admin/templates/authenticated/trips/current-course/trip.ts");});
d("vfc-admin/routes/authenticated/trips/current-course/trip", function(){ return i("vfc-admin/routes/authenticated/trips/current-course/trip.ts");});
d("vfc-admin/templates/authenticated/trips/current-course/trip/passengers", function(){ return i("vfc-admin/templates/authenticated/trips/current-course/trip/passengers.ts");});
d("vfc-admin/routes/authenticated/trips/current-course/trip/passengers", function(){ return i("vfc-admin/routes/authenticated/trips/current-course/trip/passengers.ts");});
d("vfc-admin/templates/authenticated/trips/current-course/trip/payments", function(){ return i("vfc-admin/templates/authenticated/trips/current-course/trip/payments.ts");});
d("vfc-admin/routes/authenticated/trips/current-course/trip/payments", function(){ return i("vfc-admin/routes/authenticated/trips/current-course/trip/payments.ts");});
d("vfc-admin/templates/authenticated/trips/current-course/trip/rooms", function(){ return i("vfc-admin/templates/authenticated/trips/current-course/trip/rooms.ts");});
d("vfc-admin/routes/authenticated/trips/current-course/trip/rooms", function(){ return i("vfc-admin/routes/authenticated/trips/current-course/trip/rooms.ts");});
d("vfc-admin/templates/authenticated/trips/current-course/trip/documents", function(){ return i("vfc-admin/templates/authenticated/trips/current-course/trip/documents.ts");});
d("vfc-admin/templates/authenticated/trips/current-course/trip/index", function(){ return i("vfc-admin/templates/authenticated/trips/current-course/trip/index.ts");});
d("vfc-admin/templates/authenticated/trips/current-course/trip/invoices", function(){ return i("vfc-admin/templates/authenticated/trips/current-course/trip/invoices.ts");});
d("vfc-admin/templates/authenticated/trips/incomplete/edit", function(){ return i("vfc-admin/templates/authenticated/trips/incomplete/edit.ts");});
d("vfc-admin/routes/authenticated/trips/incomplete/edit", function(){ return i("vfc-admin/routes/authenticated/trips/incomplete/edit.ts");});
d("vfc-admin/templates/authenticated/trips/incomplete/index", function(){ return i("vfc-admin/templates/authenticated/trips/incomplete/index.ts");});
d("vfc-admin/routes/authenticated/trips/incomplete/index", function(){ return i("vfc-admin/routes/authenticated/trips/incomplete/index.ts");});
d("vfc-admin/templates/authenticated/trips/pending/index", function(){ return i("vfc-admin/templates/authenticated/trips/pending/index.ts");});
d("vfc-admin/routes/authenticated/trips/pending/index", function(){ return i("vfc-admin/routes/authenticated/trips/pending/index.ts");});
d("vfc-admin/templates/authenticated/trips/pending/trip", function(){ return i("vfc-admin/templates/authenticated/trips/pending/trip.ts");});
d("vfc-admin/routes/authenticated/trips/pending/trip", function(){ return i("vfc-admin/routes/authenticated/trips/pending/trip.ts");});
d("vfc-admin/templates/authenticated/trips/pending/trip/passengers", function(){ return i("vfc-admin/templates/authenticated/trips/pending/trip/passengers.ts");});
d("vfc-admin/routes/authenticated/trips/pending/trip/passengers", function(){ return i("vfc-admin/routes/authenticated/trips/pending/trip/passengers.ts");});
d("vfc-admin/templates/authenticated/trips/pending/trip/payments", function(){ return i("vfc-admin/templates/authenticated/trips/pending/trip/payments.ts");});
d("vfc-admin/routes/authenticated/trips/pending/trip/payments", function(){ return i("vfc-admin/routes/authenticated/trips/pending/trip/payments.ts");});
d("vfc-admin/templates/authenticated/trips/pending/trip/rooms", function(){ return i("vfc-admin/templates/authenticated/trips/pending/trip/rooms.ts");});
d("vfc-admin/routes/authenticated/trips/pending/trip/rooms", function(){ return i("vfc-admin/routes/authenticated/trips/pending/trip/rooms.ts");});
d("vfc-admin/templates/authenticated/trips/pending/trip/documents", function(){ return i("vfc-admin/templates/authenticated/trips/pending/trip/documents.ts");});
d("vfc-admin/templates/authenticated/trips/pending/trip/index", function(){ return i("vfc-admin/templates/authenticated/trips/pending/trip/index.ts");});
d("vfc-admin/templates/authenticated/trips/pending/trip/invoices", function(){ return i("vfc-admin/templates/authenticated/trips/pending/trip/invoices.ts");});
d("vfc-admin/templates/authenticated/calendar", function(){ return i("vfc-admin/templates/authenticated/calendar.ts");});
d("vfc-admin/templates/authenticated/home", function(){ return i("vfc-admin/templates/authenticated/home.ts");});
d("vfc-admin/templates/authenticated/not-found", function(){ return i("vfc-admin/templates/authenticated/not-found.ts");});
d("vfc-admin/templates/change-password", function(){ return i("vfc-admin/templates/change-password.ts");});
d("vfc-admin/routes/change-password", function(){ return i("vfc-admin/routes/change-password.ts");});
d("vfc-admin/templates/login", function(){ return i("vfc-admin/templates/login.ts");});
d("vfc-admin/routes/login", function(){ return i("vfc-admin/routes/login.ts");});
d("vfc-admin/templates/request-password", function(){ return i("vfc-admin/templates/request-password.ts");});
d("vfc-admin/routes/request-password", function(){ return i("vfc-admin/routes/request-password.ts");});





if (!runningTests) {
  i("../app").default.create({"API":{"ADMIN_FINDECURSO_PREFIX":"https://api-admin-dev.findecursocolegio.com/v1/admin"},"DATE":{"DEFAULT_FORMAT":"DD/MM/YYYY","DEFAULT_DATETIME_FORMAT":"DD/MM/YYYY HH:mm","USER_FRIENDLY_FORMAT":"D MMM YYYY","DATE_TYPE_FORMAT":"YYYY-MM-DD","DATE_TIME_FORMAT":"YYYY-MM-DD HH:mm:ss"},"TYPING_DELAY":300,"PAGE_SIZE":10});
}

