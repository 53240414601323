import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { type FormData, HeadlessForm } from 'ember-headless-form';
import { urlForRoute } from 'vfc-admin/helpers/url-for-route';
import { validateInputNatively } from 'vfc-admin/utils/form-utils';
import type RouterService from '@ember/routing/router-service';
import type { School, SchoolForm } from 'vfc-admin/models/school';
export interface SchoolFormComponentSignature {
    Element: HTMLElement;
    Args: {
        school: School;
        onSubmit?: (data: FormData<SchoolForm>) => void;
    };
}
export default class SchoolFormComponent extends Component<SchoolFormComponentSignature> {
    @service
    router: RouterService;
    validateInputNatively = (event1: Event)=>validateInputNatively.call(this, event1);
    static{
        template(`
    <HeadlessForm
      @data={{@school}}
      @onSubmit={{@onSubmit}}
      @validateOn="focusout"
      @revalidateOn="input"
      as |form|
    >
      <div class="grid grid-cols-12 gap-x-6 gap-y-4 mt-5">
        <div class="lg:col-span-6 col-span-12">
          <div class="flex flex-col gap-4">
            <div>
              <form.Field @name="name" as |field|>
                <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                  Nombre
                  <span class="text-red-500">*</span>
                </field.Label>
                <field.Input
                  required
                  maxLength="255"
                  {{on "invalid" this.validateInputNatively}}
                  class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                />
                <field.Errors class="py-1 text-xs text-red-300" />
              </form.Field>
            </div>
            <div>
              <form.Field @name="email" as |field|>
                <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                  Email
                </field.Label>
                <field.Input
                  type="email"
                  {{on "invalid" this.validateInputNatively}}
                  class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                />
                <field.Errors class="py-1 text-xs text-red-300" />
              </form.Field>
            </div>
            <div>
              <form.Field @name="province" as |field|>
                <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                  Provincia
                  <span class="text-red-500">*</span>
                </field.Label>
                <field.Input
                  required
                  maxLength="255"
                  {{on "invalid" this.validateInputNatively}}
                  class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                />
                <field.Errors class="py-1 text-xs text-red-300" />
              </form.Field>
            </div>

          </div>
        </div>
        <div class="lg:col-span-6 col-span-12">
          <div class="flex flex-col gap-4">
            <div>
              <form.Field @name="cif" as |field|>
                <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                  CIF
                </field.Label>
                <field.Input
                  maxLength="255"
                  {{on "invalid" this.validateInputNatively}}
                  class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                />
                <field.Errors class="py-1 text-xs text-red-300" />
              </form.Field>
            </div>
            <div>
              <form.Field @name="phone" as |field|>
                <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                  Teléfono
                </field.Label>
                <field.Input
                  maxLength="255"
                  {{on "invalid" this.validateInputNatively}}
                  class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                />
                <field.Errors class="py-1 text-xs text-red-300" />
              </form.Field>
            </div>
            <div>
              <form.Field @name="population" as |field|>
                <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                  Población
                </field.Label>
                <field.Input
                  maxLength="255"
                  {{on "invalid" this.validateInputNatively}}
                  class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                />
                <field.Errors class="py-1 text-xs text-red-300" />
              </form.Field>
            </div>
          </div>
        </div>
        <div class="col-span-12">
          <div>
            <form.Field @name="address" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                Dirección
              </field.Label>
              <field.Input
                maxLength="255"
                {{on "invalid" this.validateInputNatively}}
                class="form-control py-2 {{if field.isInvalid 'border-error'}}"
              />
              <field.Errors class="py-1 text-xs text-red-300" />
            </form.Field>
          </div>
        </div>
        <div class="col-span-12">
          <div>
            <form.Field @name="url" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                Url
              </field.Label>
              <field.Input
                type="url"
                maxLength="255"
                {{on "invalid" this.validateInputNatively}}
                class="form-control py-2 {{if field.isInvalid 'border-error'}}"
              />
              <field.Errors class="py-1 text-xs text-red-300" />
            </form.Field>
          </div>
        </div>
        <div class="col-span-12">
          <div class="flex gap-3 justify-end">
            <button class="btn btn-md" type="submit">Guardar</button>
            <a class="btn btn-light-error" href="{{urlForRoute 'authenticated.schools'}}">
              Cancelar
            </a>
          </div>
        </div>
      </div>
    </HeadlessForm>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
